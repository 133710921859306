import { isNil, keys } from 'lodash';

export const homePath = () => '/app';

export const patientRegistrationPath = () => '/app/patients/register';

export const patientDetailsPath = (
  patientId,
  caseId,
  selectedTab,
  selectedTabEnrollmentId
) => {
  const queryParams = new URLSearchParams();

  if (caseId) {
    queryParams.set('caseId', caseId);
  }

  if (selectedTab) {
    queryParams.set('patientDetailsTab', selectedTab);
  }

  if (selectedTabEnrollmentId) {
    queryParams.set('selectedTabEnrollmentId', selectedTabEnrollmentId);
  }

  const queryString = queryParams.toString();
  return `/app/patients/${patientId}${queryString ? `?${queryString}` : ''}`;
};

export const patientListPath = () => '/app/patients';

export const prescriberRegistrationPath = () => '/app/prescribers/register';

export const prescriberDetailsPath = (prescriberId) => `/app/prescribers/${prescriberId}`;

export const prescriberListPath = () => '/app/prescribers';

export const prescriberBaaPath = () => '/app/baa';

export const destinationListPath = () => '/app/pharmacies';

export const destinationRegistrationPath = () => '/app/pharmacies/register';

export const destinationEditPath = (pharmacyId) => `/app/pharmacies/${pharmacyId}`;

export const enrollmentCreationPath = (params) => {
  const url = new URL('/app/enrollments/create', window.location.origin);
  keys(params)
    .filter((k) => !isNil(params[k]))
    .forEach((k) => url.searchParams.append(k, params[k]));

  return `${url.pathname}${url.search}`;
};

export const foundationsPath = () => '/app/foundations';

export const enrollmentPreviewPath = (formId) => `/app/enrollmentPreview/${formId}`;

export const enrollmentDetailsPath = (enrollmentId) => `/app/enrollments/${enrollmentId}`;

export const enrollmentMappedPath = (enrollmentId) => `/app/enrollments/complete/${enrollmentId}`;

export const enrollmentListPath = () => '/app/enrollments';

export const medAccessListPath = () => '/app/medaccess';

export const caseListPath = () => '/app/cases';

export const formDetailsPath = (formId) => `/app/formifier/${formId}`;

export const formListPath = () => '/app/formifier';

export const formManagerTrainingPath = () => '/app/training/formManager';

export const swaggerListPath = () => '/app/swagger';

export const swaggerApiPath = (api) => `/app/swagger/${api}`;

export const formQuestionEditPath = (formId) => `/app/questionator/${formId}`;

export const formSummaryPath = (formId) => `/app/formSummary/${formId}`;

export const questionManagementPath = () => '/app/questionManagement';

export const generalFormPath = () => '/app/generalForm';

export const lddRelationshipListPath = () => '/app/ldd';

export const lddRelationshipCreatePath = () => '/app/ldd/create';

export const orgListPath = () => '/app/organization';

export const notificationListPath = (tab) => {
  const queryParams = new URLSearchParams();

  if (tab) {
    queryParams.set('notificationsTab', tab);
  }
  const queryString = queryParams.toString();
  return `/app/notifications${queryString ? `?${queryString}` : ''}`;
};

export const fileIntakeListPath = () => '/app/file-intake';

export const copayCardsPath = () => '/app/copay-cards';

export const lisPath = () => '/app/lis';

// Paths that do not require auth
export const drugListPath = () => '/app/drugs';

export const drugEditPath = (drugId) => `/app/drugs/${drugId}`;

export const drugCreatePath = () => '/app/drugs/create';

export const supportPath = () => '/app/support';

export const prescriberVialsPath = () => '/app/productReplacement';

export const complianceViewPath = () => '/app/compliance';

export const organizationManagementPath = () => '/app/organizationManagement';

export const directoryPath = () => '/app/directory';

export const orgProviderManagementPath = () => '/app/providerManagement';

export const orgAddProviderPath = () => '/app/providerManagement/add';

export const orgProviderListPath = () => '/app/providers';

export const orgGroupsListPath = () => '/app/groups';

export const permissionListPath = () => '/app/permissions';

export const roleListPath = () => '/app/roles';

export const pendingApprovalsListPath = () => '/app/pendingApprovals';

export const editUserPath = () => '/app/editUser';

export const userListPath = () => '/app/users';

export const parentOrganizationListPath = () => '/app/parentOrganizations';

export const parentOrganizationCreatePath = () => '/app/parentOrganizations/create';

export const logoutPath = () => '/logout';

export const rptViewPath = () => '/app/rpt';

export const orderListPath = (doseOrderId) => `/app/orders${doseOrderId ? `?doseOrderId=${doseOrderId}` : ''}`;

export const treatmentPlanCreatePath = (
  drug,
  doses,
  orderType,
  authorizedUser,
  patientId,
  startDate
) => {
  const queryParams = new URLSearchParams();

  if (drug) {
    queryParams.set('drug', drug);
  }
  if (doses) {
    queryParams.set('doses', doses);
  }
  if (orderType) {
    queryParams.set('orderType', orderType);
  }
  if (authorizedUser) {
    queryParams.set('authorizedUser', authorizedUser);
  }
  if (authorizedUser) {
    queryParams.set('authorizedUser', authorizedUser);
  }
  if (patientId) {
    queryParams.set('patientId', patientId);
  }
  if (startDate) {
    queryParams.set('startDate', startDate);
  }
  const queryString = queryParams.toString();
  return `/app/treatmentPlan/create${queryString ? `?${queryString}` : ''}`;
};

export const doseListPath = () => '/app/doses';
export const treatmentPlanListPath = (treatmentPlanId) => `/app/treatmentPlans${
  treatmentPlanId ? `?treatmentPlanId=${treatmentPlanId}` : ''
}`;

export const ramLicenseDetailsPath = () => '/app/ramLicenseDetails';

export const messagesPath = () => '/app/messages';
export const messageDetailsPath = (messageId) => `/app/messages/${messageId}`;
export const createMessagePath = () => '/app/messages/create';

export const contactListPath = () => '/app/contacts';
export const urgentNotificationPath = () => '/app/urgentNotifications';
export const patientWaitListPath = () => '/app/patientWaitList';
export const batchReleaseListPath = () => '/app/batchReleases';
export const orderSetDashboardPath = () => '/app/orderSetDashboard';
export const orgRamApprovalPath = () => '/app/orgRamApproval';

export const tymlosRankingPath = () => '/app/tymlos-pharmacy-preferences';

export const vendorEnrollmentPath = () => '/enrollment';
export const vendorEnrollmentCompletePath = () => '/enrollment/complete';
