export const White = '#FFFFFF';
export const Black = '#000000';
export const LightGrey = '#ADB0BB';
export const Mineshaft = '#444444';
export const Cerulean = '#00B2E3';
export const Lochmara = '#0088CC';
export const Azure = '#345D8D';
export const MidnightBlue = '#003764';
export const CenterWellBlue = '#003DA5';
export const BigStone = '#13293D';
export const CenterWellGreen = '#78BE20';
export const WeirdPink = '#D8315B';
export const SuccessGreen = '#38923b';
export const WarningYellow = '#cda224';
export const IlumyaYellow = '#ffc845';
export const IlumyaPurple = '#5f2167';
export const KorlymPink = '#C84A5D';
export const KorlymBurgundy = '#9B004F';
export const KorlymTeal = '#069C90';
export const KorlymGray = '#425563';
