export const getStatusName = (status) => {
  if (!status) {
    return 'Unknown';
  }

  const { message, name, subStatusItems } = status;

  return `${name ?? 'Unknown'}:${
    subStatusItems?.length ? subStatusItems.join(':') : message ?? 'Unknown'
  }`;
};

export const getPersonName = (person) => (person
  ? `${person.lastName ?? 'UNKNOWN'}, ${person.firstName ?? 'UNKNOWN'}`
  : undefined);

export const getPatientName = (patient) => {
  if (!patient) {
    return 'Unknown';
  }

  const { lastName, firstName } = patient;
  return `${lastName ?? 'Unknown'}, ${firstName ?? 'Unknown'}`;
};

export const getPrescriberName = (prescriber) => {
  if (!prescriber) {
    return 'Unknown';
  }

  const { lastName, firstName } = prescriber;
  return `${lastName ?? 'Unknown'}, ${firstName?.charAt(0) ?? 'Unknown'}`;
};

export const getDrugName = (drug) => drug?.name ?? 'Unknown';

export const getDestinationName = (destination) => destination?.name ?? 'Unknown';

export const getCategoryName = (categoryWeight) => categoryWeight?.categoryWeightName;
