import axios from 'axios';

const insuranceApi = (apiConfig) => {
  const getInsuranceEligibility = (request) => axios.post('/v1/insurance/eligibility', request, apiConfig);

  const getPatientInsurance = (patientId) => axios.get(`/v1/insurance/${patientId}`, apiConfig);

  return {
    getInsuranceEligibility,
    getPatientInsurance
  };
};

export default insuranceApi;
