import axios from 'axios';

// WARNING DEPRECATED
const supplyCloudApi = (apiConfig) => {
  const AddSupplyForOrganization = (controller, supplyCloudItem) => axios.post('/v1/supplycloud', supplyCloudItem, apiConfig);

  const UpdateSupplyForOrganization = (controller, supplyCloudItem) => axios.put('/v1/supplycloud', supplyCloudItem, apiConfig);

  const UpdateSupplyQuantityAndNumberProduced = (
    controller,
    supplyCloudItem
  ) => axios.post('/v1/supplycloud/add', supplyCloudItem, apiConfig);

  const listSuppliesByOrganization = (controller, organizationId) => axios.get(`/v1/supplycloud/${organizationId}`, apiConfig);

  const listSuppliesByOrganizationAndDate = (
    controller,
    organizationId,
    startDate = null,
    endDate = null
  ) => axios.get(
    `/v1/supplycloud/${organizationId}?start=${startDate}&finish=${endDate}`,
    apiConfig
  );

  const listBatchReleaseDocumentation = (organizationId) => axios.get('/v1/supplycloud/batchReleases', {
    ...apiConfig,
    params: {
      organizationId
    }
  });

  const createBatchReleaseDocumentation = (batchRelease) => axios.post('/v1/supplyCloud/batchRelease', batchRelease, apiConfig);

  return {
    AddSupplyForOrganization,
    UpdateSupplyForOrganization,
    UpdateSupplyQuantityAndNumberProduced,
    listSuppliesByOrganization,
    listSuppliesByOrganizationAndDate,
    listBatchReleaseDocumentation,
    createBatchReleaseDocumentation
  };
};

export default supplyCloudApi;
