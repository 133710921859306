import React, {
  createContext, useEffect, useReducer, useState
} from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { first } from 'lodash';
import { useSnackbar } from 'notistack';
import SplashScreen from '../components/loading/SplashScreen';
import PreferredCommunicationDialog from '../components/dialogs/PreferredCommunicationDialog';
import { rxlApiAxios, rxlAuthApiAxios } from '../utils/axios';
import { now, toUnix } from '../utils/date-utility';
import {
  COMMUNICATION_CONSENT,
  MANAGE_GROUPS,
  MANAGE_PARENT_ORGANIZATIONS,
  READ_RAM_LICENSE_DETAILS,
  UPDATE_ORG_RAM_APPROVAL,
  UPDATE_ORGANIZATION_PREFERENCES,
  UPDATE_PREFERENCES
} from '../constants/permissions';
import useBranding from '../hooks/useBranding';
import ErrorBoundaryView from '../components/ErrorBoundaryView';
import useEnvironment from '../hooks/useEnvironment';
import useRxlApis from '../hooks/useRxlApis';
import SessionTimeout from '../components/auth/SessionTimeout';

const initialAuthState = {
  error: false,
  features: null,
  isInitialized: false,
  organizations: [],
  parentOrganizations: [],
  selectedOrganization: null,
  selectedParentOrganization: null,
  user: null,
  manufacturer: null,
  manufacturers: []
};

const AuthContext = createContext({
  ...initialAuthState,
  addToUserOrganizations: () => {},
  hasPermission: () => false,
  hasPermissions: () => false,
  hasAnyPermissionsIn: () => false,
  logout: () => {},
  redirectToLogin: () => {},
  refreshUser: () => {},
  updateSelectedOrganization: () => {},
  updateSelectedParentOrganization: () => {}
});

export const AuthProvider = ({ children }) => {
  const { AUTH_COOKIE_NAME, ENVIRONMENT, AUTH_PORTAL_URL } = useEnvironment();
  const {
    getUser,
    getOrganization,
    getParentOrganization,
    updateUserInfo,
    getUserPreferences,
    getOrganizationPreferences,
    listParentOrgs,
    listAllManufacturers,
    getPrescriber
  } = useRxlApis();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const branding = useBranding();
  const [state, dispatch] = useReducer(
    (internalState, action) => {
      switch (action.type) {
        case 'INITIALIZE': {
          return {
            ...internalState,
            ...(action.payload ?? {}),
            isInitialized: true
          };
        }
        case 'UPDATE': {
          return {
            ...internalState,
            ...(action.payload ?? {})
          };
        }
        case 'LOGOUT': {
          return initialAuthState;
        }
        case 'ERROR': {
          return {
            ...initialAuthState,
            error: true
          };
        }
        default: {
          return internalState;
        }
      }
    },
    initialAuthState,
    () => initialAuthState
  );
  const [cookies, , removeCookie] = useCookies([AUTH_COOKIE_NAME]);

  const hasPermission = (permission) => state?.user?.permissions?.includes(permission) ?? false;

  const hasPermissions = (permissions) => permissions?.every((p) => state?.user?.permissions?.includes(p)) ?? false;

  const hasAnyPermissionsIn = (permissions) => permissions?.some((p) => state?.user?.permissions?.includes(p)) ?? false;

  const setSession = (accessToken) => {
    if (accessToken) {
      const authorizationHeader = `Bearer ${accessToken}`;
      rxlApiAxios.defaults.headers.common.Authorization = authorizationHeader;
      rxlAuthApiAxios.defaults.headers.common.Authorization = authorizationHeader;
    } else {
      delete rxlApiAxios.defaults.headers.common.Authorization;
      delete rxlAuthApiAxios.defaults.headers.common.Authorization;
      removeCookie(AUTH_COOKIE_NAME, {
        domain:
          ENVIRONMENT === 'local'
            ? undefined
            : branding?.domain ?? 'rxlightning.com',
        path: ENVIRONMENT === 'local' ? undefined : '/',
        sameSite: 'none',
        secure: true
      });
      if (branding) {
        branding.clear();
      }
    }
  };

  const redirectToLogin = () => {
    window.location.href = `${AUTH_PORTAL_URL}/login?referralCode=${branding.referralCode}`;
  };

  const updateSelectedOrganization = (selectedOrganization) => {
    getOrganization(selectedOrganization.organizationId).then((response) => {
      const organization = response?.data?.data;
      dispatch({
        type: 'UPDATE',
        payload: {
          selectedOrganization: organization
        }
      });
    });
  };

  const updateSelectedParentOrganization = (selectedParentOrganization) => {
    if (selectedParentOrganization?.parentOrganizationId) {
      setLoading(true);
      getParentOrganization(selectedParentOrganization.parentOrganizationId)
        .then((response) => {
          const parentOrganization = response?.data?.data;
          dispatch({
            type: 'UPDATE',
            payload: {
              selectedParentOrganization: parentOrganization,
              organizations: parentOrganization.organizations,
              selectedOrganization: first(parentOrganization.organizations)
            }
          });
        })
        .catch(() => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const addToUserOrganizations = (organization) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        selectedOrganization: organization,
        organizations: [organization, ...(state.user?.organizations ?? [])],
        user: {
          ...(state.user ?? {}),
          organizations: [organization, ...(state.user?.organizations ?? [])]
        }
      }
    });
  };

  const logout = () => {
    setSession();
    dispatch({ type: 'LOGOUT' });
    redirectToLogin();
  };

  const refreshUser = () => {
    buildUser();
  };

  const updatePreferredCommunicationType = (
    preferredCommunicationType,
    onSuccess
  ) => {
    updateUserInfo(state.user.userId, {
      ...state.user,
      preferredCommunicationType
    }).then((response) => {
      if (!response?.data?.data || response?.data?.errors?.locations?.length) {
        enqueueSnackbar(
          'Error occurred while updating preferences. Please try again.',
          {
            autoHideDuration: 3000,
            variant: 'error'
          }
        );
      } else {
        dispatch({
          type: 'UPDATE',
          payload: {
            user: {
              ...state.user,
              ...response.data.data
            }
          }
        });

        if (onSuccess) {
          onSuccess();
        }
      }
    });
  };

  const parseAuthorization = () => {
    const authCookie = cookies[AUTH_COOKIE_NAME];
    const parsedCookie = decodeURI(authCookie);

    if (parsedCookie) {
      const urlSearchParams = new URLSearchParams(parsedCookie);
      const accessToken = urlSearchParams.get('access_token');
      const expires = urlSearchParams.get('expires');

      const sessionIsValid = expires && toUnix(now()) < expires;
      if (accessToken && sessionIsValid) {
        setSession(accessToken);

        return {
          accessToken,
          sessionExpires: expires,
          branding
        };
      }
    }

    return null;
  };

  const buildUser = async () => {
    setLoading(true);
    try {
      const parsedAuth = parseAuthorization();
      let previouslySelectedOrganization = state.selectedOrganization;

      if (parsedAuth) {
        const userResponse = await getUser();
        const userResponseData = userResponse?.data?.data;

        // ensure previously selected organization wasn't archived
        if (previouslySelectedOrganization) {
          previouslySelectedOrganization = first(
            userResponseData.organizations.filter(
              (o) => o.organizationId ===
                previouslySelectedOrganization.organizationId
            )
          );
        }

        const currentOrganizationId = previouslySelectedOrganization
          ? first(
            userResponseData.organizations.filter(
              (o) => o.organizationId ===
                previouslySelectedOrganization.organizationId
            )
          )?.organizationId
          : first(userResponseData.organizations)?.organizationId;

        let manufacturersResponseData = [];
        let foundManufacturer = null;
        if (userResponseData?.permissions.includes(READ_RAM_LICENSE_DETAILS)) {
          manufacturersResponseData = (await Promise.resolve(
            listAllManufacturers()
          ))?.data;

          if (userResponseData?.permissions.includes(UPDATE_ORG_RAM_APPROVAL)) {
            foundManufacturer = manufacturersResponseData.find(
              (m) => m?.info?.organizationId === currentOrganizationId
            );
          }
        }

        // get orgInfo that's not returned with userResponseData
        const selectedOrganizationResponseData = currentOrganizationId
          ? (await getOrganization(currentOrganizationId))?.data?.data
          : {};

        // fetch prescriber information if user has prescriberId
        let prescriber = null;
        const userPrescriberId = userResponseData?.prescriberId;
        if (userPrescriberId) {
          prescriber = (await getPrescriber(userPrescriberId))?.data?.data;
        }

        // fetch org/user permissions
        let userPreferencesResponseData = {};
        let organizationPreferencesResponseData = {};
        try {
          if (
            userResponseData?.permissions.includes(UPDATE_PREFERENCES) ||
            userResponseData?.permissions.includes(
              UPDATE_ORGANIZATION_PREFERENCES
            )
          ) {
            const [
              userPreferencesResponse,
              organizationPreferencesResponse
            ] = await Promise.all([
              getUserPreferences(
                currentOrganizationId,
                userResponseData?.userId
              ),
              getOrganizationPreferences(currentOrganizationId)
            ]);

            userPreferencesResponseData =
              userPreferencesResponse?.data?.data ?? {};
            organizationPreferencesResponseData =
              organizationPreferencesResponse?.data?.data ?? {};
          }
        } catch (err) {
          console.log('preferences not found: ', err);
        }

        let orgAdminGroups;

        if (
          userResponseData.permissions.includes(MANAGE_GROUPS) &&
          userResponseData?.parentOrganization?.parentOrganizationId
        ) {
          await getParentOrganization(
            userResponseData?.parentOrganization?.parentOrganizationId
          ).then((response) => {
            const responseData = response.data?.data;
            if (responseData) {
              orgAdminGroups = responseData.organizations;
            }
          });
        }
        dispatch({
          type: 'INITIALIZE',
          payload: {
            user: {
              ...userResponseData,
              email: first(userResponseData.emails)?.emailAddress,
              sessionExpirationDate: parsedAuth.expires,
              branding: parsedAuth.branding,
              organizations: userResponseData.organizations,
              userPreferences: userPreferencesResponseData
            },
            prescriber,
            organizations: userResponseData?.permissions?.includes(
              MANAGE_GROUPS
            )
              ? (orgAdminGroups ?? userResponseData.organizations)
              : userResponseData.organizations,
            selectedParentOrganization: userResponseData.parentOrganization,
            selectedOrganization: previouslySelectedOrganization
              ? {
                organizationPreferences: organizationPreferencesResponseData,
                ...selectedOrganizationResponseData,
                ...first(
                  userResponseData.organizations.filter(
                    (o) => o.organizationId ===
                      previouslySelectedOrganization.organizationId
                  )
                )
              }
              : {
                organizationPreferences: organizationPreferencesResponseData,
                ...selectedOrganizationResponseData,
                ...first(userResponseData.organizations)
              },
            manufacturer: foundManufacturer,
            manufacturers: manufacturersResponseData
          }
        });

        if (
          userResponseData.permissions.includes(MANAGE_PARENT_ORGANIZATIONS)
        ) {
          await listParentOrgs().then((response) => {
            const responseData = response.data?.filter(
              (po) => po.organizationType !== 'Patient'
            );
            dispatch({
              type: 'UPDATE',
              payload: {
                parentOrganizations: responseData ?? []
              }
            });
          });
        }
      } else {
        dispatch({ type: 'INITIALIZE' });
      }
    } catch (e) {
      dispatch({ type: 'ERROR' });
    }
    setLoading(false);
  };

  useEffect(() => {
    buildUser();
  }, []);

  if (state.error) {
    return <ErrorBoundaryView />;
  }

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loading,
        addToUserOrganizations,
        hasAnyPermissionsIn,
        hasPermission,
        hasPermissions,
        logout,
        redirectToLogin,
        refreshUser,
        updateSelectedOrganization,
        updateSelectedParentOrganization,
        updatePreferredCommunicationType
      }}
    >
      {children}
      {hasPermission(COMMUNICATION_CONSENT) &&
        state.user?.preferredCommunicationType === undefined && (
          <PreferredCommunicationDialog
            user={state?.user ?? null}
            refreshUser={refreshUser ?? (() => {})}
          />
      )}
      {state?.user && <SessionTimeout />}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
