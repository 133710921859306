import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme
} from '@mui/material';
import { InlineIcon } from '@iconify/react';
import { ExpandLess, ExpandMore } from '../../../../constants/icons';

const TextNavButton = ({
  icon, label, options, to
}) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuOptions = options?.map((o) => (
    <MenuItem
      key={o.label}
      onClick={() => {
        setMenuOpen(false);
        navigate(o.to);
      }}
    >
      <ListItemIcon>
        <InlineIcon color="inherit" icon={o.icon} width={20} />
      </ListItemIcon>
      <ListItemText>{o.label}</ListItemText>
    </MenuItem>
  ));

  const handleOpen = () => {
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Button
        component={to && RouterLink}
        endIcon={
          menuOptions?.length && (
            <InlineIcon icon={menuOpen ? ExpandLess : ExpandMore} />
          )
        }
        onClick={to ? undefined : handleOpen}
        ref={ref}
        size="medium"
        startIcon={
          <InlineIcon color={theme.palette.text.title} icon={icon} width={28} />
        }
        sx={{
          color: theme.palette.tertiary.main,
          textTransform: 'none'
        }}
        to={to}
      >
        <Typography variant="h4" color={theme.palette.text.title}>
          {label}
        </Typography>
      </Button>
      {menuOptions?.length && (
        <Menu
          anchorEl={ref.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          onClose={handleClose}
          open={menuOpen}
        >
          {menuOptions}
        </Menu>
      )}
    </>
  );
};

TextNavButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  to: PropTypes.string
};

export default TextNavButton;
