import AddressIcon from '@iconify-icons/mdi/address-marker-outline';
import AccountAlertIcon from '@iconify-icons/mdi/account-alert-outline';
import AccountCancelIcon from '@iconify-icons/mdi/account-cancel';
import AccountCheckOutlineIcon from '@iconify-icons/mdi/account-check';
import AccountEditOutlineIcon from '@iconify-icons/mdi/account-edit-outline';
import AccountPlusOutlineIcon from '@iconify-icons/mdi/account-plus-outline';
import AccountQuestionOutlineIcon from '@iconify-icons/mdi/account-question-outline';
import AddIcon from '@iconify-icons/mdi/add';
import AddDocumentIcon from '@iconify-icons/mdi/file-document-add-outline';
import AddDocumentPlusIcon from '@iconify-icons/mdi/file-document-plus-outline';
import AddNoteIcon from '@iconify-icons/mdi/note-edit-outline';
import AddUserIcon from '@iconify-icons/mdi/account-plus';
import AddUsersIcon from '@iconify-icons/mdi/account-multiple-plus-outline';
import AdminIcon from '@iconify-icons/mdi/account-key';
import ApplicationEditIcon from '@iconify-icons/mdi/application-edit-outline';
import ApplicationImportIcon from '@iconify-icons/mdi/application-import';
import ArchiveIcon from '@iconify-icons/mdi/archive';
import Auth0Icon from '@iconify/icons-cib/auth0';
import BrainIcon from '@iconify-icons/bx/bxs-brain';
import BellIcon from '@iconify-icons/mdi/bell-outline';
import ClipboardTextIcon from '@iconify-icons/mdi/clipboard-text-outline';
import ClockOutlineIcon from '@iconify-icons/mdi/clock-outline';
import ClockPlusOutlineIcon from '@iconify-icons/mdi/clock-plus-outline';
import AttachIcon from '@iconify-icons/mdi/attach-file';
import ArrowBackIcon from '@iconify-icons/mdi/arrow-back';
import ArrowForwardIcon from '@iconify-icons/mdi/arrow-forward';
import ArrowUpwardIcon from '@iconify-icons/mdi/arrow-upward';
import ArrowDownwardIcon from '@iconify-icons/mdi/arrow-downward';
import LockAccountIcon from '@iconify-icons/mdi/account-lock-outline';
import BriefCaseEditIcon from '@iconify-icons/mdi/briefcase-edit-outline';
import BuildingCircleArrowRightIcon from '@iconify/icons-fa6-solid/building-circle-arrow-right';
import CancelIcon from '@iconify-icons/mdi/cancel';
import CancelMultipleIcon from '@iconify-icons/mdi/cancel-box-multiple';
import CalculatorIcon from '@iconify-icons/mdi/calculator';
import CalendarIcon from '@iconify-icons/mdi/calendar-blank';
import CalendarCheckIcon from '@iconify-icons/mdi/calendar-check-outline';
import CalendarClockIcon from '@iconify-icons/mdi/calendar-clock';
import CalendarEditIcon from '@iconify-icons/mdi/calendar-edit-outline';
import CalendarMultiSelectIcon from '@iconify-icons/mdi/calendar-multiselect-outline';
import CallMergeIcon from '@iconify-icons/mdi/call-merge';
import CardTextOutlineIcon from '@iconify-icons/mdi/card-text-outline';
import CardAccountPhoneOutlineIcon from '@iconify-icons/mdi/card-account-phone-outline';
import ClipboardRemoveOutline from '@iconify-icons/mdi/clipboard-remove-outline';
import ChatApproveIcon from '@iconify-icons/akar-icons/chat-approve';
import CheckCircleOutline from '@iconify-icons/mdi/check-circle-outline';
import CheckIcon from '@iconify-icons/mdi/check';
import CheckboxMarkedIcon from '@iconify-icons/mdi/checkbox-marked';
import CheckboxUnmarkedIcon from '@iconify-icons/mdi/checkbox-blank-outline';
import CheckCircleForm from '@iconify-icons/mdi/playlist-add-check';
import CheckCirclePlusOutlineIcon from '@iconify-icons/mdi/checkbox-marked-circle-plus-outline';
import ChevronLeftIcon from '@iconify-icons/mdi/chevron-left';
import ChevronRightIcon from '@iconify-icons/mdi/chevron-right';
import CircularArrowReverseIcon from '@iconify-icons/mdi/cached';
import ClipboardIcon from '@iconify-icons/mdi/clipboard-outline';
import ClipboardClockIcon from '@iconify-icons/mdi/clipboard-clock-outline';
import ClipboardPlusIcon from '@iconify-icons/mdi/clipboard-plus-outline';
import ClipboardCheckIcon from '@iconify-icons/mdi/clipboard-check-outline';
import ClipboardTextMultipleOutlineIcon from '@iconify-icons/mdi/clipboard-text-multiple-outline';
import CloseCircleOutlineIcon from '@iconify-icons/mdi/close-circle-outline';
import CloseIcon from '@iconify-icons/mdi/close';
import CloudDownloadIcon from '@iconify-icons/mdi/cloud-download';
import CompleteIcon from '@iconify-icons/carbon/task-complete';
import CogIcon from '@iconify-icons/mdi/cog-outline';
import CollapseAllIcon from '@iconify-icons/mdi/collapse-all';
import CommentMedicalIcon from '@iconify-icons/fa-solid/comment-medical';
import CommentMultipleOutline from '@iconify-icons/mdi/comment-multiple-outline';
import ContentCopyIcon from '@iconify-icons/mdi/content-copy';
import CoordinateRectangleIcon from '@iconify-icons/mdi/vector-rectangle';
import CreditCardHeartIcon from '@iconify-icons/material-symbols/credit-card-heart-outline-rounded';
import DatabaseIcon from '@iconify-icons/mdi/database-outline';
import DeleteIcon from '@iconify-icons/mdi/delete';
import DesktopIcon from '@iconify-icons/mdi/desktop-classic';
import DoctorIcon from '@iconify-icons/mdi/doctor';
import DotsHorizontalCircleOutlineIcon from '@iconify-icons/mdi/dots-horizontal-circle-outline';
import ChatIcon from '@iconify-icons/bx/chat';
import EditIcon from '@iconify-icons/mdi/square-edit-outline';
import EmailReceiveIcon from '@iconify-icons/mdi/email-receive-outline';
import EmailEditIcon from '@iconify-icons/mdi/email-edit-outline';
import EmailSyncOutlineIcon from '@iconify-icons/mdi/email-sync-outline';
import EmoticonSickOutline from '@iconify-icons/mdi/emoticon-sick-outline';
import ExclamationThickIcon from '@iconify-icons/mdi/exclamation-thick';
import ExpandAllIcon from '@iconify-icons/mdi/expand-all';
import ExpandMoreIcon from '@iconify-icons/mdi/expand-more';
import ExpandLessIcon from '@iconify-icons/mdi/expand-less';
import EyeIcon from '@iconify-icons/mdi/eye';
import EyeOffIcon from '@iconify-icons/mdi/eye-off';
import FaxIcon from '@iconify-icons/mdi/fax';
import FiberManualRecordIcon from '@iconify-icons/mdi/fiber-manual-record';
import FileDownloadIcon from '@iconify-icons/mdi/file-download-outline';
import FileTreeIcon from '@iconify-icons/mdi/file-tree';
import FilterIcon from '@iconify-icons/mdi/filter-outline';
import FilterOffIcon from '@iconify-icons/mdi/filter-off-outline';
import FormatListBulleted from '@iconify-icons/mdi/format-list-bulleted';
import FormatListNumbered from '@iconify-icons/mdi/format-list-numbered';
import HeartIcon from '@iconify-icons/mdi/heart-outline';
import HeartPlusIcon from '@iconify-icons/mdi/heart-plus-outline';
import HelpIcon from '@iconify-icons/mdi/help-circle-outline';
import HomeIcon from '@iconify-icons/mdi/home';
import InfoOutlineIcon from '@iconify-icons/mdi/information-outline';
import InfoSearchIcon from '@iconify-icons/fluent/search-info-24-regular';
import IvBagIcon from '@iconify-icons/mdi/iv-bag';
import LightningBoltIcon from '@iconify-icons/mdi/lightning-bolt';
import LightbulbIcon from '@iconify-icons/mdi/lightbulb-on-outline';
import ListPlusIcon from '@iconify-icons/mdi/playlist-plus';
import LocationHazardIcon from '@iconify-icons/carbon/location-hazard';
import LockPlusIcon from '@iconify-icons/mdi/lock-plus-outline';
import LogoutIcon from '@iconify-icons/mdi/logout';
import MagnifyIcon from '@iconify-icons/mdi/magnify';
import MailIcon from '@iconify-icons/mdi/email-outline';
import MailCheckIcon from '@iconify-icons/mdi/email-check-outline';
import MedicalFileIcon from '@iconify-icons/icon-park-outline/medical-files';
import MedicationIcon from '@iconify-icons/ic/baseline-medication';
import MenuIcon from '@iconify-icons/mdi/menu';
import MessageAddIcon from '@iconify-icons/bx/message-add';
import MessageOutlineIcon from '@iconify-icons/mdi/message-outline';
import MessagePlusOutlineIcon from '@iconify-icons/mdi/message-plus-outline';
import NoteIcon from '@iconify-icons/mdi/note';
import NotebookRefreshIcon from '@iconify-icons/fluent/notebook-sync-24-regular';
import AttachMoneyIcon from '@iconify-icons/mdi/attach-money';
import NewIcon from '@iconify-icons/mdi/new-box';
import NotebookIcon from '@iconify-icons/fluent/notebook-24-regular';
import NotebookAddIcon from '@iconify-icons/fluent/notebook-add-24-regular';
import OrganizationIcon from '@iconify-icons/mdi/account-group-outline';
import PatientListIcon from '@iconify-icons/material-symbols/patient-list-outline';
import PersonAddIcon from '@iconify-icons/mdi/person-add';
import PersonMultipleCheckIcon from '@iconify-icons/mdi/person-multiple-check-outline';
import PharmacyIcon from '@iconify-icons/mdi/pharmacy';
import PhoneIcon from '@iconify-icons/mdi/phone';
import PhonePlusIcon from '@iconify-icons/mdi/phone-plus';
import PillIcon from '@iconify-icons/mdi/pill';
import PlayCircleFilledIcon from '@iconify-icons/mdi/play-circle';
import PlusCircleOutlineIcon from '@iconify-icons/mdi/plus-circle-outline';
import PlusMinusIcon from '@iconify-icons/mdi/plus-minus-variant';
import PrintIcon from '@iconify-icons/mdi/printer';
import PrescriptionIcon from '@iconify-icons/mdi/prescription';
import RadioactiveIcon from '@iconify-icons/mdi/radioactive';
import RefreshIcon from '@iconify-icons/mdi/refresh';
import RemoveUserIcon from '@iconify-icons/mdi/account-minus-outline';
import SaveIcon from '@iconify-icons/mdi/content-save';
import SaveAllIcon from '@iconify-icons/mdi/content-save-all';
import SearchDollar from '@iconify-icons/fa-solid/search-dollar';
import SearchIcon from '@iconify-icons/mdi/search';
import SendIcon from '@iconify-icons/mdi/send';
import SetMergeIcon from '@iconify-icons/mdi/set-merge';
import SignatureIcon from '@iconify-icons/mdi/signature';
import ShieldAccountIcon from '@iconify-icons/mdi/shield-account-outline';
import ShieldOutlineIcon from '@iconify-icons/mdi/shield-outline';
import ShieldStarIcon from '@iconify-icons/mdi/shield-star-outline';
import SourceBranchIcon from '@iconify-icons/mdi/source-branch';
import StarIcon from '@iconify-icons/mdi/star';
import StethoscopeIcon from '@iconify-icons/mdi/stethoscope';
import SupportIcon from '@iconify-icons/bx/bx-support';
import SwaggerIcon from '@iconify/icons-cib/swagger';
import SwapHorizontalIcon from '@iconify-icons/mdi/swap-horizontal';
import SyringeIcon from '@iconify-icons/mdi/syringe';
import SyringeOffIcon from '@iconify-icons/mdi/syringe-off';
import TextBoxEditIcon from '@iconify-icons/mdi/text-box-check';
import TextBoxMultipleIcon from '@iconify-icons/mdi/text-box-multiple-outline';
import TextSearchIcon from '@iconify-icons/mdi/text-search';
import ToolsIcon from '@iconify-icons/mdi/tools';
import UndoVariantIcon from '@iconify-icons/mdi/undo-variant';
import UnlockAccountIcon from '@iconify-icons/mdi/account-lock-open-outline';
import UsersIcon from '@iconify-icons/mdi/account-multiple-outline';
import UserIcon from '@iconify-icons/mdi/user-outline';
import UserHeartIcon from '@iconify-icons/mdi/user-heart-outline';
import UserDetails from '@iconify-icons/mdi/account-details-outline';
import UserRemoveIcon from '@iconify-icons/mdi/user-multiple-remove-outline';
import ViewDay from '@iconify-icons/mdi/view-day';
import ViewDayOutline from '@iconify-icons/mdi/view-day-outline';
import VialIcon from '@iconify-icons/bx/vial';
import VisibilityOffIcon from '@iconify-icons/mdi/visibility-off';
import VisibilityIcon from '@iconify-icons/mdi/visibility';
import WarningStandardLine from '@iconify/icons-clarity/warning-standard-line';
import WeatherLightningIcon from '@iconify-icons/mdi/weather-lightning';
import WebIcon from '@iconify-icons/mdi/web';

import { toLower } from 'lodash';
import {
  A4MePapAppealPharmacyId,
  A4MePapPharmacyId,
  AdverseEventPharmacyId,
  GeneralInquiryPharmacyId,
  MedAccessQueuePharmacyId,
  PreMedAccessQueuePharmacyId,
  RxLightningTestFormsPharmacyId
} from './pharmacies';

export const Address = AddressIcon;
export const Add = AddIcon;
export const AddAnonymizedPatient = ShieldAccountIcon;
export const AddUser = AddUserIcon;
export const AddUsers = AddUsersIcon;
export const AddCase = NotebookAddIcon;
export const AddCircle = PlusCircleOutlineIcon;
export const AdditionalInfo = DotsHorizontalCircleOutlineIcon;
export const AddCaseNote = BriefCaseEditIcon;
export const AddDocument = AddDocumentIcon;
export const AddMessage = MessagePlusOutlineIcon;
export const AddNote = AddNoteIcon;
export const AddPatient = AccountPlusOutlineIcon;
export const AddPrescriber = HeartPlusIcon;
export const AddTime = ClockPlusOutlineIcon;
export const Admin = AdminIcon;
export const AdverseEvent = EmoticonSickOutline;
export const ApplicationEdit = ApplicationEditIcon;
export const ApproveAccount = AccountCheckOutlineIcon;
export const Archive = ArchiveIcon;
export const Attach = AttachIcon;
export const ArrowLeft = ArrowBackIcon;
export const ArrowRight = ArrowForwardIcon;
export const ArrowUp = ArrowUpwardIcon;
export const ArrowDown = ArrowDownwardIcon;
export const Auth0 = Auth0Icon;
export const BaseQuestionManager = TextBoxEditIcon;
export const Branch = FileTreeIcon;
export const Calculator = CalculatorIcon;
export const Calendar = CalendarIcon;
export const CalendarDay = ViewDay;
export const CalendarDayOutline = ViewDayOutline;
export const CalendarAvailability = CalendarMultiSelectIcon;
export const CalendarCheck = CalendarCheckIcon;
export const Cancel = CancelIcon;
export const CancelMultiple = CancelMultipleIcon;
export const CancelEnrollment = ClipboardRemoveOutline;
export const Category = CardTextOutlineIcon;
export const Case = NotebookIcon;
export const CaseReopen = NotebookRefreshIcon;
export const Change = SwapHorizontalIcon;
export const ClipboardClock = ClipboardClockIcon;
export const Check = CheckIcon;
export const CheckboxMarked = CheckboxMarkedIcon;
export const CheckboxUnmarked = CheckboxUnmarkedIcon;
export const CheckPlus = CheckCirclePlusOutlineIcon;
export const ChevronLeft = ChevronLeftIcon;
export const ChevronRight = ChevronRightIcon;
export const CircularArrowReverse = CircularArrowReverseIcon;
export const Clock = ClockOutlineIcon;
export const Close = CloseIcon;
export const Cog = CogIcon;
export const CollapseAll = CollapseAllIcon;
export const Complete = CompleteIcon;
export const Conditional = SourceBranchIcon;
export const Contact = CardAccountPhoneOutlineIcon;
export const ContentCopy = ContentCopyIcon;
export const CoordinateMapping = CoordinateRectangleIcon;
export const CreateCall = PhonePlusIcon;
export const CreateEnrollment = ClipboardPlusIcon;
export const CreditCardHeart = CreditCardHeartIcon;
export const Database = DatabaseIcon;
export const DateTime = CalendarClockIcon;
export const Delete = DeleteIcon;
export const DenyAccount = AccountCancelIcon;
export const Desktop = DesktopIcon;
export const Destination = EmailReceiveIcon;
export const Doctor = DoctorIcon;
export const Document = AddDocumentPlusIcon;
export const Dollar = AttachMoneyIcon;
export const DownloadDocument = CloudDownloadIcon;
export const DownloadPdf = FileDownloadIcon;
export const Drug = PillIcon;
export const Duplicate = ClipboardTextMultipleOutlineIcon;
export const Edit = EditIcon;
export const EditCalendar = CalendarEditIcon;
export const EditUser = AccountEditOutlineIcon;
export const Enrollment = ClipboardIcon;
export const EnrollmentComplete = ClipboardCheckIcon;
export const Exclamation = ExclamationThickIcon;
export const ExpandAll = ExpandAllIcon;
export const ExpandLess = ExpandLessIcon;
export const ExpandMore = ExpandMoreIcon;
export const ExternalFile = ApplicationImportIcon;
export const Eye = EyeIcon;
export const EyeOff = EyeOffIcon;
export const Fax = SendIcon;
export const FaxMachine = FaxIcon;
export const FilledCircle = FiberManualRecordIcon;
export const Filter = FilterIcon;
export const FilterOff = FilterOffIcon;
export const FoundationSearch = SearchDollar;
export const GeneralInquiry = AccountQuestionOutlineIcon;
export const Help = HelpIcon;
export const History = CommentMultipleOutline;
export const Home = HomeIcon;
export const Hub = SupportIcon;
export const Info = InfoOutlineIcon;
export const InfoSearch = InfoSearchIcon;
export const Infusion = IvBagIcon;
export const LDD = BuildingCircleArrowRightIcon;
export const LightningBolt = LightningBoltIcon;
export const List = FormatListBulleted;
export const ListPlus = ListPlusIcon;
export const LocationHazard = LocationHazardIcon;
export const Lock = LockAccountIcon;
export const Logout = LogoutIcon;
export const Magnify = MagnifyIcon;
export const Mail = MailIcon;
export const MailCheck = MailCheckIcon;
export const MedAccessQueue = PatientListIcon;
export const MedicalFile = MedicalFileIcon;
export const Menu = MenuIcon;
export const Merge = CallMergeIcon;
export const Message = MessageOutlineIcon;
export const New = NewIcon;
export const NewMessage = EmailEditIcon;
export const Notification = BellIcon;
export const Note = NoteIcon;
export const NumberedList = FormatListNumbered;
export const Organization = OrganizationIcon;
export const PAP = MedicationIcon;
export const Patient = UserHeartIcon;
export const PatientDetails = UserDetails;
export const Pharmacy = PharmacyIcon;
export const Phone = PhoneIcon;
export const Play = PlayCircleFilledIcon;
export const PlusMinus = PlusMinusIcon;
export const Prescriber = HeartIcon;
export const Prescription = PrescriptionIcon;
export const Print = PrintIcon;
export const PriorAuthorization = ChatApproveIcon;
export const Register = PersonAddIcon;
export const PendingRegistrationRequests = PersonMultipleCheckIcon;
export const Radioactive = RadioactiveIcon;
export const Refresh = RefreshIcon;
export const Remove = CloseCircleOutlineIcon;
export const RemoveUser = RemoveUserIcon;
export const RemovePatient = UserRemoveIcon;
export const Resend = EmailSyncOutlineIcon;
export const Save = SaveIcon;
export const SaveAll = SaveAllIcon;
export const Search = SearchIcon;
export const SearchList = TextSearchIcon;
export const Send = SendIcon;
export const SetMerge = SetMergeIcon;
export const SmartFormIntake = BrainIcon;
export const Sign = SignatureIcon;
export const Shield = ShieldOutlineIcon;
export const ShieldStar = ShieldStarIcon;
export const Star = StarIcon;
export const Stethoscope = StethoscopeIcon;
export const OrderManagement = WeatherLightningIcon;
export const Swagger = SwaggerIcon;
export const TextBox = TextBoxMultipleIcon;
export const Tools = ToolsIcon;
export const Training = LightbulbIcon;
export const Undo = UndoVariantIcon;
export const Unlock = UnlockAccountIcon;
export const Update = MessageAddIcon;
export const UrgentNotificationPreferences = AccountAlertIcon;
export const UserStatus = CommentMedicalIcon;
export const Users = UsersIcon;
export const User = UserIcon;
export const UpdateHistory = ChatIcon;
export const Verify = CheckCircleOutline;
export const AddAuthCode = LockPlusIcon;

export const AutoCompleteIcon = CheckCircleForm;
export const Vial = VialIcon;
export const ViewEnrollment = ClipboardTextIcon;
export const VisibilityOff = VisibilityOffIcon;
export const VisibilityOn = VisibilityIcon;
export const Warning = WarningStandardLine;
export const Web = WebIcon;

export const Syringe = SyringeIcon;
export const SyringeOff = SyringeOffIcon;

export const determineIconsFromFormTypes = (types) => {
  if (!types?.length) {
    return [Pharmacy];
  }

  const lowerTypes = types.map((t) => toLower(t));
  const icons = [];

  if (lowerTypes.includes('hub')) {
    icons.push(Hub);
  }

  if (lowerTypes.includes('pap')) {
    icons.push(PAP);
  }

  if (lowerTypes.includes('infusion')) {
    icons.push(Infusion);
  }

  if (lowerTypes.includes('foundation')) {
    icons.push(Dollar);
  }

  if (lowerTypes.includes('pa')) {
    icons.push(PriorAuthorization);
  }

  if (lowerTypes.includes('specialty') || lowerTypes.includes('pharmacy')) {
    icons.push(Pharmacy);
  }

  if (lowerTypes.includes('general inquiry')) {
    icons.push(GeneralInquiry);
  }

  if (lowerTypes.includes('adverse event')) {
    icons.push(AdverseEvent);
  }

  if (lowerTypes.includes('med access queue')) {
    icons.push(MedAccessQueue);
  }

  return icons;
};

export const determineDestinationTypes = (destination) => {
  const types = [];

  if (!destination) {
    return types;
  }

  if (destination.pharmacyId === RxLightningTestFormsPharmacyId) {
    types.push('Enrollment');
  }

  if (destination.pharmacyId === AdverseEventPharmacyId) {
    types.push('Adverse Event');
  }

  if (destination.pharmacyId === GeneralInquiryPharmacyId) {
    types.push('General Inquiry');
  }

  if (destination.isSpecialty) {
    types.push('Pharmacy');
  }

  if (destination.isInfusion) {
    types.push('Infusion');
  }

  if (destination.isHub || toLower(destination.name)?.includes('hub')) {
    types.push('Hub');
  }

  if (
    destination.isPap ||
    destination.pharmacyId === A4MePapPharmacyId ||
    destination.pharmacyId === A4MePapAppealPharmacyId ||
    toLower(destination.name)?.includes('pap')
  ) {
    types.push('PAP');
  }

  if (destination.isEPA) {
    types.push('PA');
  }

  if (destination.isFoundation) {
    types.push('Foundation');
  }

  if (
    destination.pharmacyId === MedAccessQueuePharmacyId ||
    destination.pharmacyId === PreMedAccessQueuePharmacyId
  ) {
    types.push('Med Access Queue');
  }

  return types;
};
