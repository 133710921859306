import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import UnauthorizedView from '../../views/errors/UnauthorizedView';

// enforces a valid login session and permissions if provided
const AuthGuard = ({ children, permissions, requireAllPermissions }) => {
  const {
    hasAnyPermissionsIn,
    hasPermissions,
    redirectToLogin,
    user
  } = useAuth();

  if (!user) {
    redirectToLogin(`?redirectUrl=${window.location.href}`);
    return <></>;
  }

  if (
    !user ||
    (permissions?.length &&
      ((requireAllPermissions && !hasPermissions(permissions)) ||
        (!requireAllPermissions && !hasAnyPermissionsIn(permissions))))
  ) {
    return <UnauthorizedView />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  permissions: PropTypes.arrayOf(PropTypes.string),
  requireAllPermissions: PropTypes.bool
};

export default AuthGuard;
