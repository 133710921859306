import axios from 'axios';

const prescriberAgentApi = (apiConfig) => {
  const listPrescriberAgentsForPrescriber = (prescriberId, organizationId) => axios.get(`/v1/authcode/delegates/prescriber/${prescriberId}?organizationId=${organizationId}`, apiConfig);

  const listPrescriberDelegatorsForUser = (userId, organizationId) => axios.get(`/v1/authcode/delegators/prescriber/${userId}?organizationId=${organizationId}`, apiConfig);

  const assignPrescriberAgents = (prescriberId, userIds, organizationId) => axios.post(`v1/authcode/prescriber-agents/${prescriberId}?organizationId=${organizationId}`, userIds, apiConfig);

  const removePrescriberAgent = (prescriberId, userId, organizationId) => axios.delete(
    `v1/authcode/prescriber-agent/${prescriberId}?agentUserId=${userId}&organizationId=${organizationId}`, apiConfig
  );

  const removePrescriberAgents = (prescriberId, userIds, organizationId) => axios.delete(
    `v1/authcode/prescriber-agents/${prescriberId}?organizationId=${organizationId}`,
    {
      ...apiConfig,
      data: userIds
    }
  );

  const saveUserAuthCode = (userId, authCode) => axios.post(`/v1/authcode/user-auth-code/${userId}`, {}, {
    ...apiConfig,
    params: {
      authCode
    }
  });

  const savePrescriberAuthCode = (prescriberId, authCode) => axios.post(`/v1/authcode/prescriber-auth-code/${prescriberId}`, {}, {
    ...apiConfig,
    params: {
      authCode
    }
  });

  return {
    listPrescriberAgentsForPrescriber,
    listPrescriberDelegatorsForUser,
    assignPrescriberAgents,
    removePrescriberAgent,
    removePrescriberAgents,
    saveUserAuthCode,
    savePrescriberAuthCode
  };
};

export default prescriberAgentApi;
