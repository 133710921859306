import React from 'react';
import {
  Box, Button, Container, Typography
} from '@mui/material';

const ErrorBoundaryView = () => (
  <Container>
    <Box textAlign="center" sx={{ m: 5 }}>
      <Typography variant="h3">Oops...</Typography>
      <Typography variant="h6">An unexpected error has occurred.</Typography>
      <Button variant="outlined" color="secondary" size="small" onClick={() => window.location.reload()}>Try Again!</Button>
    </Box>
  </Container>
);

export default ErrorBoundaryView;
