import axios from 'axios';

const userManagementApi = (apiConfig) => {
  const registerUser = (user) => axios.post('/v1/UserRegistration', user, apiConfig);

  const createSupportRequest = (request) => axios.post('/v1/supportrequest', request, apiConfig);

  return {
    registerUser,
    createSupportRequest
  };
};

export default userManagementApi;
