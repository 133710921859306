import axios from 'axios';

const featuresApi = (apiConfigNoAuthToken) => {
  const getFeatures = () => axios.get('/v1/features', apiConfigNoAuthToken);

  return {
    getFeatures
  };
};

export default featuresApi;
