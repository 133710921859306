import axios from 'axios';

const anonymousPatientApi = (apiConfig) => {
  const getAnonymousPatient = (anonymousPatientId) => axios.get(`/v1/patient/anon/${anonymousPatientId}`, apiConfig);
  const updateAnonymousPatient = (payload) => axios.put('/v1/patient/anon', payload, apiConfig);

  return {
    getAnonymousPatient,
    updateAnonymousPatient
  };
};

export default anonymousPatientApi;
