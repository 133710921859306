export const RolvedonDrugId = '8be6deaf-ef39-48ca-9d71-41343d79c0b4';
export const BoltDrugId = 'd33bfaf2-a964-47b1-b679-1afd5f01e44d';
export const TymlosDrugId = '7147be4e-2aec-4550-8104-ea7282ce1175';
export const FoundationDrugId = '0b9816ce-8e8d-4bdd-9472-5e6ea9822519';
export const BelsomraDrugId = '83ca08bf-6611-4ef0-b90e-fcc9a7072700';
export const DelstrigoDrugId = '1a268bd2-5009-42a9-8e47-d1a460b266d6';
export const DificidDrugId = 'b3694c05-bef7-4a5c-a709-521c07072751';
export const EmendDrugId = 'c1ef3af1-71ac-47ca-b2e1-79911e8c4a63';
export const IsentressDrugId = 'cfd4e36c-0a9f-49af-ade3-b7a4ba54b022';
export const JanumetDrugId = '75e3dda0-4ed4-457e-bca2-80bb5f21df9f';
export const JanuviaDrugId = '706f993b-04b2-4406-9eac-c033991a68b7';
export const NoxafilDrugId = 'f6abade0-fa16-43b6-ad66-1724e976a86c';
export const PifeltroDrugId = '1534588a-f79c-4072-b9bc-6949d64de701';
export const StromectolDrugId = '3cd7f112-cc7f-4367-9fad-9df0d7412320';
export const ZepatierDrugId = '46910656-f5b4-4ba0-9265-07570a6aca1f';
export const ZolinzaDrugId = '76324367-95c4-465a-8dc3-04098a7b393b';
export const PluvictoDrugId = 'adbb71e4-9507-48ca-933d-b52211e4a288';
export const LutatheraDrugId = 'bda9e9ff-9df5-48f5-b957-f17a6eee378c';
export const IlumyaDrugId = '4755c968-c10f-4399-abc6-068177baac7e';
export const RPTDrug1Id = '997e665b-44de-4775-8d20-1479c46fb0a2';
export const RPTDrug2Id = 'c3d10dc6-cc9b-4e55-8c36-a58fc73d78db';
export const KorlymDrugId = 'b871ff09-b101-48db-9f58-5da412e7fa56';
