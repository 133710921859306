import axios from 'axios';

const prescriberApi = (apiConfig, apiConfigNoAuthToken) => {
  const getPrescriber = (prescriberId) => axios.get(`/v1/prescriber/${prescriberId}`, apiConfig);

  const createPrescriber = (prescriber) => axios.post('/v1/prescriber', prescriber, apiConfig);

  const updatePrescriber = (prescriberId, prescriber) => axios.put(`/v1/prescriber/${prescriberId}`, prescriber, apiConfig);

  const deletePrescriber = (prescriberId, organizationId) => axios.delete(`/v1/prescriber/${prescriberId}`, {
    ...apiConfig,
    params: {
      organizationId
    }
  });

  const listPrescribers = (onlyVerified, organizationId) => axios.get('/v1/prescribers', {
    ...apiConfig,
    params: {
      pageSize: 3000,
      verified: onlyVerified,
      organizationId
    }
  });

  const listNpiPrescribers = (npi, firstName, lastName, state) => axios.get('/v1/npiregistry', {
    ...apiConfig,
    params: {
      npi: npi || undefined,
      firstName: firstName || undefined,
      lastName: lastName || undefined,
      state: state?.abbreviation ?? state?.id
    }
  });

  const signBaa = (prescriberAuthorization) => axios.post('/v1/prescriberauthorization', prescriberAuthorization, apiConfig);

  const getCurrentlyLoggedInPrescriber = () => axios.get('/v1/prescriberinfo', apiConfig);

  const setPrescriberAuthorization = (prescriberAuthorization) => axios.post('/v1/prescriberauthorization', prescriberAuthorization, apiConfigNoAuthToken);

  const verifyChallengeCode = (registrationCode) => axios.get(`/v1/prescriberverification/${registrationCode}`, apiConfigNoAuthToken);

  const updateVialCount = (prescriberProductReplacement) => axios.post('/v1/prescriberProductReplacement', prescriberProductReplacement, apiConfig);

  const getPrescriberProductReplacement = () => axios.get(
    '/v1/prescriberProductReplacement?pageSize=3000&embeds=prescriber,drug',
    apiConfig
  );

  const registerPrescribersForOrg = (prescribers) => axios.post('/v1/prescribers', prescribers, apiConfig);

  const generatePrescriberVerificationUrl = (prescriberId) => axios.get(`/v1/prescriberverification/${prescriberId}/authCode`, apiConfig);
  const sendPrescriberVerificationUrl = (
    prescriberId,
    recipientName,
    prescriberEmail,
    organizationId
  ) => axios.get(
    `/v1/prescriberverification/${prescriberId}/authCode?emailaddress=${prescriberEmail}&recipientname=${recipientName}&organizationid=${organizationId}`,
    apiConfig
  );

  const verifyPrescriberAuthorization = (prescriberAuthorization) => axios.post('/v1/verifyPrescriberAuthorization', prescriberAuthorization, apiConfig);

  return {
    getPrescriber,
    createPrescriber,
    updatePrescriber,
    deletePrescriber,
    listPrescribers,
    listNpiPrescribers,
    signBaa,
    getCurrentlyLoggedInPrescriber,
    setPrescriberAuthorization,
    verifyChallengeCode,
    updateVialCount,
    getPrescriberProductReplacement,
    registerPrescribersForOrg,
    generatePrescriberVerificationUrl,
    sendPrescriberVerificationUrl,
    verifyPrescriberAuthorization
  };
};

export default prescriberApi;
