import axios from 'axios';

const batchApi = (apiConfig) => {
  const getBatchFileStatus = () => axios.get('/v2/batch/processing/status', apiConfig);

  return {
    getBatchFileStatus
  };
};

export default batchApi;
