import React from 'react';
import PropTypes from 'prop-types';
import UnauthorizedView from '../../views/errors/UnauthorizedView';
import useFeatures from '../../hooks/useFeatures';

// enforces an active feature flag
const FeatureGuard = ({ children, feature }) => {
  const { featureEnabled } = useFeatures();

  return featureEnabled(feature) ? <>{children}</> : <UnauthorizedView />;
};

FeatureGuard.propTypes = {
  children: PropTypes.node,
  feature: PropTypes.string
};

export default FeatureGuard;
