import axios from 'axios';

const drugApi = (apiConfig) => {
  const getDrug = (drugId) => axios.get(`/v1/drug/${drugId}`, apiConfig);

  const updateDrug = (drugId, drug) => axios.put(`/v1/drug/${drugId}`, drug, apiConfig);

  const createDrug = (drug) => axios.post('/v1/drug', drug, apiConfig);

  const deleteDrug = (drugId) => axios.delete(`/v1/drug/${drugId}`, apiConfig);

  const listDrugs = (organizationId) => axios.get('/v1/drugs', {
    ...apiConfig,
    params: {
      pageSize: 3000,
      organizationId: organizationId ?? undefined
    }
  });

  const listAllDrugs = () => axios.get('/v1/drugs/all', apiConfig);

  const getFdbDrugsByName = (searchTerm) => axios.get('/v1/drug', {
    ...apiConfig,
    params: {
      name: searchTerm,
      limit: 50
    }
  });

  const getFdbDrugFormStrengthsByNdc = (ndc) => axios.get('/v1/drugformstrengths', {
    ...apiConfig,
    params: {
      ndc
    }
  });

  const getFdbDrugFormStrengthsByDrugId = (drugId) => axios.get('/v1/drugformstrengths', {
    ...apiConfig,
    params: {
      drugId
    }
  });

  const getNadacByNdc = (ndc) => axios.get(`/v1/nadac/${ndc}`, apiConfig);

  const getQuomList = () => axios.get('/v1/drug/quom', apiConfig);

  const convertRamUnits = (amount, unitFrom, unitTo) => axios.get('/v1/drug/convertRam', {
    ...apiConfig,
    params: {
      amount,
      unitFrom,
      unitTo
    }
  });

  const getRamDoseForms = () => new Promise((fulfill) => {
    setTimeout(() => {
      const doseForms = [
        {
          id: 'syringe',
          label: 'Syringe'
        },
        {
          id: 'vial',
          label: 'Vial'
        }
      ];
      const mockDoseFormsResponse = {
        status: 200,
        data: doseForms
      };
      fulfill(mockDoseFormsResponse);
    }, 500);
  });

  return {
    getDrug,
    updateDrug,
    createDrug,
    deleteDrug,
    listDrugs,
    listAllDrugs,
    getFdbDrugsByName,
    getFdbDrugFormStrengthsByNdc,
    getFdbDrugFormStrengthsByDrugId,
    getNadacByNdc,
    getQuomList,
    convertRamUnits,
    getRamDoseForms
  };
};

export default drugApi;
