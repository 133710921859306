import axios from 'axios';

const rxlSuccessResponseInterceptor = (response) => response;

const rxlErrorResponseInterceptor = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      const location = window.location;
      sessionStorage.setItem(
        'rxl-return-to',
        `${location.pathname}${location.search}`
      );
      window.location.href = `${process.env.REACT_APP_AUTH_PORTAL_URL}/login`;
    } else {
      return Promise.reject(
        new Error(`Server responded: ${error.response.status}`)
      );
    }
  }

  if (error.request) {
    return Promise.reject(new Error('No Response'));
  }

  return Promise.reject(new Error(`Server responded: ${error.message}`));
};

const rxlApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

const rxlUnauthApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

const rxlAuthApiInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_BASE_URL
});

rxlUnauthApiInstance.interceptors.response.use(
  rxlSuccessResponseInterceptor,
  rxlErrorResponseInterceptor
);

rxlApiInstance.interceptors.response.use(
  rxlSuccessResponseInterceptor,
  rxlErrorResponseInterceptor
);

rxlAuthApiInstance.interceptors.response.use(
  rxlSuccessResponseInterceptor,
  rxlErrorResponseInterceptor
);

export const rxlApiAxios = rxlApiInstance;
export const rxlAuthApiAxios = rxlAuthApiInstance;
export const rxlUnauthApiAxios = rxlUnauthApiInstance;
