import axios from 'axios';

const lddRelationshipApi = (apiConfig) => {
  const getLddRelationship = (lddId) => axios.get(`/v1/LimitedDistributionRelationship/${lddId}`, apiConfig);

  const updateLddRelationShip = (lddId, ldd) => axios.put(`v1/LimitedDistributionRelationship/${lddId}`, ldd, apiConfig);

  const createLddRelationship = (pharmacyDrugIdPair) => axios.post('/v1/LimitedDistributionRelationship', pharmacyDrugIdPair, apiConfig);

  const deleteLddRelationship = (pharmacyId, drugId) => axios.delete(`/v1/LimitedDistributionRelationship/${pharmacyId}/${drugId}`, apiConfig);

  const listAllLddRelationships = () => axios.get('/v1/LimitedDistributionRelationships', apiConfig);

  return {
    getLddRelationship,
    updateLddRelationShip,
    createLddRelationship,
    deleteLddRelationship,
    listAllLddRelationships
  };
};

export default lddRelationshipApi;
