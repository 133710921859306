import { merge } from 'lodash';
import {
  colors,
  createTheme as createMuiTheme,
  darken,
  responsiveFontSizes
} from '@mui/material';
import { alpha, lighten } from '@mui/material/styles';
import { softShadows } from './shadows';
import typography from './typography';
import {
  Azure,
  BigStone,
  WeirdPink,
  Lochmara,
  White,
  Black,
  LightGrey,
  CenterWellBlue,
  SuccessGreen,
  WarningYellow,
  CenterWellGreen, IlumyaYellow, IlumyaPurple, KorlymPink, KorlymGray, KorlymTeal
} from './brand-colors';

const RxLightningTheme = {
  name: 'RxLightning',
  primary: Azure,
  secondary: Lochmara,
  header: BigStone
};

const RxLightningDarkMode = {
  name: 'RXLIGHTNING-DARK',
  mode: 'dark',
  background: {
    default: '#121212',
    dark: '#121212',
    paper: '#121212'
  },
  primary: Azure,
  secondary: Lochmara,
  tertiary: null,
  quaternary: WeirdPink,
  header: BigStone,
  text: {
    primary: colors.blueGrey[300],
    secondary: colors.blueGrey[400],
    title: colors.blueGrey[300]
  }
};

const KorlymTheme = {
  name: 'Korlym',
  background: {
    dark: '#F3F7FB'
  },
  primary: KorlymTeal,
  secondary: KorlymGray,
  tertiary: KorlymPink,
  quaternary: '#857874',
  header: KorlymPink,
  text: {
    header: {
      primary: White,
      secondary: White
    },
    button: {
      primary: White,
      secondary: White
    }
  },
  button: {
    primary: KorlymGray,
    secondary: KorlymTeal
  }
};

const IlumyaTheme = {
  name: 'Ilumya',
  background: {
    dark: '#F3F7FB'
  },
  primary: IlumyaPurple,
  secondary: IlumyaPurple,
  tertiary: '#7D9BC1',
  quaternary: '#857874',
  header: IlumyaYellow,
  text: {
    header: {
      primary: '#857874',
      secondary: White
    }
  }
};

const CenterWellTheme = {
  name: 'Centerwell',
  background: {
    dark: '#F3F7FB'
  },
  primary: '#003057',
  primaryDark: CenterWellBlue,
  secondary: CenterWellGreen,
  tertiary: '#ffaaff',
  quaternary: '#AF0061',
  header: '#003DA5',
  text: {
    title: '#003DA5',
    button: {
      primary: '#003057'
    },
    link: '#003DA5'
  },
  feedback: {
    success: '#2A7000',
    warning: '#BE5000',
    error: '#AA0008'
  }
};

const WalgreensTheme = {
  name: 'WALGREENS',
  background: {
    dark: '#ffffff'
  },
  primary: '#c3e9ff',
  secondary: '#284b9b',
  quaternary: '#C54C6D',
  header: '#333265',
  text: {
    title: '#333265',
    primaryContrast: '#333265'
  }
};

const MerckTheme = {
  name: 'Merck',
  primary: '#179690',
  secondary: '#6ECEB2',
  header: '#179690',
  quaternary: '#e65540',
  text: {
    link: '#179690',
    header: {
      primary: White,
      secondary: White
    }
  }
};

// const RadiusTheme = {
//   name: 'Radius',
//   background: {
//     dark: '#F3F7FB'
//   },
//   primary: '#F28E36',
//   primaryDark: '#F28E36',
//   secondary: '#F28E36',
//   tertiary: null,
//   quaternary: '#F28E36',
//   header: '#00115D',
//   text: {
//     title: '#00115D',
//     button: {
//       primary: '#003057'
//     },
//     link: '#F28E36'
//   },
//   feedback: {
//     success: '#2A7000',
//     warning: '#BE5000',
//     error: '#AA0008'
//   }
// };

const buildTheme = (colorSet) => ({
  name: colorSet.name,
  typography: {
    fontFamily:
      colorSet.fontFamily ??
      ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',')
  },
  palette: {
    mode: colorSet.mode ?? 'light',
    action: {
      active: colors.blueGrey[600]
    },
    background: {
      default: colorSet.background?.default ?? White,
      dark: colorSet.background?.dark ?? '#f4f6f8',
      paper: colorSet.background?.paper ?? White
    },
    header: {
      default: colorSet.background?.default ?? White,
      dark: colorSet.header ?? BigStone,
      paper: White,
      text: {
        primary: colorSet?.text?.header?.primary ?? '#e6e5e8',
        secondary: colorSet?.text?.header?.secondary ?? '#adb0bb',
        bold: {
          fontWeight: 'bold'
        }
      }
    },
    primary: {
      main: colorSet.primary,
      dark: colorSet.primaryDark ?? darken(colorSet.primary, 0.3),
      light: lighten(colorSet.primary, 0.3)
    },
    secondary: {
      main: colorSet.secondary,
      dark: darken(colorSet.secondary, 0.3),
      light: lighten(colorSet.secondary, 0.3)
    },
    tertiary: {
      main: White,
      intermediate: LightGrey,
      contrast: Black
    },
    quaternary: {
      main: colorSet.quaternary ?? WeirdPink
    },
    text: {
      primary: colorSet.text?.primary ?? colors.blueGrey[900],
      secondary: colorSet.text?.secondary ?? colors.blueGrey[300],
      title: colorSet.text?.title ?? BigStone,
      link: colorSet.text?.link ?? Lochmara,
      button: {
        primary: colorSet.text?.button?.primary ?? White,
        secondary: colorSet.text?.button?.secondary ?? White
      }
    },
    button: {
      primary: colorSet.button?.primary ?? colorSet.primary,
      secondary: colorSet.button?.secondary ?? colorSet.secondary,
      tertiary: colorSet.button?.tertiary ?? colorSet.tertiary,
      quaternary: colorSet.button?.quaternary ?? colorSet.quaternary
    },
    feedback: {
      success: colorSet.feedback?.success ?? SuccessGreen,
      warning: colorSet.feedback?.warning ?? WarningYellow,
      failure: colorSet.feedback?.failure ?? WeirdPink
    }
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: alpha(colorSet.primary, 0.075),
            color: darken(colorSet.primary, 0.3)
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: alpha(colorSet.primary, 0.075),
            color: darken(colorSet.primary, 0.3)
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: colorSet.primary ?? Azure,
          color: colorSet.text?.primaryContrast ?? White
        }
      }
    },
    MUIDataTableSearch: {
      styleOverrides: {
        searchIcon: {
          color: colorSet.text?.primaryContrast ?? White
        },
        searchText: {
          '& div': {
            '& input': {
              backgroundColor: White
            }
          }
        },
        clearIcon: {
          color: colorSet.text?.primaryContrast ?? White,
          '&:hover': {
            color: colorSet.text?.primaryContrast ?? White,
            backgroundColor: darken(colorSet.primary, 0.075)
          }
        }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: colorSet.primary,
          color: colorSet.text?.primaryContrast ?? White
        },
        icon: {
          color: colorSet.text?.primaryContrast ?? White,
          '&:hover': {
            color: colorSet.text?.primaryContrast ?? White,
            backgroundColor: darken(colorSet.primary, 0.075)
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colorSet.text?.primary,
          '&:hover': {
            backgroundColor: alpha(colorSet.header, 0.075),
            color: colorSet.secondary
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: colorSet.text?.primary ?? colors.blueGrey[600]
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&$completed': {
            color: colorSet.secondary
          },
          '&$active': {
            color: colorSet.secondary
          }
        },
        text: {
          fill: colorSet.text?.primaryContrast ?? White,
          '&$completed': {
            fill: colorSet.text?.primaryContrast ?? White
          },
          '&$active': {
            fill: colorSet.text?.primaryContrast ?? White
          }
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&$completed': {
            color: colorSet.secondary
          },
          '&$active': {
            color: colorSet.secondary
          }
        }
      }
    }
  }
});

const baseOptions = {
  direction: 'ltr',
  typography,
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            opacity: 1
          }
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        stackedHeader: {
          fontWeight: '500'
        }
      }
    },
    MUIDataTableSearch: {
      styleOverrides: {
        main: {
          alignItems: 'center'
        },
        searchIcon: {
          marginTop: 0,
          marginRight: '.5em'
        },
        searchText: {
          '& div': {
            '& input': {
              padding: '.5em'
            }
          }
        },
        clearIcon: {
          '&:hover': {
            opacity: 1
          }
        }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          '&:hover': {
            opacity: 1
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            opacity: 1
          }
        },
        label: {
          '&:hover': {
            opacity: 1
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:hover': {
            opacity: 1
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: '30px',
          paddingRight: '30px',
          fontSize: '1rem'
        }
      }
    }
  },
  shadows: softShadows,
  responsiveFontSizes: true
};

const themesOptions = [
  buildTheme(RxLightningTheme),
  // sample themes
  buildTheme(RxLightningDarkMode),
  buildTheme(CenterWellTheme),
  buildTheme(WalgreensTheme),
  buildTheme(MerckTheme),
  buildTheme(IlumyaTheme),
  buildTheme(KorlymTheme)
  // buildTheme(RadiusTheme)
];

/* eslint-disable import/prefer-default-export */
export const createTheme = (config = {}) => {
  let themeOptions =
    themesOptions.find((theme) => theme.name === config.theme) ??
    themesOptions[0];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(merge({}, baseOptions, themeOptions));

  theme = responsiveFontSizes(theme);

  return theme;
};
