import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  AUTH_COOKIE_NAME: ''
};

const EnvironmentContext = createContext({
  ...initialState
});

export const EnvironmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (internalState, action) => {
      switch (action.type) {
        case 'INIT': {
          return action.payload;
        }
        default: {
          return internalState;
        }
      }
    },
    null,
    () => null
  );

  useEffect(() => {
    fetch(`${window.location.origin}/config.json`).then((response) => {
      // eslint-disable-next-line no-return-assign
      response.json().then((config) => dispatch({
        type: 'INIT',
        payload: config
      }));
    });
  }, []);

  return (
    <EnvironmentContext.Provider value={state}>
      {state ? children : <></>}
    </EnvironmentContext.Provider>
  );
};

EnvironmentProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default EnvironmentContext;
