import axios from 'axios';

const enrollmentApi = (apiConfig) => {
  const enrollmentEmbeds = (additionalEmbeds) => (additionalEmbeds
    ? [
      'patient',
      'prescriber',
      'drug',
      'pharmacy',
      'enrollmentOption',
      'user',
      ...additionalEmbeds
    ]
    : ['patient', 'prescriber', 'drug', 'pharmacy', 'enrollmentOption', 'user']);

  const getEnrollment = (enrollmentId) => axios.get(`/v1/enrollment/${enrollmentId}`, {
    ...apiConfig,
    params: {
      embeds: enrollmentEmbeds().join(',')
    }
  });

  const getEnrollmentPharmacies = (enrollmentId) => axios.get(`/v1/enrollment/${enrollmentId}/pharmacies`, apiConfig);

  const createEnrollment = (enrollment) => axios.post(enrollment?.formId ? `/v1/enrollment?formId=${enrollment?.formId}` : '/v1/enrollment', enrollment, apiConfig);

  const cancelEnrollment = (enrollmentId, cancelReasonCode) => axios.post(`/v1/enrollment/cancel/${enrollmentId}`, { cancelReasonCode }, apiConfig);

  const duplicateEnrollment = (enrollmentId) => axios.post('/v1/enrollment/duplicate', { enrollmentId }, apiConfig);

  const autoCompleteEnrollment = (enrollmentId) => axios.post(`/v1/enrollment/autocomplete/${enrollmentId}`, { enrollmentId }, apiConfig);

  const updateEnrollment = (enrollmentId, enrollment) => axios.put(`/v1/enrollment/${enrollmentId}`, enrollment, apiConfig);

  const deleteEnrollment = (enrollmentId) => axios.delete(`/v1/enrollment/${enrollmentId}`, apiConfig);

  const downloadEnrollment = (enrollmentId) => axios.get(`/v1/enrollmentdocument/${enrollmentId}`, {
    ...apiConfig,
    responseType: 'blob'
  });

  const faxEnrollment = (enrollmentId, faxNumber) => axios.post('/v1/enrollmentdocument', {
    enrollmentId,
    faxNumber
  }, apiConfig);

  const forceCompleteEnrollment = (enrollmentId) => axios.put(`/v1/enrollmentdocument/forcecomplete/${enrollmentId}`, {}, apiConfig);

  const listEnrollments = (
    index = 0,
    pageSize = 2000,
    organizationId,
    enrollmentType,
    additionalEmbeds
  ) => axios.get('/v1/enrollments', {
    ...apiConfig,
    params: {
      index,
      pageSize,
      embeds: enrollmentEmbeds(additionalEmbeds).join(','),
      hasCase: false,
      organizationId,
      enrollmentType
    }
  });

  const listEnrollmentsByPatient = (patientId) => axios.get('/v1/enrollments', {
    ...apiConfig,
    params: {
      pageSize: 3000,
      patientId,
      embeds: enrollmentEmbeds().join(',')
    }
  });

  const listEnrollmentsByPrescriber = (prescriberId) => axios.get('/v1/enrollments', {
    ...apiConfig,
    params: {
      pageSize: 3000,
      prescriberId,
      embeds: enrollmentEmbeds().join(',')
    }
  });

  const listRefillNotifications = (organizationId) => axios.get('/v1/notifications', {
    ...apiConfig,
    params: {
      organizationId
    }
  });

  const dismissRefillNotification = (notificationId) => axios.delete(`/v1/notifications/${notificationId}`, apiConfig);

  const listTestEnrollments = (formId) => axios.get(`/v1/testenrollment/complete/${formId}`, apiConfig);

  const createTestEnrollment = (formId, enrollment) => axios.post(`/v1/testenrollment/${formId}`, enrollment, apiConfig);

  const archiveTestEnrollment = (testEnrollmentId) => axios.delete(`/v1/testenrollment/${testEnrollmentId}`, apiConfig);

  const getEnrollmentPrescriptions = (enrollmentId) => axios.get(`/v1/prescription/${enrollmentId}`, apiConfig);

  const validateEnrollment = (enrollmentId, enrollmentData) => axios.put(`/v1/enrollment/validate/${enrollmentId}`, enrollmentData, apiConfig);

  const autoCompleteTestEnrollment = (formId) => axios.get(`/v1/testenrollment/autocomplete/${formId}`, apiConfig);

  return {
    getEnrollment,
    getEnrollmentPharmacies,
    createEnrollment,
    cancelEnrollment,
    duplicateEnrollment,
    autoCompleteEnrollment,
    updateEnrollment,
    deleteEnrollment,
    downloadEnrollment,
    faxEnrollment,
    forceCompleteEnrollment,
    listEnrollments,
    listEnrollmentsByPatient,
    listEnrollmentsByPrescriber,
    listRefillNotifications,
    dismissRefillNotification,
    listTestEnrollments,
    createTestEnrollment,
    archiveTestEnrollment,
    getEnrollmentPrescriptions,
    validateEnrollment,
    autoCompleteTestEnrollment
  };
};

export default enrollmentApi;
