import React from 'react';
import { Box, LinearProgress, useTheme } from '@mui/material';

const LoadingScreen = () => {
  const theme = useTheme();

  return (
    <Box sx={{
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      minHeight: '100%',
      padding: theme.spacing(3)
    }}
    >
      <Box width={400}>
        <LinearProgress />
      </Box>
    </Box>
  );
};

export default LoadingScreen;
