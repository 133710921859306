import { toLower } from 'lodash';
import {
  BelsomraDrugId,
  DelstrigoDrugId,
  DificidDrugId,
  EmendDrugId,
  IlumyaDrugId, IsentressDrugId, JanumetDrugId, JanuviaDrugId,
  KorlymDrugId, NoxafilDrugId, PifeltroDrugId, StromectolDrugId, ZepatierDrugId, ZolinzaDrugId
} from '../constants/drugs';
import { ILUMYA } from '../constants/forms';
import {
  Access4MeHubPharmacyId,
  CenterwellPharmacyId, CvsPharmacyId,
  FountainRxPharmacyId, MerckPapPharmacyId, Onco360PharmacyId, OptimePharmacyId,
  PatientServicesPharmacyId, WalgreensPharmacyId
} from '../constants/pharmacies';

export const Access4MeReferral = 'Access4Me';
export const AdOrInternetReferral = 'Ad/Internet Search';
export const CenterwellReferral = 'Centerwell Specialty Pharmacy';
export const FountainRxReferral = 'FountainRx';
export const IlumyaReferral = 'Ilumya';
export const KorlymReferral = 'Korlym';
export const CurrentUserReferral = 'Current User';
export const EverwellReferral = 'Everwell Specialty Pharmacy';
export const MerckReferral = 'Merck Patient Assistance Program';
export const Onco360Referral = 'Onco360 Specialty Pharmacy';
export const OtherReferral = 'Other';
export const RadiusReferral = 'Radius';
export const RxLightningReferral = 'RxLightning';
export const WalgreensReferral = 'Walgreens';
export const YaleReferral = 'Yale';

const configs = [
  {
    activeSsoClient: false,
    displayName: 'Access4Me',
    domain: 'access4meportal.com',
    faviconSrc: 'https://cdn.rxlightning.com/client/icons/access4me-icon.svg',
    homeUrl: 'https://www.access4me.com/',
    icon: 'https://cdn.rxlightning.com/client/icons/access4me-icon.svg',
    logo: 'https://cdn.rxlightning.com/client/logos/access4me_text_logo.svg',
    loginProdRedirect: 'https://portal.access4meportal.com',
    name: 'Access4Me',
    promotedPharmacyId: Access4MeHubPharmacyId,
    referralCode: '6bfefe51-a9cc-42f7-a7a7-8bc6ca31888a',
    referralTypes: [Access4MeReferral]
  },
  {
    activeSsoClient: false,
    displayName: 'Centerwell',
    domain: 'rxlightning.com',
    faviconSrc: 'https://cdn.rxlightning.com/client/icons/centerwell-icon.svg',
    homeUrl: 'https://www.centerwell.com/',
    icon: 'https://cdn.rxlightning.com/client/icons/centerwell-icon.svg',
    logo: 'https://cdn.rxlightning.com/client/logos/centerwell_text_logo.svg',
    name: 'Centerwell',
    promotedPharmacyId: CenterwellPharmacyId,
    referralCode: '3a9e754e-43ce-4e9a-90fd-a714dc627346',
    referralTypes: [CenterwellReferral]
  },
  {
    activeSsoClient: false,
    displayName: 'FountainRx',
    domain: 'rxlightning.com',
    homeUrl: 'https://www.rxlightning.com',
    icon: 'https://cdn.rxlightning.com/images/logo.svg',
    logo: 'https://cdn.rxlightning.com/images/text_logo.svg',
    name: 'FountainRx',
    promotedPharmacyId: FountainRxPharmacyId,
    referralCode: 'cac15d12-9e68-4ff6-b90d-ed136130b1b1',
    referralTypes: [FountainRxReferral]
  },
  {
    activeSsoClient: false,
    displayName: 'Ilumya',
    domain: 'rxlightning.com',
    drugs: [
      {
        name: 'ilumya',
        drugId: IlumyaDrugId
      }
    ],
    faviconSrc: 'https://cdn.rxlightning.com/client/icons/ilumya-icon.svg',
    homeUrl: 'https://www.ilumya.com',
    icon: 'https://cdn.rxlightning.com/client/icons/ilumya-icon.svg',
    loginDemoRedirect: 'https://ilumya-demo.rxlightning.com',
    loginDevRedirect: 'https://ilumya-dev.rxlightning.com',
    loginProdRedirect: 'https://ilumya.rxlightning.com',
    loginQaRedirect: 'https://ilumya-qa.rxlightning.com',
    loginStagingRedirect: 'https://ilumya-staging.rxlightning.com',
    logo: 'https://cdn.rxlightning.com/client/logos/ilumya_text_logo.svg',
    name: 'Ilumya',
    promotedPharmacyId: PatientServicesPharmacyId,
    promotedFormId: ILUMYA,
    referralCode: 'c84fda3b-d4ad-4c62-bde5-89c4d1bb74da',
    referralDrug: IlumyaDrugId,
    referralTypes: [IlumyaReferral]
  },
  {
    activeSsoClient: false,
    displayName: 'Korlym',
    domain: 'rxlightning.com',
    homeUrl: 'https://korlym.rxlightning.com',
    icon: 'https://cdn.rxlightning.com/images/logo.svg',
    loginDemoRedirect: 'https://korlym-demo.rxlightning.com',
    loginDevRedirect: 'https://korlym-dev.rxlightning.com',
    loginProdRedirect: 'https://korlym.rxlightning.com',
    loginQaRedirect: 'https://korlym-qa.rxlightning.com',
    loginStagingRedirect: 'https://korlym-staging.rxlightning.com',
    logo: 'https://cdn.rxlightning.com/client/logos/Korlym_text_logo.svg',
    name: 'Korlym',
    promotedPharmacyId: OptimePharmacyId,
    referralCode: '57a1d6d1-0d4d-4bcb-9201-6e27107b8918',
    referralDrug: KorlymDrugId,
    referralTypes: [KorlymReferral],
    secondaryWelcomeHeaderText: 'KOR-00937 MAR 2024'
  },
  {
    activeSsoClient: false,
    displayName: 'Merck Patient Assistance Program',
    domain: 'rxlightning.com',
    drugs: [
      {
        name: 'belsomra',
        drugId: BelsomraDrugId
      },
      {
        name: 'delstrigo',
        drugId: DelstrigoDrugId
      },
      {
        name: 'dificid',
        drugId: DificidDrugId
      },
      {
        name: 'emend',
        drugId: EmendDrugId
      },
      {
        name: 'isentress',
        drugId: IsentressDrugId
      },
      {
        name: 'janumet',
        drugId: JanumetDrugId
      },
      {
        name: 'januvia',
        drugId: JanuviaDrugId
      },
      {
        name: 'noxafil',
        drugId: NoxafilDrugId
      },
      {
        name: 'pifeltro',
        drugId: PifeltroDrugId
      },
      {
        name: 'stromectol',
        drugId: StromectolDrugId
      },
      {
        name: 'zepatier',
        drugId: ZepatierDrugId
      },
      {
        name: 'zolinza',
        drugId: ZolinzaDrugId
      }
    ],
    homeUrl: 'https://www.merckhelps.com',
    icon: 'https://cdn.rxlightning.com/images/logo.svg',
    logo: 'https://cdn.rxlightning.com/client/logos/merk_text_logo2.svg',
    name: 'Merck',
    promotedPharmacyId: MerckPapPharmacyId,
    referralCode: 'aae4cffa-b05a-4a01-a9c6-3e573d2377a8',
    referralTypes: [MerckReferral]
  },
  {
    activeSsoClient: false,
    displayName: 'Onco360',
    domain: 'rxlightning.com',
    faviconSrc: 'https://cdn.rxlightning.com/client/icons/onco360-icon.svg',
    homeUrl: 'https://www.onco360.com',
    icon: 'https://cdn.rxlightning.com/client/icons/onco360-icon.svg',
    logo: 'https://cdn.rxlightning.com/client/logos/onco360_text_logo.svg',
    name: 'Onco360',
    promotedPharmacyId: Onco360PharmacyId,
    referralCode: 'd0288ba5-5012-492c-834e-1f701c0a9bd9',
    referralTypes: [Onco360Referral]
  },
  {
    activeSsoClient: false,
    displayName: 'Radius',
    domain: 'rxlightning.com',
    faviconSrc: 'https://cdn.rxlightning.com/client/icons/tymlos-icon.svg',
    homeUrl: 'https://radiuspharm.com',
    icon: 'https://cdn.rxlightning.com/client/icons/tymlos-icon.svg',
    logo: 'https://cdn.rxlightning.com/client/logos/radius_text_logo.svg',
    name: 'Radius',
    promotedPharmacyId: CvsPharmacyId,
    referralCode: 'f168b125-7028-441b-9788-d9392a5e104d',
    referralTypes: [RadiusReferral]
  },
  {
    activeSsoClient: false,
    displayName: 'RxLightning',
    domain: 'rxlightning.com',
    homeUrl: 'https://www.rxlightning.com',
    icon: 'https://cdn.rxlightning.com/images/logo.svg',
    logo: 'https://cdn.rxlightning.com/images/text_logo.svg',
    name: 'RxLightning',
    referralCode: 'e31bd9a7-97cd-41e2-803f-273dd1688d48',
    referralTypes: [
      Access4MeReferral,
      AdOrInternetReferral,
      CenterwellReferral,
      FountainRxReferral,
      IlumyaReferral,
      CurrentUserReferral,
      FountainRxReferral,
      EverwellReferral,
      MerckReferral,
      Onco360Referral,
      OtherReferral,
      RadiusReferral,
      RxLightningReferral,
      WalgreensReferral,
      YaleReferral
    ]
  },
  {
    activeSsoClient: false,
    displayName: 'Walgreens',
    domain: 'rxlightning.com',
    faviconSrc: 'https://cdn.rxlightning.com/client/icons/walgreens-icon.svg',
    homeUrl: 'https://www.walgreens.com/',
    icon: 'https://cdn.rxlightning.com/client/icons/walgreens-icon.svg',
    logo: 'https://cdn.rxlightning.com/client/logos/walgreens_text_logo.svg',
    name: 'Walgreens',
    promotedPharmacyId: WalgreensPharmacyId,
    referralCode: 'ded0e4ec-e9e7-4808-a186-9711dcd57dc5',
    referralTypes: [WalgreensReferral]
  },
  {
    activeSsoClient: true,
    displayName: 'Yale',
    domain: 'rxlightning.com',
    homeUrl: 'https://yale.com',
    icon: 'https://cdn.rxlightning.com/images/logo.svg',
    logo: 'https://cdn.rxlightning.com/client/logos/yale_text_logo.svg',
    coBrandingLogo: 'https://cdn.rxlightning.com/images/text_logo.svg',
    coBrandingName: 'RxLightning',
    coBrandingDisplayName: 'RxLightning',
    name: 'Yale',
    referralCode: '21dbe332-1ea8-49ac-8d90-cb0dd802f6cb',
    referralTypes: [YaleReferral]
  }
];

export const byNameIncluded = (value) => configs.find((brand) => toLower(value)?.includes(toLower(brand.name)));
export const byReferralCode = (code) => configs.find((brand) => toLower(brand.referralCode) === toLower(code));
export const byDrugIncluded = (value) => configs.find((brand) => brand.drugs?.some((d) => toLower(value).includes(toLower(d.name))));
