export const convertRamAmountToMillicurie = (ramAmount, convertFromUnit) => {
  if (!ramAmount || !convertFromUnit) {
    return null;
  }

  // todo: extract constants to file
  switch (convertFromUnit) {
    case 'Bq':
      return ramAmount * 2.702702702e-8;
    case 'TBq':
      return ramAmount * 27027.027027;
    case 'GBq':
      return ramAmount * 27.027027027;
    case 'MBq':
      return ramAmount * 0.027027027;
    case 'kBq':
      return ramAmount * 0.000027027;
    case 'mBq':
      return ramAmount * 2.702702702e-11;
    case 'Ci':
      return ramAmount * 1000;
    case 'kCi':
      return ramAmount * 1000000;
    case 'mCi':
      return ramAmount;
    case 'µCi':
      return ramAmount * 0.001;
    case 'nCi':
      return ramAmount * 0.000001;
    case 'pCi':
      return ramAmount * 1e-9;
    case 'Rd':
      return ramAmount * 0.027027027;
    case '1/s':
      return ramAmount * 2.702702702e-8;
    case '1/m':
      return ramAmount * 4.504504504e-10;
    case 'pBq':
      return ramAmount * 27027027.02703;
    default:
      return 0;
  }
};

export const findLicenseDifferences = (obj1, obj2) => {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach((key) => {
    if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
      result[key] = obj2[key];
    }
    if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
      const value = findLicenseDifferences(obj1[key], obj2[key]);
      if (value !== undefined) {
        result[key] = value;
      }
    }
  });
  return result;
};
