import React from 'react';
import {
  Box, Button, Container, Typography
} from '@mui/material';

export default () => (
    <Container>
      <Box textAlign="center" sx={{ mb: 20, mt: 20 }}>
        <Typography variant="h1">401 Unauthorized</Typography>
        <Typography variant="body1">Sorry, your request could not be processed</Typography>
        <Button variant="outlined" color="secondary" onClick={() => window.location.reload()}>Try Again!</Button>
      </Box>
    </Container>
);
