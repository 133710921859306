import axios from 'axios';

const contactApi = (apiConfig) => {
  const createContact = (contact) => axios.post('/v1/contact', contact, apiConfig);

  const updateContact = (contactId, contact) => axios.put(`/v1/contact/${contactId}`, contact, apiConfig);

  const getContact = (contactId) => axios.get(`v1/contact/${contactId}`, apiConfig);

  const deleteContact = (contactId) => axios.delete(`/v1/contact/${contactId}`, apiConfig);

  const listContactsByOrganizationId = (organizationId, useCase) => axios.get('/v1/contacts', {
    ...apiConfig,
    params: {
      organizationId,
      embeds: 'patient,prescriber',
      useCase
    }
  });

  const listContactsByParentOrganizationId = (parentOrganizationId) => axios.get('/v1/contacts', {
    ...apiConfig,
    params: {
      parentOrganizationId,
      embeds: 'patient,prescriber'
    }
  });

  const listRecipientContactsByOrganizationId = (organizationId) => axios.get(`/v1/contacts/recipients/${organizationId}`, {
    ...apiConfig,
    params: {
      embeds: 'patient,prescriber'
    }
  });

  return {
    createContact,
    updateContact,
    getContact,
    deleteContact,
    listContactsByOrganizationId,
    listContactsByParentOrganizationId,
    listRecipientContactsByOrganizationId
  };
};

export default contactApi;
