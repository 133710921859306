import axios from 'axios';

const rptApi = (apiConfig) => {
  const listAllManufacturers = () => axios.get('/v1/licenses/manufacturers', apiConfig);

  const getManufacturerByServiceId = (serviceId) => axios.get(`/v1/licenses/services/${serviceId}`, apiConfig);

  const getManufacturerByOrganizationId = (organizationId) => axios.get(`/v1/licenses/manufacturers/${organizationId}`, apiConfig);

  const getOrganizationServices = (orgId) => axios.get(`/v1/Organization/${orgId}/services`, apiConfig);

  const getAllOrganizationServices = () => axios.get('/v1/organization/services', apiConfig);

  return {
    listAllManufacturers,
    getManufacturerByServiceId,
    getManufacturerByOrganizationId,
    getOrganizationServices,
    getAllOrganizationServices
  };
};

export default rptApi;
