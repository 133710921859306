import axios from 'axios';

const messageApi = (apiConfig) => {
  const getMessage = (messageId) => axios.get(`/v1/message/${messageId}`, apiConfig);

  const listInboxMessages = (organizationId, toContactId) => axios.get('/v1/messages', {
    ...apiConfig,
    params: {
      organizationId,
      toContactId,
      embeds: 'contact'
    }
  });

  const listOutboxMessages = (organizationId, fromContactId) => axios.get('/v1/messages', {
    ...apiConfig,
    params: {
      organizationId,
      fromContactId,
      embeds: 'contact'
    }
  });

  const sendMessage = (message) => axios.post('/v1/message', message, apiConfig);

  const archiveMessage = (messageId) => axios.delete(`/v1/message/${messageId}`, apiConfig);

  return {
    getMessage,
    listInboxMessages,
    listOutboxMessages,
    sendMessage,
    archiveMessage
  };
};

export default messageApi;
