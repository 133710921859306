import axios from 'axios';
import { now } from '../utils/date-utility';
import { mockApiCall } from '../utils/api-utility';

const patientApi = (apiConfig) => {
  const caseEmbeds = [
    'patient',
    'prescriber',
    'drug',
    'pharmacy',
    'user',
    'enrollment'
  ];

  const getPatient = (patientId) => axios.get(`/v1/patient/${patientId}`, apiConfig);

  const getPatientCases = (patientId) => axios.get(`/v1/patient/${patientId}/cases`, apiConfig);

  const getPatientTraining = (patientId) => axios.get(`/v1/patient/${patientId}/training`, apiConfig);

  const registerPatientTraining = (patientId, trainingId) => axios.put(`/v1/patient/${patientId}/training/${trainingId}`, {
    patientId,
    trainingId,
    registered: now()
  }, apiConfig);

  const getCurrentlyLoggedInPatient = () => axios.get('/v1/patientInfo', apiConfig);

  const createPatient = (patient) => axios.post('/v1/patient', patient, apiConfig);

  const getPatientByChallengeCode = (challengeCode) => axios.get(`/v1/patient/registration/${challengeCode}`, apiConfig);

  const registerPatientAccount = (challengeCode, newUser) => axios.put(`/v1/patient/registration/${challengeCode}`, newUser, apiConfig);

  const initiatePatientAccountRegistration = (enrollmentId) => axios.post(`/v1/enrollment/${enrollmentId}/patientregistration`, {}, apiConfig);

  const updatePatient = (patientId, patient) => axios.put(`/v1/patient/${patientId}`, patient, apiConfig);

  const deletePatient = (patientId) => axios.delete(`/v1/patient/${patientId}`, apiConfig);

  const listPatients = (organizationId, index = 0, pageSize = 2000) => axios.get('/v1/patients', {
    ...apiConfig,
    params: {
      pageSize,
      index,
      organizationId
    }
  });

  const verifyAccessCode = (accessCode) => axios.get(`/v1/patientAuthorization/verify/${accessCode}`, apiConfig);

  const patientVerification = (verificationData) => axios.post('/v1/patientAuthorization', verificationData, apiConfig);

  const resendPatientVerification = (contactData) => axios.post('/v1/patientAuthorization/resendCode', contactData, apiConfig);

  const updatePatientAuthData = (answers) => axios.put('/v1/patientAuthorization', answers, apiConfig);

  const listPatientInsurance = (patientId) => axios.get(`/v1/patient/${patientId}/insurance`, apiConfig);

  const listPatientRtbcHistory = (patientId) => axios.get(`/v1/BenefitCheck/RTBC/${patientId}`, apiConfig);

  const listPatientCases = (patientId) => axios.get(`/v1/patient/${patientId}/cases?embeds=${caseEmbeds.join(',')}`, apiConfig);

  const listPatientNotes = (patientId) => axios.get(`/v1/patient/${patientId}/notes`, apiConfig);

  const createPatientNote = (patientId, payload) => axios.post(`/v1/patient/${patientId}/notes`, payload, apiConfig);

  const listPatientProcurements = (patientId) => axios.get(`/v1/patient/${patientId}/procurements`, apiConfig);

  const listPatientWaitListForOrganization = (organizationId) => axios.get(`/v1/WaitLists/Entry/Organization/${organizationId}?isActive=true`, apiConfig);

  const addPatientToWaitList = (waitListEntry) => axios.post('/v1/WaitLists/Entry', waitListEntry, apiConfig);

  const removePatientFromWaitList = (waitListEntry) => axios.delete(
    `/v1/WaitLists/Entry/Organization/${waitListEntry?.organizationId}/Patient/${waitListEntry?.patientId}`,
    apiConfig
  );

  const removeWaitListItem = (waitListId) => axios.delete(`/v1/WaitLists/Entry/${waitListId}`, apiConfig);

  const testRequestPatientSmsVerificationCode = (
    lastName,
    dateOfBirth,
    code
  ) => {
    const isSuccess = true;
    if (isSuccess) {
      return new Promise((fulfill) => {
        setTimeout(() => {
          const mockPatientVerification = {
            status: 200,
            data: {
              lastName,
              dateOfBirth,
              code
            }
          };
          fulfill(mockPatientVerification);
        }, 1000);
      });
    }
    return new Promise((fulfill) => {
      setTimeout(() => {
        const mockPatientVerification = {
          status: 500
        };
        fulfill(mockPatientVerification);
      }, 1000);
    });
  };

  const testSubmitSmsVerification = (lastName, dateOfBirth, smsCode) => {
    const isSuccess = true;
    if (isSuccess) {
      return new Promise((fulfill) => {
        setTimeout(() => {
          const mockSmsVerification = {
            status: 200,
            data: {
              lastName,
              dateOfBirth,
              smsCode,
              attachment: {
                attachmentId: '5745215B-E3FA-4A4D-B544-7B42A3B65472',
                attachmentName: 'nologin_christina_aguilera_signature.png'
              }
            }
          };
          fulfill(mockSmsVerification);
        }, 1000);
      });
    }
    return new Promise((fulfill) => {
      setTimeout(() => {
        const mockPatientVerification = {
          status: 500,
          data: [
            {
              error: 'err'
            }
          ]
        };
        fulfill(mockPatientVerification);
      }, 1000);
    });
  };

  const creditCheck = () => {
    const theData = {
      householdSize: 2,
      houseHoldSizeDate: '2024-01-31T00:00:00.000',
      annualIncome: '30000',
      annualIncomeDate: '2024-01-31T00:00:00.000',
      fpl: 200,
      fplDate: '2024-01-31T00:00:00.000'
    };

    return mockApiCall(theData, 300, '');
  };

  const listCopayHistory = (patientId) => {
    const data = [
      {
        recordId: '123',
        drugName: 'Drugerone',
        programName: 'Program A',
        copayAmount: 1234,
        phone: '5025555555',
        website: 'www.website.com',
        bin: 'binny bin',
        pcn: 9000,
        monthlyMax: 90001,
        yearlyMax: null,
        patientOutreachRequired: false,
        eligibilityCriteria: [
          'criteria 1',
          'criteria 2',
          'criteria 3'
        ],
        hubRequired: true,
        papAvailable: false,
        created: '2022-11-30T18:25:04.3933333'
      },
      {
        recordId: '234',
        drugName: 'Drugerol',
        programName: 'Program B',
        copayAmount: 2435,
        phone: '5025555555',
        website: 'www.website.com',
        bin: 'this is a bin',
        pcn: 5000,
        monthlyMax: null,
        yearlyMax: null,
        patientOutreachRequired: false,
        eligibilityCriteria: [
          'criteria 1',
          'criteria 2',
          'criteria 3'
        ],
        hubRequired: false,
        papAvailable: true,
        created: '2022-11-30T18:25:04.3933333'
      },
      {
        recordId: '345',
        drugName: 'Drugavere',
        programName: 'Program C',
        copayAmount: 4567,
        phone: '5025555555',
        website: 'www.website.com',
        bin: 'this is a bin',
        pcn: 5000,
        monthlyMax: null,
        yearlyMax: null,
        patientOutreachRequired: false,
        eligibilityCriteria: [
          'criteria 1',
          'criteria 2',
          'criteria 3'
        ],
        hubRequired: false,
        papAvailable: true,
        created: '2022-11-30T18:25:04.3933333'
      }
    ];

    return mockApiCall(data, 500, patientId);
  };

  const deleteCopayRecord = (copayRecordId) => {
    const data = {};

    return mockApiCall(data, 500, copayRecordId);
  };

  return {
    getPatient,
    getPatientCases,
    getPatientTraining,
    registerPatientTraining,
    getCurrentlyLoggedInPatient,
    createPatient,
    getPatientByChallengeCode,
    registerPatientAccount,
    initiatePatientAccountRegistration,
    updatePatient,
    deletePatient,
    listPatients,
    verifyAccessCode,
    patientVerification,
    resendPatientVerification,
    updatePatientAuthData,
    listPatientInsurance,
    listPatientRtbcHistory,
    listPatientCases,
    listPatientNotes,
    createPatientNote,
    listPatientProcurements,
    listPatientWaitListForOrganization,
    addPatientToWaitList,
    removePatientFromWaitList,
    removeWaitListItem,
    testRequestPatientSmsVerificationCode,
    testSubmitSmsVerification,
    creditCheck,
    listCopayHistory,
    deleteCopayRecord
  };
};

export default patientApi;
