import axios from 'axios';

const pharmacyApi = (apiConfig) => {
  const getDestination = (pharmacyId) => axios.get(`/v1/pharmacy/${pharmacyId}`, apiConfig);

  const listAllPharmacies = () => axios.get('/v1/pharmacies/all', apiConfig);

  const createDestination = (destination) => axios.post('/v1/pharmacy', destination, apiConfig);

  const updateDestination = (pharmacyId, destination) => axios.put(`/v1/pharmacy/${pharmacyId}`, destination, apiConfig);

  const searchPharmacies = (drugId) => axios.get('/v1/pharmacies', {
    ...apiConfig,
    params: {
      drugId,
      pageSize: 3000
    }
  });

  const listDataQPharmacies = (criteria) => axios.post('v1/pharmacies/find', {
    partialName: criteria.name || undefined,
    partialAddressLine1: criteria.address || undefined,
    postalCode: criteria.postalCode || undefined,
    phoneNumber: criteria.phone || undefined
  }, apiConfig);

  return {
    getDestination,
    listAllPharmacies,
    createDestination,
    updateDestination,
    searchPharmacies,
    listDataQPharmacies
  };
};

export default pharmacyApi;
