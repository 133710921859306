import axios from 'axios';

const organizationApi = (securityApiConfig) => {
  const getOrganization = (orgId) => axios.get(`/v1/organization/${orgId}`, securityApiConfig);

  const getParentOrganization = (parentOrgId) => axios.get(`/v1/parentorganization/${parentOrgId}`, securityApiConfig);

  const createOrganization = (parentOrgId, organization) => axios.post(`/v1/parentorganization/${parentOrgId}/organizations`, {
    organizations: [organization]
  }, securityApiConfig);

  const updateOrganization = (organizationId, organization) => axios.put(`/v1/organization/${organizationId}`, organization, securityApiConfig);

  const archiveOrganization = (organizationId) => axios.delete(`/v1/organization/${organizationId}`, organizationId, securityApiConfig);

  const archiveParentOrganization = (parentOrganizationId) => axios.delete(
    `/v1/parentOrganization/${parentOrganizationId}`,
    parentOrganizationId,
    securityApiConfig
  );

  const mergeOrganizations = (orgId, orgsToMerge) => axios.put(`/v1/organization/${orgId}/merge`, orgsToMerge, securityApiConfig);

  const getOrgAdmins = (orgId) => axios.get(`/v1/Organization/${orgId}/admins`, securityApiConfig);

  const addAdminToOrg = (orgId, userId) => axios.post(
    `/v1/organization/${orgId}/${userId}`,
    {},
    {
      ...securityApiConfig,
      params: {
        isAdmin: true
      }
    }
  );

  const addUsersToOrg = (orgId, request) => axios.post(`/v1/organization/${orgId}/users`, request, securityApiConfig);

  const addPrescribersToOrg = (orgId, request) => axios.post(`/v1/organization/${orgId}/prescribers`, request, securityApiConfig);

  const updateUserOrgs = (userId, orgIds) => axios.put(`/v1/user/${userId}/organizations`, orgIds, securityApiConfig);

  const removeAdminFromOrg = (orgId, userId) => axios.delete(`/v1/organization/${orgId}/${userId}`, {
    ...securityApiConfig,
    params: {
      isAdmin: true
    }
  });

  const approvePendingParentOrg = (payload, orgId) => axios.put(`/v1/pendingRegistration/${orgId}`, payload, securityApiConfig);

  const denyPendingParentOrg = (payload, orgId) => axios.put(`/v1/pendingRegistration/${orgId}/deny`, payload, securityApiConfig);

  return {
    getOrganization,
    getParentOrganization,
    createOrganization,
    updateOrganization,
    archiveOrganization,
    archiveParentOrganization,
    mergeOrganizations,
    getOrgAdmins,
    addAdminToOrg,
    addUsersToOrg,
    addPrescribersToOrg,
    updateUserOrgs,
    removeAdminFromOrg,
    approvePendingParentOrg,
    denyPendingParentOrg
  };
};

export default organizationApi;
