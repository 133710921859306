import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import useAuth from '../../../hooks/useAuth';

const GroupSelector = () => {
  const {
    organizations,
    selectedOrganization,
    updateSelectedOrganization
  } = useAuth();

  const organizationsOptions = organizations.map((org) => ({
    id: org.organizationId,
    label: org.organizationName
  }));

  return (
    <Autocomplete
      color="secondary"
      id="orgSelection"
      disableClearable
      autoHighlight
      onChange={(event, newOrg) => {
        updateSelectedOrganization(
          organizations.find((org) => newOrg.id === org.organizationId)
        );
      }}
      size="small"
      sx={{ width: '250px' }}
      value={{
        id: selectedOrganization?.organizationId ?? '',
        label: selectedOrganization?.organizationName ?? ''
      }}
      options={organizationsOptions}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label="Select Group" />}
    />
  );
};

export default GroupSelector;
