import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material';
import useBranding from '../hooks/useBranding';
import { createTheme } from './index';

const PortalThemeProvider = ({ children }) => {
  const branding = useBranding();

  return (
    <MaterialThemeProvider theme={createTheme({ theme: branding.name })}>
      {children}
    </MaterialThemeProvider>
  );
};

PortalThemeProvider.propTypes = {
  children: PropTypes.node
};

export default PortalThemeProvider;
