import axios from 'axios';

const securityApi = (securityApiConfig) => {
  const login = (username, password) => axios.post('/v1/login', {
    username,
    password
  }, securityApiConfig);

  const getUser = () => axios.get('/v1/user', securityApiConfig);

  const getUserById = (userId) => axios.get(`/v1/user/${userId}`, securityApiConfig);

  const getUserByEmail = (email) => axios.get(`/v1/user/ByEmail/${email}`, securityApiConfig);

  const listPendingApprovals = () => axios.get('/v1/pendingregistration', securityApiConfig);

  const updateUserInfo = (userId, newUserData) => axios.put(`/v1/user/${userId}`, newUserData, securityApiConfig);

  const createUser = (user) => axios.post('/v1/user', user, securityApiConfig);

  const batchCreateUsers = (users) => axios.post('/v1/users', users, securityApiConfig);

  const listParentOrgs = () => axios.get('/v1/ParentOrganizations', securityApiConfig);

  const registerParentOrg = (parentOrganizationRequest) => axios.post('/v1/parentOrganization', parentOrganizationRequest, securityApiConfig);

  const getParentOrg = (parentOrganizationId) => axios.get(`/v1/parentorganization/${parentOrganizationId}`, securityApiConfig);

  // general use
  const listRoles = () => axios.get('/v1/roles', securityApiConfig);

  // rxl admin use
  const listAllRoles = () => axios.get('/v1/roles/all', securityApiConfig);

  const listRptRoles = () => axios.get('/v1/roles/rpt', securityApiConfig);

  const updateUserArchive = (userId, archive) => axios.put(`/v1/user/${userId}/archive?archive=${archive}`, {}, securityApiConfig);

  const updateUserBlock = (userId) => axios.post(`/v1/user/${userId}/block`, {}, securityApiConfig);

  const assignRolesToUser = (roles, userEmail) => axios.put(`/v1/user/${userEmail}/roles`, roles, securityApiConfig);

  // auth0 pass-through
  const createRole = (role) => axios.post('/v1/role', role, securityApiConfig);

  const deleteRole = (roleId) => axios.delete(`/v1/role/${roleId}`, securityApiConfig);

  const listAuth0Roles = () => axios.get('/v1/roles/auth0', securityApiConfig);

  const listAuth0Users = () => axios.get('/v1/users/auth0', securityApiConfig);

  const updateRole = (roleId, role) => axios.put(`/v1/role/${roleId}`, role, securityApiConfig);

  const getPermissionsByRole = (roleId) => axios.get(`/v1/role/${roleId}/permissions`, securityApiConfig);

  const removePermissionsFromRole = (roleId, permissions) => axios.put(`/v1/role/${roleId}/permissions`, permissions, securityApiConfig);

  const associatePermissionsWithRole = (roleId, permissions) => axios.post(`/v1/role/${roleId}/permissions`, permissions, securityApiConfig);

  const assignUsersToRole = (roleId, users) => axios.post(`/v1/role/${roleId}/users`, users, securityApiConfig);

  const getARolesUsers = (roleId) => axios.get(`/v1/role/${roleId}/users`, securityApiConfig);

  const removeRoleFromUser = (roleId, auth0UserId) => axios.put(`/v1/user/${auth0UserId}/roles/${roleId}`, {}, securityApiConfig);

  const getAuth0ResourceServers = () => axios.get('/v1/resourceServers', securityApiConfig);

  const updateResourceServer = (
    resourceServerId,
    resourceServerUpdateRequest
  ) => axios.patch(
    `/v1/resourceServers/${resourceServerId}`,
    resourceServerUpdateRequest,
    securityApiConfig
  );

  const getUserRoles = (auth0UserId) => axios.get(`/v1/user/${auth0UserId}/roles`, securityApiConfig);

  const getUserPermissions = (auth0UserId) => axios.get(`/v1/user/${auth0UserId}/roles`, securityApiConfig);

  const getAuth0UserDetails = (email) => axios.get(`/v1/user/${email}/auth0Details`, securityApiConfig);

  const removeRolesFromUser = (rolesRequest, auth0UserId) => axios.put(`/v1/user/${auth0UserId}/roles/remove`, rolesRequest, securityApiConfig);

  const listUsersForParentOrganization = (parentOrganizationId) => axios.get(`/v1/users?parentOrganizationId=${parentOrganizationId}`, securityApiConfig);

  const associateUserToOrganization = (organizationId, userId) => axios.post(`/v1/organization/${organizationId}/users`, { organizationId, userIds: [userId] }, securityApiConfig);

  return {
    login,
    getUser,
    getUserById,
    getUserByEmail,
    listPendingApprovals,
    updateUserInfo,
    createUser,
    batchCreateUsers,
    listParentOrgs,
    registerParentOrg,
    getParentOrg,
    listRoles,
    listAllRoles,
    listRptRoles,
    updateUserArchive,
    updateUserBlock,
    assignRolesToUser,
    createRole,
    deleteRole,
    listAuth0Roles,
    listAuth0Users,
    updateRole,
    getPermissionsByRole,
    removePermissionsFromRole,
    associatePermissionsWithRole,
    assignUsersToRole,
    getARolesUsers,
    removeRoleFromUser,
    getAuth0ResourceServers,
    updateResourceServer,
    getUserRoles,
    getUserPermissions,
    getAuth0UserDetails,
    removeRolesFromUser,
    listUsersForParentOrganization,
    associateUserToOrganization
  };
};

export default securityApi;
