/* eslint-disable import/prefer-default-export */
/**
 * Returns a mock Rxl api call promise.
*
* @return {Promise}
* @param {Object} data The data to be returned.
* @param {number} delay The mock latency of the response in ms.
* @param {string} param Placeholder for the parameter
*/
export const mockApiCall = (data, delay, param) => new Promise((fulfill) => {
  setTimeout(() => {
    const res = {
      status: 200,
      data: { data },
      param
    };
    fulfill(res);
  }, delay);
});
