import axios from 'axios';

const diagnosisCodesApi = (apiConfig) => {
  const listDiagnosisCodes = (searchTerm, index = 0, pageSize = 20) => axios.get('/v1/diagnosisCodes/search', {
    ...apiConfig,
    params: {
      searchTerm,
      index,
      pageSize
    }
  });

  return {
    listDiagnosisCodes
  };
};

export default diagnosisCodesApi;
