import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useRxlApis from '../hooks/useRxlApis';

const initialState = {
  notifications: null
};

const NotificationsContext = createContext({
  ...initialState
});

export const NotificationsProvider = ({ children }) => {
  const { user } = useAuth();
  const { listNotifications } = useRxlApis();

  const [state, dispatch] = useReducer(
    (internalState, action) => {
      switch (action.type) {
        case 'UPDATE': {
          return {
            ...internalState,
            ...(action.payload ?? {})
          };
        }
        default: {
          return internalState;
        }
      }
    },
    initialState,
    () => initialState
  );

  const updateNotifications = (notifications) => {
    dispatch({ type: 'UPDATE', payload: { notifications } });
  };

  useEffect(() => {
    if (user?.userId) {
      listNotifications()
        .then((response) => {
          updateNotifications(response?.data?.data);
        })
        .catch(() => {
          /** oops * */
        });
    }
  }, [user?.userId]);

  return (
    <NotificationsContext.Provider value={{ ...state }}>
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NotificationsContext;
