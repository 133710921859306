import axios from 'axios';

const licenseApi = (apiConfig) => {
  const createLicense = (license) => axios.post('/v1/license', license, apiConfig);

  const listRAMLicensesByOrganizationId = (organizationId) => axios.get('/v1/licenses', {
    ...apiConfig,
    params: {
      organizationId,
      licenseType: 'RAM'
    }
  });

  const updateLicense = (license) => axios.put(`/v1/license/${license.licenseId}`, license, apiConfig);

  const listRamOrgs = () => axios.get('/v1/licenses/ram-orgs', apiConfig);

  const listRamHistory = (licenseId) => axios.get(`/v1/license/${licenseId}/history`, apiConfig);

  const markRAMLicensesAsInUse = (
    organizationId,
    licenseIds,
    includeInRamCalc
  ) => axios.post(
    `/v1/licenses/include-in-ram-calc/${organizationId}?includeInRamCalc=${includeInRamCalc}`,
    licenseIds,
    apiConfig
  );

  return {
    createLicense,
    listRAMLicensesByOrganizationId,
    updateLicense,
    listRamOrgs,
    listRamHistory,
    markRAMLicensesAsInUse
  };
};

export default licenseApi;
