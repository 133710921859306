import React, {
  createContext, useEffect, useReducer, useState
} from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  first, groupBy, startCase, toLower
} from 'lodash';
import {
  getDateRangeBetweenTwoDates,
  getFirstDayOfISOWeek,
  getFridayOfISOWeek,
  getWeekNumberByDate
} from '../views/rpt/components/week-scheduler/utils/scheduler-utility';
import useAuth from '../hooks/useAuth';
import { addDaysToDate, dateIsWeekendOrHoliday } from '../utils/date-utility';
import useRxlApis from '../hooks/useRxlApis';
import { getPrescriberName } from '../utils/name-utility';
import Days from '../views/rpt/components/week-scheduler/constants/days';
import { convertRamAmountToMillicurie } from '../views/rpt/licenses/utils/license-utility';
import { READ_RAM_LICENSE_DETAILS } from '../constants/permissions';

let currentDate = new Date(addDaysToDate(new Date(), 14));
while (dateIsWeekendOrHoliday(currentDate)) {
  currentDate = new Date(addDaysToDate(currentDate, 1));
}

const initialValues = {
  manufacturers: [],
  selectedManufacturer: null, // todo: (💩) attempt to match one based on service id or default to first manufacturer in manufacturers list
  drugOptions: [], // drug on each ramDrugProperty
  ramDrugProperties: [], // each ramDrugProperty includes drug, orderingRules, productionRules, availability and treatmentPlanTemplates
  selectedWeekNo: getWeekNumberByDate(currentDate),
  selectedDate: currentDate,
  selectedDrug: null, // selected drugOption
  selectedDrugProperties: null, // ramDrugProperty.drug
  selectedRamDrugProperties: null, // ramDrugProperty based on selectedDrug
  selectedWeekdays: null, // dates visible in rpt home
  selectedInventoryDate: null, // date selected from DoseCountCard
  scheduleItems: null,
  scheduleItemsEmbeds: null,
  rawDoseOrdersResponse: null,
  rawDoseOrdersEmbedsResponse: null,
  availabilityDateRange: {
    startDate: null,
    endDate: null
  },
  availabilityDates: {
    availableDays: [],
    unavailableDays: []
  },
  doseList: [],
  incrementWeekNo: () => {},
  decrementWeekNo: () => {},
  updateSelectedDate: () => {},
  updateSelectedDrug: () => {},
  updateSelectedAvailabilityDateRange: () => {},
  updateSelectedInventoryDate: () => {},
  refreshRPT: () => {}
};

const RPTContext = createContext(initialValues);

export const RPTProvider = ({ children }) => {
  const [loadingScheduleItems, setLoadingScheduleItems] = useState(false);
  const { getOrdersForAnOrganizationByDate } = useRxlApis();
  const { selectedOrganization, manufacturers, hasPermission } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(
    (internalState, action) => {
      switch (action?.type) {
        case 'UPDATE': {
          return {
            ...internalState,
            ...(action.payload ?? {})
          };
        }
        default: {
          return internalState;
        }
      }
    },
    { ...initialValues },
    () => ({ ...initialValues })
  );
  const [loadingOpenDoses, setLoadingOpenDoses] = useState(false);

  const update = (payload) => {
    dispatch({
      type: 'UPDATE',
      payload
    });
  };

  const incrementWeekNo = () => {
    updateSelectedDate(new Date(addDaysToDate(state.selectedDate, 7)));
  };

  const decrementWeekNo = () => {
    updateSelectedDate(new Date(addDaysToDate(state.selectedDate, -7)));
  };

  const updateSelectedDate = (newDate) => {
    update({
      selectedDate: newDate ?? currentDate,
      selectedWeekNo: getWeekNumberByDate(newDate ?? currentDate)
    });
  };

  const updateDrugOptions = () => {
    if (manufacturers.length > 0) {
      const ramDrugProperties = manufacturers?.flatMap((m) => m?.ramDrugProperties.flatMap((rdp) => ({
        ...rdp
      })));

      const drugOptions = ramDrugProperties.flatMap((d) => ({
        ...d.drug,
        label: startCase(d?.drug?.genericDrugName),
        id: d?.drug?.drugId
      }));

      update({
        drugOptions,
        ramDrugProperties
      });
    }
  };

  const updateSelectedManufacturer = () => {
    if (manufacturers.length > 0) {
      // todo: find manufacturer by matching serviceId if possible
      update({
        selectedManufacturer: manufacturers?.[0]
      });
    }
  };

  const updateSelectedDrug = (newDrug) => update({
    selectedDrug: newDrug,
    selectedDrugProperties: state.drugOptions.find(
      (drug) => drug?.drugId === newDrug?.drugId
    ),
    selectedRamDrugProperties: state.ramDrugProperties?.find(
      (rdp) => rdp?.drug?.drugId === newDrug?.drugId
    )
  });

  const updateSelectedWeekdays = () => {
    const selectedWkNo = state.selectedWeekNo;
    const selectedDate = state.selectedDate;
    if (selectedWkNo) {
      const [mo, fri] = [
        getFirstDayOfISOWeek(selectedWkNo, selectedDate?.getFullYear()),
        getFridayOfISOWeek(selectedWkNo, selectedDate?.getFullYear())
      ];
      update({
        selectedWeekdays: getDateRangeBetweenTwoDates(mo, fri)
      });
    }
  };

  // todo: on-update also update useful ordering values (e.g. ramCapacity, order/cancel window, etc.)
  const updateSelectedInventoryDate = (inventoryDate) => {
    update({
      selectedInventoryDate: inventoryDate
    });
  };

  const updateSelectedAvailabilityDateRange = (startDate, endDate) => {
    update({
      availabilityDateRange: {
        startDate,
        endDate
      }
    });
  };

  const fetchDoses = () => {
    if (state?.selectedWeekdays?.length > 0) {
      setLoadingOpenDoses(true);
      setLoadingScheduleItems(true);
      const startDate = new Date(state?.selectedWeekdays[0])
        ?.toISOString()
        ?.slice(0, 10);
      const endDate = new Date(
        state?.selectedWeekdays[state?.selectedWeekdays.length - 1]
      )
        ?.toISOString()
        ?.slice(0, 10);

      getOrdersForAnOrganizationByDate(
        selectedOrganization?.organizationId,
        startDate,
        endDate
      )
        .then((response) => {
          if (response?.status?.locations?.length > 0) {
            enqueueSnackbar('Failed to fetch doses.', {
              autoHideDuration: 3000,
              variant: 'error',
              preventDuplicate: true
            });
          } else if (response?.data?.data) {
            // todo: set doseList to response?.data?.data;
            // todo: apply different mapping strategies if used by doseListView/orderListView OR call buildDoseOrdersList to be used with doseCountCard
            const doseOrdersList =
              buildDoseOrdersList(
                response?.data?.data,
                response?.data?.embeds
              ) ?? [];

            update({
              rawDoseOrdersResponse: response?.data?.data ?? [],
              rawDoseOrdersEmbedsResponse: response?.data?.embeds ?? {},
              doseList: doseOrdersList,
              scheduleItems:
                doseOrdersList
                  ?.find(
                    (doseOrder) => doseOrder?.drugId === state?.selectedDrug?.drugId
                  )
                  ?.data?.flatMap((s) => s?.scheduleItems) ?? []
            });
          }
        })
        .catch(() => enqueueSnackbar('Failed to fetch dose orders.', {
          autoHideDuration: 3000,
          variant: 'error',
          preventDuplicate: true
        }))
        .finally(() => {
          setLoadingOpenDoses(false);
          setLoadingScheduleItems(false);
        });
    }
  };

  const getStatusCounts = (
    doseOrders,
    drugProperties = null,
    orderDate = null
  ) => {
    const confirmedCount = doseOrders?.filter(
      (d) => toLower(d?.doseOrderStatus) === 'confirmed'
    )?.length;
    const cancelledCount = doseOrders?.filter(
      (d) => toLower(d?.doseOrderStatus) === 'cancelled'
    )?.length;
    const pendingCount = doseOrders?.filter(
      (d) => toLower(d?.doseOrderStatus) === 'pending'
    )?.length;
    const administeredCount = doseOrders?.filter(
      (d) => toLower(d?.doseOrderStatus) === 'administered'
    )?.length;

    let availableCount = 0;
    if (drugProperties && orderDate <= 4 >= 0) {
      // todo: next 5 or 6 lines are redundant
      const ramCap =
        drugProperties?.availability?.[toLower(Days?.[orderDate])]?.amount ?? 0;

      const radioactivity = drugProperties?.drug?.radioactivity;
      availableCount = Math.floor(ramCap / radioactivity?.amount);

      if (radioactivity?.units !== 'mCi') {
        radioactivity.amount = convertRamAmountToMillicurie(
          radioactivity?.amount,
          radioactivity?.units
        );
        radioactivity.units = 'mCi';
      }

      let ramRemaining = ramCap;

      if (doseOrders?.length > 0) {
        doseOrders?.forEach((d) => {
          if (
            ['confirmed', 'administered'].includes(toLower(d?.doseOrderStatus))
          ) {
            if (d?.ramUnits === 'mCi') {
              ramRemaining -= d?.ramAmount;
            } else {
              ramRemaining -= convertRamAmountToMillicurie(
                d?.ramAmount,
                d?.ramUnits
              );
            }
          }
        });
      }
      availableCount = Math.floor(ramRemaining / radioactivity?.amount);
    }

    return {
      doseCount: doseOrders?.length,
      confirmed: confirmedCount,
      cancelled: cancelledCount,
      pending: pendingCount,
      administered: administeredCount,
      available: availableCount
    };
  };

  const buildDoseOrdersList = (doseOrders, embeds) => {
    // todo: (💩) find by matching serviceId or fallback to first manufacturer option
    const primaryManufacturer = manufacturers?.[0];
    const defaultDoseList = primaryManufacturer?.ramDrugProperties?.map(
      (rptDrug) => ({
        name: rptDrug?.drug?.genericDrugName,
        drugId: rptDrug?.drug?.drugId,
        data: Array.from({ length: 5 }, (i, index) => {
          let disableByManufacturer;
          if (state?.selectedRamDrugProperties?.availability) {
            const availWkDays = Object.keys(
              state.selectedRamDrugProperties?.availability
            );

            disableByManufacturer = Days.map(
              (wd) => availWkDays.findIndex((ad) => ad === toLower(wd)) === -1
            );
          }

          // default disable every day besides wednesday/thursday for clarity
          const isDisabled =
            disableByManufacturer?.[index] ?? (index !== 2 && index !== 3);
          let defaultAvailableCount = 0;

          // TODO: refactor - some of this exact code is in the getStatusCounts (apparently i have 1 brain cell because this work is done 2 times...)
          if (!isDisabled) {
            const rptDrugAvailability = rptDrug?.availability;
            const rptDrugDoseDateAvailabilityAmount =
              rptDrugAvailability?.[toLower(Days?.[index])]?.amount;
            const rptDrugSingleDoseAmount =
              rptDrug?.drug?.radioactivity?.amount;
            defaultAvailableCount =
              Math.floor(
                rptDrugDoseDateAvailabilityAmount / rptDrugSingleDoseAmount
              ) ?? 0;
          }

          return {
            drug: rptDrug?.drug,
            drugId: rptDrug?.drug?.drugId,
            producedDoses: 0,
            cancelledDoses: 0,
            pendingDoses: 0,
            confirmedDoses: 0,
            availableDoses: defaultAvailableCount,
            disabled: isDisabled,
            scheduleItems: []
          };
        })
      })
    );

    const groupedOrders = groupBy(doseOrders, 'injectionDate');
    const groupedOrdersKeys = Object.keys(groupedOrders);
    const mappedGroupedOrders = groupedOrdersKeys?.map((o) => {
      const groupedOrder = groupedOrders?.[o];
      const firstGroupedOrder = first(groupedOrder);

      const groupedOrderDoseOrders =
        groupedOrder?.flatMap((cgo) => cgo?.doseOrders) ?? [];
      const firstGroupedOrderDoseOrder = first(groupedOrderDoseOrders);

      // todo: this should use selectedDrug instead of the firstGrouped?.DrugId, but it will work for clarity's single drug
      const drugProperties = state?.ramDrugProperties?.find(
        (rdp) => rdp?.drug?.drugId === firstGroupedOrder?.drugId
      );
      const orderDate =
        new Date(firstGroupedOrderDoseOrder?.injectionDate)?.getDay() - 1 ?? 0;

      const counts = getStatusCounts(
        groupedOrderDoseOrders,
        drugProperties,
        orderDate
      );

      return {
        organizationId: firstGroupedOrderDoseOrder?.organizationId,
        organizationName:
          embeds?.[firstGroupedOrderDoseOrder?.organizationId]
            ?.organizationName ??
          firstGroupedOrderDoseOrder?.organizationId ??
          '------',
        created: firstGroupedOrder?.created,
        drugId: firstGroupedOrder?.drugId,
        drugProperties,
        drugName: embeds?.[firstGroupedOrder?.drugId]?.name,
        injectionDate: firstGroupedOrder?.injectionDate,
        authorizedUserName: getPrescriberName(
          embeds?.[firstGroupedOrder?.prescriberId]
        ),
        productionDate: firstGroupedOrderDoseOrder?.productionDate,
        lastUpdated: firstGroupedOrder?.lastUpdated,
        prescriberId: firstGroupedOrder?.prescriberId,
        orderSetId: firstGroupedOrder?.orderSetId,
        doseOrders: groupedOrderDoseOrders,
        scheduleItems: groupedOrderDoseOrders
          ?.filter(
            (doseOrderItem) => !['administered', 'cancelled']?.includes(
              toLower(doseOrderItem?.doseOrderStatus)
            ) &&
              doseOrderItem?.utcStartTime &&
              // todo: this should use selectedDrug instead of the firstGrouped?.DrugId, but it will work for clarity's single drug
              doseOrderItem?.drugId === firstGroupedOrder?.drugId
          )
          ?.map((_doseOrderItem) => ({
            who: _doseOrderItem?.patientStudyIdNumber ?? 'Unknown',
            utcStartTime: _doseOrderItem?.utcStartTime,
            utcEndTime: _doseOrderItem?.utcEndTime,
            status: _doseOrderItem?.doseOrderStatus,
            doseOrderId: _doseOrderItem?.doseOrderId,
            doseOrder: _doseOrderItem
          })),
        orderDoseIds: groupedOrderDoseOrders?.flatMap((d) => d?.doseId),
        orderSetIds: groupedOrder?.flatMap((cgo) => cgo?.orderSetId) ?? [],
        doseCount: counts?.doseCount ?? 0,
        confirmed: counts?.confirmed ?? 0,
        cancelled: counts?.cancelled ?? 0,
        counts
      };
    });

    mappedGroupedOrders?.forEach((groupedOrder) => {
      const {
        drugId, counts, injectionDate, scheduleItems
      } = groupedOrder;

      const {
        confirmed, cancelled, pending, available
      } = counts;

      // mon-fri
      const doseDate = new Date(injectionDate)?.getDay() - 1 ?? 0;

      // grab drug from first day of the week (will be the same for other days)
      const rptDrugIndex = defaultDoseList.findIndex(
        (doseData) => doseData?.data?.[0]?.drugId === drugId
      );

      if (drugId === state.selectedDrug?.drugId && rptDrugIndex >= 0) {
        defaultDoseList[rptDrugIndex].data[doseDate].cancelledDoses =
          cancelled ?? 0;

        // exact count of doses in "Pending" status for the calendar day
        defaultDoseList[rptDrugIndex].data[doseDate].pendingDoses =
          pending ?? 0;

        // combined count of doses in "Confirmed" & "Confirmed/Unused Dose(s)" status for the calendar day
        defaultDoseList[rptDrugIndex].data[doseDate].confirmedDoses =
          confirmed ?? 0;

        // Available = Manufacturer production capacity (doses) for calendar day - "Confirmed doses" - "Confirmed/Unused Dose(s)"
        // aka capacity/day - confirmed - administered
        defaultDoseList[rptDrugIndex].data[doseDate].availableDoses =
          available ?? 0;

        defaultDoseList[rptDrugIndex].data[doseDate].scheduleItems =
          scheduleItems ?? [];
      }
    });

    return defaultDoseList;
  };

  // hard refresh scheduleItems and update selected date
  const refreshRPT = (requestedDate) => {
    update({ scheduleItems: [] });
    updateSelectedDate(
      requestedDate ?? state.selectedInventoryDate?.date ?? currentDate
    );
  };

  // if selected weekdays or selectedDrug change - then fetch available doses
  useEffect(() => {
    if (state?.selectedWeekdays && state?.selectedDrug) {
      fetchDoses();
    }
  }, [state?.selectedWeekdays, state?.selectedDrug]);

  // if the selected weekNoChanges - then updated selected weekdays
  useEffect(() => {
    updateSelectedWeekdays();
  }, [state?.selectedDate]);

  // update default selected drug
  useEffect(() => {
    if (state?.drugOptions?.length > 0) {
      updateSelectedDrug(state?.drugOptions?.[0]);
    }
  }, [state?.drugOptions]);

  // initialize data
  useEffect(() => {
    const controller = new AbortController();
    if (hasPermission(READ_RAM_LICENSE_DETAILS)) {
      updateDrugOptions();
      updateSelectedManufacturer();
      updateSelectedWeekdays();
    }

    return () => controller.abort();
  }, [selectedOrganization?.organizationId]);

  return (
    <RPTContext.Provider
      value={{
        ...state,
        loadingOpenDoses,
        loadingScheduleItems,
        incrementWeekNo,
        decrementWeekNo,
        updateSelectedDate,
        updateSelectedDrug,
        updateSelectedInventoryDate,
        updateSelectedWeekdays,
        updateSelectedAvailabilityDateRange,
        fetchDoses,
        refreshRPT
      }}
    >
      {children}
    </RPTContext.Provider>
  );
};

RPTProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default RPTContext;
