import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  useTheme
} from '@mui/material';
import { InlineIcon } from '@iconify/react';
import AccountNav from './components/AccountNav';
import TextNavButton from './components/TextNavButton';
import useAuth from '../../../hooks/useAuth';
import {
  EMBEDDED_PORTAL, MANAGE_GROUPS,
  MANAGE_PARENT_ORGANIZATIONS,
  READ_CASE,
  READ_ENROLLMENT,
  READ_FOUNDATION,
  READ_MEDACCESS,
  READ_PATIENT,
  READ_PRESCRIBER,
  READ_RAM_LICENSE_DETAILS
} from '../../../constants/permissions';
import {
  Case,
  CreditCardHeart,
  Enrollment,
  FoundationSearch,
  MedAccessQueue,
  MedicalFile,
  Menu,
  Patient,
  PatientDetails,
  Prescriber
} from '../../../constants/icons';
import {
  caseListPath,
  copayCardsPath,
  enrollmentListPath,
  foundationsPath,
  lisPath,
  medAccessListPath,
  patientDetailsPath,
  patientListPath,
  prescriberListPath
} from '../../../constants/paths';
import Logo from '../../../components/logos/Logo';
import OrganizationSelector from '../../../views/organizations/components/OrganizationSelector';
import GroupSelector from '../../../views/organizations/components/GroupSelector';
import useBranding from '../../../hooks/useBranding';
import useFeatures from '../../../hooks/useFeatures';
import { MAQ } from '../../../constants/features';

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const theme = useTheme();
  const { hasPermission, user } = useAuth();
  const branding = useBranding();
  const { featureEnabled } = useFeatures();

  return (
    <AppBar
      className={className}
      sx={{
        boxShadow: 'none',
        backgroundColor: theme.palette.header.default
      }}
      {...rest}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton onClick={onMobileNavOpen} size="large">
            <InlineIcon icon={Menu} />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          {!hasPermission(EMBEDDED_PORTAL) && (
            <Logo selectedBranding={branding} />
          )}
        </Hidden>
        <Box flexGrow={1} ml={2} />
        <Hidden lgDown>
          {hasPermission(READ_CASE) && (
            <TextNavButton icon={Case} label="Cases" to={caseListPath()} />
          )}
          {hasPermission(READ_PATIENT) && !user.patientId && (
            <TextNavButton
              icon={Patient}
              label="Patients"
              to={patientListPath()}
            />
          )}
          {hasPermission(READ_PATIENT) && user.patientId && (
            <TextNavButton
              icon={PatientDetails}
              label="My Info"
              to={patientDetailsPath(user.patientId)}
            />
          )}
          {!hasPermission(READ_RAM_LICENSE_DETAILS) &&
            hasPermission(READ_PRESCRIBER) && (
              <TextNavButton
                icon={Prescriber}
                label="Prescribers"
                to={prescriberListPath()}
              />
          )}
          {hasPermission(READ_ENROLLMENT) && (
            <TextNavButton
              icon={Enrollment}
              label="Enrollments"
              to={enrollmentListPath()}
            />
          )}
          {hasPermission(READ_MEDACCESS) && (
            <TextNavButton
              icon={MedAccessQueue}
              label="Med Access Queue"
              to={medAccessListPath()}
            />
          )}
          {hasPermission(READ_FOUNDATION) && (
            <TextNavButton
              icon={FoundationSearch}
              label="Foundations"
              to={foundationsPath()}
            />
          )}
          {featureEnabled(MAQ) && !hasPermission(READ_RAM_LICENSE_DETAILS) && (
            <TextNavButton
              icon={CreditCardHeart}
              label="Copay Cards"
              to={copayCardsPath()}
            />
          )}
          {featureEnabled(MAQ) && !hasPermission(READ_RAM_LICENSE_DETAILS) && (
            <TextNavButton icon={MedicalFile} label="LIS" to={lisPath()} />
          )}
          {hasPermission(MANAGE_PARENT_ORGANIZATIONS) && (
            <Box ml={1}>
              <OrganizationSelector />
            </Box>
          )}
          {!user.patientId &&
            (user.organizations.length > 1 ||
              hasPermission(MANAGE_GROUPS)) && (
              <Box ml={1}>
                <GroupSelector />
              </Box>
          )}
        </Hidden>
        <Box ml={2}>
          <AccountNav />
        </Box>
        <Hidden lgDown>
          <Box pr={3} />
        </Hidden>
        <Hidden mdDown>
          <Box pr={1} />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
