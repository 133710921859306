import axios from 'axios';

const signatureApi = (apiConfig) => {
  const createSignatureAttachment = (attachment) => axios.post('/v1/signature', attachment, apiConfig);

  const registerPrescriberSignature = (prescriberRegistrationRequest) => axios.put('/v1/signature/register', prescriberRegistrationRequest, apiConfig);

  return {
    createSignatureAttachment,
    registerPrescriberSignature
  };
};

export default signatureApi;
