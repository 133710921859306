import dayJs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayJs.extend(isoWeek);

// todo: combine this file with the date-utility and utilize DayJs instead of actually calculating

/**
 * Get the current week number based on the date.
 *
 * @return {number}
 * @param date
 */
export const getWeekNumberByDate = (date) => dayJs(date).isoWeek();

/**
 * Get the first day(date) of week. (sunday)
 *
 * @return {Date}
 * @param {number} weekNumber
 * @param {number} year
 */
export const getFirstDayOfWeek = (weekNumber, year) => {
  const d = 1 + (weekNumber - 1) * 7; // 1st of January + 7 days for each week
  return new Date(year, 0, d);
};

/**
 * Get the first day(date) of ISO week. (monday)
 *
 * @return {Date}
 * @param {number} weekNumber
 * @param {number} year
 */
export const getFirstDayOfISOWeek = (weekNumber, year = 2024) => {
  const simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  const dow = simple.getDay();
  const ISOWeekStart = simple;
  if (dow <= 4) {
    ISOWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }
  return ISOWeekStart;
};

/**
 * Get the last day(date) of week.
 *
 * @return {Date}
 * @param {number} weekNumber
 * @param {number} year
 */
export const getLastDayOfWeek = (weekNumber, year) => {
  const d = 7 + (weekNumber - 1) * 7; // 7th of January + 7 days for each week
  return new Date(year, 0, d);
};

/**
 * Get the last day(date) of ISO week.
 *
 * @return {Date}
 * @param {number} weekNumber
 * @param {number} year
 */
export const getLastDayOfISOWeek = (weekNumber, year = 2024) => dayJs(getFirstDayOfISOWeek(weekNumber, year)).add(6, 'day').toDate();

/**
 * Get friday(date) of ISO week.
 *
 * @return {Date}
 * @param {number} weekNumber
 * @param {number} year
 */
export const getFridayOfISOWeek = (weekNumber, year) => dayJs(getFirstDayOfISOWeek(weekNumber, year)).add(4, 'day').toDate();

/**
 * Get the ISO week start and end dates based on year and week number.
 *
 * @param  {number} weekNumber
 * @param  {number} year
 * @return {{startOfWeek: Date, endOfWeek: Date}}
 */
export const getStartAndEndDateOfISOWeekByWeekNoAndYear = (
  weekNumber,
  year
) => {
  const isoStartDate = getFirstDayOfISOWeek(weekNumber, year);
  const isoEndDate = getLastDayOfISOWeek(weekNumber, year);

  return {
    startOfWeek: isoStartDate,
    endOfWeek: isoEndDate
  };
};

/**
 * Get the ISO week start and end dates based on year and week number.
 *
 * @param  {Date} startDate
 * @param  {Date} endDate
 * @return {*[]}
 */
export const getDateRangeBetweenTwoDates = (startDate, endDate) => {
  const extEndDate = dayJs(endDate).add(1, 'day');
  const days = [];
  let tempDate = startDate;
  while (tempDate < extEndDate) {
    days.push(tempDate);
    tempDate = dayJs(tempDate).add(1, 'day').toDate();
  }
  return days;
};

/**
 * Date Is Between.
 *
 * @param  {Date} startDate
 * @param  {Date} endDate
 * @param  {Date} selectedDate
 * @return {Boolean}
 */
export const dateIsBetween = (startDate, endDate, selectedDate) => {
  const start = Date.parse(startDate);
  const end = Date.parse(endDate);
  const date = Date.parse(selectedDate);

  return date >= start && date <= end;
};

/**
 * Time Is Between.
 * params are in military/24-hour format
 * @param startTime
 * @param endTime
 * @param testTime
 * @return {Boolean}
 */
export const timeIsBetween = (startTime, endTime, testTime) => {
  const startHour = parseInt(startTime.substring(0, 2), 10);
  const endHour = parseInt(endTime.substring(0, 2), 10);
  const testHour = parseInt(testTime.substring(0, 2), 10);
  return testHour >= startHour && testHour <= endHour;
};

/**
 * Date Is Before.
 * @param dateA
 * @param dateB
 * @return {Boolean}
 */
export const dateAIsBeforeDateB = (dateA, dateB) => dayJs(dateA).isBefore(dateB);

/**
 * Date Is equal.
 * @param dateA
 * @param dateB
 * @return {Boolean}
 */
export const dateAIsSameAsDateB = (dateA, dateB) => dayJs(dateA).isSame(dateB);
