import React, {
  createContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import useEnvironment from '../hooks/useEnvironment';

const initialState = {
  trackException: () => {}
};

const LoggingContext = createContext({
  ...initialState
});

export const LoggingProvider = ({ children }) => {
  const { ENVIRONMENT, LOGGING_CONNECTION_STRING } = useEnvironment();
  const [reactPlugin, setReactPlugin] = useState(null);
  const [appInsights, setAppInsights] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!LOGGING_CONNECTION_STRING) return;
    if (ENVIRONMENT === 'local') {
      setReactPlugin(null);
      setAppInsights(null);
      setInitialized(true);
    } else {
      const newPlugin = new ReactPlugin();
      const newAppInsights = new ApplicationInsights({
        config: {
          connectionString: LOGGING_CONNECTION_STRING,
          enableAutoRouteTracking: true,
          extensions: [newPlugin],
        }
      });
      newAppInsights?.loadAppInsights();

      setReactPlugin(newPlugin);
      setAppInsights(newAppInsights);
      setInitialized(true);
    }
  }, [ENVIRONMENT, LOGGING_CONNECTION_STRING]);

  const trackException = ({ error, properties }) => {
    if (appInsights) {
      appInsights.trackException({ error, properties });
    }
  };

  if (ENVIRONMENT === 'local') {
    return children;
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <LoggingContext.Provider value={{ trackException }}>
        {initialized ? children : <></>}
      </LoggingContext.Provider>
    </AppInsightsContext.Provider>
  );
};

LoggingProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LoggingContext;
