import axios from 'axios';

const codeListApi = (apiConfig) => {
  const listQuantityUnitOfMeasure = () => axios.get('/v1/codelist/quom', apiConfig);

  const listRAMUnits = () => axios.get('v1/codelist/quom?searchType=radiationActivityUnits', apiConfig);

  return {
    listQuantityUnitOfMeasure,
    listRAMUnits
  };
};

export default codeListApi;
