import { mockApiCall } from '../utils/api-utility';

// eslint-disable-next-line no-unused-vars
const copayApi = (apiConfig) => {
  const mockCopayCardProgramData = [
    {
      drug: 'Drugerol',
      manufacturer: 'Big Pharma',
      programs: [
        {
          programName: 'Program 1',
          copayAmount: 1234,
          phone: '5025555555',
          website: 'www.website.com',
          bin: 'binny bin',
          pcn: 9000,
          monthlyMax: 90001,
          yearlyMax: null,
          patientOutreachRequired: false,
          eligibilityCriteria: [
            'criteria 1',
            'criteria 2',
            'criteria 3'
          ],
        }
      ]
    },
    {
      drug: 'Drugerone',
      manufacturer: 'Little Pharma',
      programs: [
        {
          programName: 'Program A',
          copayAmount: 1234,
          phone: '5025555555',
          website: 'www.website.com',
          bin: 'binny bin',
          pcn: 9000,
          monthlyMax: 90001,
          yearlyMax: null,
          patientOutreachRequired: false,
          eligibilityCriteria: [
            'criteria 1',
            'criteria 2',
            'criteria 3'
          ],
          hubRequired: true,
          papAvailable: false
        },
        {
          programName: 'Program B',
          copayAmount: 2435,
          phone: '5025555555',
          website: 'www.website.com',
          bin: 'this is a bin',
          pcn: 5000,
          monthlyMax: null,
          yearlyMax: null,
          patientOutreachRequired: false,
          eligibilityCriteria: [
            'criteria 1',
            'criteria 2',
            'criteria 3'
          ],
          hubRequired: false,
          papAvailable: true
        }
      ]
    }
  ];

  const listCopayCardPrograms = () => mockApiCall(mockCopayCardProgramData, 500);

  const saveCopayCardToPatient = (copayCard, patientId) => mockApiCall({}, { copayCard, patientId }, 500);

  return {
    listCopayCardPrograms,
    saveCopayCardToPatient
  };
};

export default copayApi;
