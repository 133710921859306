import React, { useRef, useState } from 'react';
import {
  Avatar,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  useTheme
} from '@mui/material';
import { InlineIcon } from '@iconify/react';
import { useNavigate } from 'react-router';
import useAuth from '../../../../hooks/useAuth';
import {
  EMBEDDED_PORTAL,
  FORMIFIER_ADMIN_QUESTIONS,
  FORMIFIER_VIEW,
  READ_LDD,
  READ_MESSAGE,
  READ_NOTIFICATIONS,
  READ_SWAGGER,
  VIEW_ACCOUNT_MANAGEMENT
} from '../../../../constants/permissions';
import {
  BaseQuestionManager,
  EditUser,
  Hub,
  LDD,
  Logout,
  Mail,
  Notification,
  Organization,
  Swagger,
  Training,
  ViewEnrollment
} from '../../../../constants/icons';
import {
  organizationManagementPath,
  editUserPath,
  formListPath,
  formManagerTrainingPath,
  lddRelationshipListPath,
  notificationListPath,
  questionManagementPath,
  supportPath,
  swaggerListPath,
  messagesPath
} from '../../../../constants/paths';
import useBranding from '../../../../hooks/useBranding';

const AccountNav = () => {
  const ref = useRef(null);
  const { hasPermission, logout, user } = useAuth();
  const branding = useBranding();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);
  const walgreensBranding = branding.referralCode === 'ded0e4ec-e9e7-4808-a186-9711dcd57dc5';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUserLabel = () => {
    const { firstName, lastName, email } = user;

    if (firstName && lastName) {
      return `${lastName}, ${firstName}`;
    }

    if (firstName) {
      return firstName;
    }

    if (lastName) {
      return lastName;
    }

    return email;
  };

  const menuOptions = [];

  if (hasPermission(VIEW_ACCOUNT_MANAGEMENT)) {
    menuOptions.push(
      <MenuItem
        key={organizationManagementPath()}
        onClick={() => {
          handleClose();
          navigate(organizationManagementPath());
        }}
      >
        <ListItemIcon>
          <InlineIcon icon={Organization} color={theme.palette.text.title} />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>
          Organization Management
        </Typography>
      </MenuItem>
    );
  }

  if (hasPermission(READ_MESSAGE)) {
    menuOptions.push(
      <MenuItem
        key={messagesPath()}
        onClick={() => {
          handleClose();
          navigate(messagesPath());
        }}
      >
        <ListItemIcon>
          <InlineIcon icon={Mail} color={theme.palette.text.title} />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>Messages</Typography>
      </MenuItem>
    );
  }

  menuOptions.push(
    <MenuItem
      key={editUserPath()}
      onClick={() => {
        handleClose();
        navigate(editUserPath());
      }}
    >
      <ListItemIcon>
        <InlineIcon icon={EditUser} color={theme.palette.text.title} />
      </ListItemIcon>
      <Typography color={theme.palette.text.title}>
        Edit My Account Info
      </Typography>
    </MenuItem>
  );

  if (hasPermission(READ_LDD)) {
    menuOptions.push(
      <MenuItem
        key={lddRelationshipListPath()}
        onClick={() => {
          handleClose();
          navigate(lddRelationshipListPath());
        }}
      >
        <ListItemIcon>
          <InlineIcon icon={LDD} color={theme.palette.text.title} />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>
          Limited Distribution Drugs
        </Typography>
      </MenuItem>
    );
  }

  if (hasPermission(READ_NOTIFICATIONS)) {
    menuOptions.push(
      <MenuItem
        key={notificationListPath()}
        onClick={() => {
          handleClose();
          navigate(notificationListPath());
        }}
        color={theme.palette.text.title}
      >
        <ListItemIcon>
          <InlineIcon icon={Notification} color={theme.palette.text.title} />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>Notifications</Typography>
      </MenuItem>
    );
  }

  if (hasPermission(FORMIFIER_ADMIN_QUESTIONS)) {
    menuOptions.push(
      <MenuItem
        key={questionManagementPath()}
        onClick={() => {
          handleClose();
          navigate(questionManagementPath());
        }}
      >
        <ListItemIcon>
          <InlineIcon
            icon={BaseQuestionManager}
            color={theme.palette.text.title}
          />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>
          Base Question Manager
        </Typography>
      </MenuItem>
    );
  }

  if (hasPermission(FORMIFIER_VIEW)) {
    menuOptions.push(
      <MenuItem
        key={formListPath()}
        onClick={() => {
          handleClose();
          navigate(formListPath());
        }}
      >
        <ListItemIcon>
          <InlineIcon icon={ViewEnrollment} color={theme.palette.text.title} />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>Forms</Typography>
      </MenuItem>,
      <MenuItem
        key={formManagerTrainingPath()}
        onClick={() => {
          handleClose();
          navigate(formManagerTrainingPath());
        }}
      >
        <ListItemIcon>
          <InlineIcon icon={Training} color={theme.palette.text.title} />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>Training</Typography>
      </MenuItem>
    );
  }

  if (hasPermission(READ_SWAGGER)) {
    menuOptions.push(
      <MenuItem
        key={swaggerListPath()}
        onClick={() => {
          handleClose();
          navigate(swaggerListPath());
        }}
      >
        <ListItemIcon>
          <InlineIcon icon={Swagger} color={theme.palette.text.title} />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>Swagger</Typography>
      </MenuItem>
    );
  }

  menuOptions.push(
    <MenuItem
      key={supportPath()}
      onClick={() => {
        handleClose();
        navigate(supportPath());
      }}
    >
      <ListItemIcon>
        <InlineIcon icon={Hub} color={theme.palette.text.title} />
      </ListItemIcon>
      <Typography color={theme.palette.text.title}>Support</Typography>
    </MenuItem>
  );

  if (!hasPermission(EMBEDDED_PORTAL)) {
    menuOptions.push(
      <MenuItem id="topBar-dropdown-logout" key="logout" onClick={logout}>
        <ListItemIcon>
          <InlineIcon icon={Logout} color={theme.palette.text.title} />
        </ListItemIcon>
        <Typography color={theme.palette.text.title}>Logout</Typography>
      </MenuItem>
    );
  }

  return (
    <>
      <Button
        id="topBar-dropdown-open"
        onClick={handleOpen}
        ref={ref}
        startIcon={
          !hasPermission(EMBEDDED_PORTAL) && (
            <Avatar
              alt="User"
              src={branding.icon}
              variant="square"
              sx={{
                height: walgreensBranding ? '26px' : ''
              }}
            />
          )
        }
        sx={{ textTransform: 'none' }}
      >
        <Typography
          color={
            theme.palette.mode === 'dark'
              ? theme.palette.primary.light
              : theme.palette.primary.dark
          }
          variant="h5"
        >
          {getUserLabel()}
        </Typography>
      </Button>
      {
        // todo: this extra isOpen works to hide the menu on navigation, but should be improved
        isOpen && (
          <Menu
            id="topBar-dropdown-menu"
            anchorEl={ref.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            keepMounted
            onClose={handleClose}
            open={isOpen}
            sx={{ width: '100%' }}
          >
            {menuOptions}
          </Menu>
        )
      }
    </>
  );
};

export default AccountNav;
