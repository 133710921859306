import React, { Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { isNil } from 'lodash';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import LoadingScreen from './components/loading/LoadingScreen';
import AuthGuard from './components/auth/AuthGuard';
import {
  CREATE_DRUG,
  CREATE_ENROLLMENT,
  CREATE_LDD,
  CREATE_PATIENT,
  CREATE_PRESCRIBER,
  CREATE_UPICK,
  FORMIFIER_ADMIN_QUESTIONS,
  FORMIFIER_VIEW,
  MANAGE_GROUPS,
  MANAGE_PARENT_ORGANIZATIONS,
  MANAGE_PENDING_APPROVALS,
  MANAGE_PERMISSIONS,
  MANAGE_PROVIDERS,
  MANAGE_RPT,
  MANAGE_ROLES,
  READ_BATCH_RELEASE,
  READ_CASE_DASHBOARD,
  READ_COMPLETE_ENROLLMENT,
  READ_COMPLIANCE,
  READ_DESTINATION,
  READ_DOSE,
  READ_DOSE_ORDER,
  READ_DRUG,
  READ_ENROLLMENT,
  READ_FILE_INTAKE,
  READ_FOUNDATION,
  READ_LDD,
  READ_MEDACCESS,
  READ_MESSAGE,
  READ_NOTIFICATIONS,
  READ_ORG_RAM_APPROVAL,
  READ_PATIENT,
  READ_PRESCRIBER,
  READ_RAM_LICENSE_DETAILS,
  READ_RPT,
  READ_ORDER_MANAGEMENT,
  READ_SWAGGER,
  READ_USER,
  SIGN_BAA,
  UPDATE_PRESCRIBER_QUANTITY,
  VIEW_ACCOUNT_MANAGEMENT
} from './constants/permissions';
import AnonymousLayout from './layouts/AnonymousLayout';
import FeatureGuard from './components/auth/FeatureGuard';

const formManagerRoutes = [
  {
    component: lazy(() => import('./views/formifier/FormListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/formifier',
    permissions: [FORMIFIER_VIEW]
  },
  {
    component: lazy(() => import('./views/formifier/FormEditView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/formifier/:formId',
    permissions: [FORMIFIER_VIEW]
  },
  {
    component: lazy(() => import('./views/formifier/QuestionatorView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/questionator/:formId',
    permissions: [FORMIFIER_VIEW]
  },
  {
    component: lazy(() => import('./views/formifier/FormSummaryView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/formSummary/:formId',
    permissions: [FORMIFIER_VIEW]
  },
  {
    component: lazy(() => import('./views/formifier/QuestionManagementView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/questionManagement',
    permissions: [FORMIFIER_ADMIN_QUESTIONS]
  },
  {
    component: lazy(() => import('./views/formifier/GeneralFormView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/generalForm',
    permissions: [FORMIFIER_ADMIN_QUESTIONS]
  },
  {
    component: lazy(() => import('./views/formifier/EnrollmentPreviewView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/enrollmentPreview/:formId',
    permissions: [FORMIFIER_VIEW]
  },
  {
    component: lazy(() => import('./views/training/FormManagerResourcesView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/training/formManager',
    permissions: [FORMIFIER_VIEW]
  },
  {
    component: lazy(() => import('./views/drugs/DrugListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/drugs',
    permissions: [READ_DRUG]
  },
  {
    component: lazy(() => import('./views/drugs/DrugCreateView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/drugs/create',
    permissions: [CREATE_DRUG]
  },
  {
    component: lazy(() => import('./views/destinations/DestinationListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/destinations',
    permissions: [READ_DESTINATION]
  }
];

const patientRoutes = [
  {
    component: lazy(() => import('./views/patients/PatientConsentView')),
    exact: true,
    layout: AnonymousLayout,
    path: '/consent'
  },
  {
    component: lazy(() => import('./views/patients/PatientConsentView')),
    exact: true,
    layout: AnonymousLayout,
    path: '/consent/:challengeCode'
  },
  {
    component: lazy(() => import('./views/patients/PatientRegistrationView')),
    exact: true,
    layout: AnonymousLayout,
    path: '/patientregistration/:challengeCode'
  },
  {
    component: lazy(() => import('./views/patients/PatientListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/patients',
    permissions: [READ_PATIENT]
  },
  {
    component: lazy(() => import('./views/patients/PatientCreateView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/patients/register',
    permissions: [CREATE_PATIENT]
  },
  {
    component: lazy(() => import('./views/patients/PatientDetailsView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/patients/:patientId',
    permissions: [READ_PATIENT]
  },
  {
    component: lazy(() => import('./views/patients/PatientDocumentDownloadView')),
    exact: true,
    layout: AnonymousLayout,
    path: '/downloadpatientdoc?code'
  },
  {
    component: lazy(() => import('./views/patients/PatientDocumentDownloadView')),
    exact: true,
    layout: AnonymousLayout,
    path: '/downloadpatientdoc'
  }
];

const prescriberRoutes = [
  {
    component: lazy(() => import('./views/prescribers/PrescriberBaaView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/baa',
    permissions: [SIGN_BAA]
  },
  {
    component: lazy(() => import('./views/prescribers/PrescriberListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/prescribers',
    permissions: [READ_PRESCRIBER]
  },
  {
    component: lazy(() => import('./views/prescribers/PrescriberRegistrationView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/prescribers/register',
    permissions: [CREATE_PRESCRIBER]
  },
  {
    component: lazy(() => import('./views/prescribers/PrescriberDetailsView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/prescribers/:prescriberId',
    permissions: [READ_PRESCRIBER]
  },
  {
    component: lazy(() => import('./views/prescribers/PrescriberAddView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/providerManagement/add',
    permissions: [MANAGE_PROVIDERS]
  },
  {
    component: lazy(() => import('./views/prescribers/PrescriberManagementView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/providerManagement',
    permissions: [MANAGE_PROVIDERS]
  },
  {
    component: lazy(() => import('./views/prescribers/PrescriberVerificationView')),
    exact: true,
    layout: AnonymousLayout,
    path: '/prescriberVerification/:challengeCode'
  },
  {
    component: lazy(() => import('./views/organizations/OrgProviderListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/providers',
    permissions: [MANAGE_PROVIDERS]
  }
];

const enrollmentRoutes = [
  {
    component: lazy(() => import('./views/enrollments/EnrollmentListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/enrollments',
    permissions: [READ_ENROLLMENT]
  },
  {
    component: lazy(() => import('./views/enrollments/EnrollmentCreateView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/enrollments/create',
    permissions: [CREATE_ENROLLMENT]
  },
  {
    component: lazy(() => import('./views/enrollments/EnrollmentEditView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/enrollments/:enrollmentId',
    permissions: [READ_ENROLLMENT]
  },
  {
    component: lazy(() => import('./views/enrollments/EnrollmentCompleteView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/enrollments/complete/:enrollmentId',
    permissions: [READ_COMPLETE_ENROLLMENT]
  },
  {
    component: lazy(() => import('./views/med-access/MedAccessListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/medaccess',
    permissions: [READ_MEDACCESS]
  }
];

const financialAssistanceRoutes = [
  {
    component: lazy(() => import('./views/foundations/FoundationListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/foundations',
    permissions: [READ_FOUNDATION]
  },
  {
    component: lazy(() => import('./views/copay-cards/CopayCardsListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/copay-cards'
  },
  {
    component: lazy(() => import('./views/lis/LisView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/lis'
  }
];

const boltRoutes = [
  {
    component: lazy(() => import('./views/cases/CaseListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/cases',
    permissions: [READ_CASE_DASHBOARD]
  },
  {
    component: lazy(() => import('./views/file-intake/IntakeListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/file-intake',
    permissions: [READ_FILE_INTAKE]
  },
  {
    component: lazy(() => import('./views/prescribers/PrescriberVialsView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/productReplacement',
    permissions: [UPDATE_PRESCRIBER_QUANTITY]
  },
  {
    component: lazy(() => import('./views/prescribers/PrescriberVialsView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/compliance',
    permissions: [READ_COMPLIANCE]
  }
];

const accountManagementRoutes = [
  {
    component: lazy(() => import('./views/organizations/OrgGroupListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/groups',
    permissions: [MANAGE_GROUPS]
  },
  {
    component: lazy(() => import('./views/organizations/ParentOrganizationView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/parentOrganizations',
    permissions: [MANAGE_PARENT_ORGANIZATIONS]
  },
  {
    component: lazy(() => import('./views/organizations/CreateParentOrganizationView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/parentOrganizations/create',
    permissions: [MANAGE_PARENT_ORGANIZATIONS]
  },
  {
    component: lazy(() => import('./views/organizations/PendingApprovalsListView')),
    exact: true,
    layout: AuthenticatedLayout,
    path: '/app/pendingApprovals',
    permissions: [MANAGE_PENDING_APPROVALS]
  },
  {
    component: lazy(() => import('./views/directory/DirectoryListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/directory',
    permissions: [READ_USER]
  },
  {
    component: lazy(() => import(
      './views/account-management/AccountManagementView/AccountManagementColumnView'
    )),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/organizationManagement',
    permissions: [VIEW_ACCOUNT_MANAGEMENT]
  },
  {
    component: lazy(() => import('./views/directory/EditUserInfoView')),
    exact: true,
    layout: AuthenticatedLayout,
    path: '/app/editUser'
  }
];

const rolesAndPermsRoutes = [
  {
    component: lazy(() => import('./views/roles/RoleManagementView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/roles',
    permissions: [MANAGE_ROLES]
  },
  {
    component: lazy(() => import('./views/roles/PermissionManagementView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/permissions',
    permissions: [MANAGE_PERMISSIONS]
  }
];

const lddRoutes = [
  {
    component: lazy(() => import('./views/drugs/ldd/LddRelationships/LddRelationshipListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/ldd',
    permissions: [READ_LDD]
  },
  {
    component: lazy(() => import('./views/drugs/ldd/LddRelationships/LddRelationshipCreateView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/ldd/create',
    permissions: [CREATE_LDD]
  }
];

const swaggerRoutes = [
  {
    component: lazy(() => import('./views/swagger/SwaggerListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/swagger',
    permissions: [READ_SWAGGER]
  },
  {
    component: lazy(() => import('./views/swagger/SwaggerApiView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/swagger/:api',
    permissions: [READ_SWAGGER]
  }
];

const rptRoutes = [
  {
    component: lazy(() => import('./views/rpt/rpt-home')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/rpt',
    permissions: [MANAGE_RPT, READ_RPT]
  },
  {
    component: lazy(() => import('./views/rpt/orders/DoseOrderListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/orders',
    permissions: [MANAGE_RPT, READ_DOSE_ORDER, READ_ORDER_MANAGEMENT]
  },
  {
    component: lazy(() => import('./views/rpt/licenses/LicenseListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/RamLicenseDetails',
    permissions: [MANAGE_RPT, READ_RAM_LICENSE_DETAILS]
  },
  {
    component: lazy(() => import('./views/messages/MessagesListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/messages',
    permissions: [MANAGE_RPT, READ_MESSAGE]
  },
  {
    component: lazy(() => import('./views/rpt/doses/DoseListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/doses',
    permissions: [MANAGE_RPT, READ_DOSE]
  },
  {
    component: lazy(() => import('./views/rpt/batch-releases/BatchReleaseListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/batchReleases',
    permissions: [READ_BATCH_RELEASE]
  },
  {
    component: lazy(() => import('./views/rpt/ram-org-approvals/OrgRamApprovalView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/orgRamApproval',
    permissions: [MANAGE_RPT, READ_ORG_RAM_APPROVAL]
  }
];

const upickRoutes = [
  {
    component: lazy(() => import('./views/upick/PharmacyPreference')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/tymlos-pharmacy-preferences',
    permissions: [CREATE_UPICK]
  }
];

const vendorRoutes = [
  {
    component: lazy(() => import('./views/vendor')),
    exact: true,
    guard: FeatureGuard,
    layout: AnonymousLayout,
    path: '/enrollment',
    feature: 'UseAnonymousEnrollmentWorkflow'
  },
  {
    component: lazy(() => import('./views/vendor/VendorEnrollmentCompleteView')),
    exact: true,
    guard: FeatureGuard,
    layout: AnonymousLayout,
    path: '/enrollment/complete',
    feature: 'UseAnonymousEnrollmentWorkflow'
  }
];

export default [
  ...formManagerRoutes,
  ...patientRoutes,
  ...prescriberRoutes,
  ...enrollmentRoutes,
  ...accountManagementRoutes,
  ...boltRoutes,
  ...financialAssistanceRoutes,
  ...rolesAndPermsRoutes,
  ...lddRoutes,
  ...swaggerRoutes,
  ...rptRoutes,
  ...upickRoutes,
  ...vendorRoutes,
  {
    component: lazy(() => import('./views/home')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app'
  },
  {
    component: lazy(() => import('./views/notifications/NotificationListView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/notifications',
    permissions: [READ_NOTIFICATIONS]
  },
  {
    component: lazy(() => import('./views/notifications/UrgentNotificationConfigurationView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/notifications/urgentprefs'
  },
  {
    component: lazy(() => import('./views/support/SupportView')),
    exact: true,
    guard: AuthGuard,
    layout: AuthenticatedLayout,
    path: '/app/support'
  },
  {
    component: lazy(() => import('./components/Logout')),
    exact: true,
    path: '/logout'
  },
  {
    component: () => <Navigate to="/app" replace />,
    path: '*'
  }
];

export const renderRoutes = (configuredRoutes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {configuredRoutes.map(
        ({
          component,
          exact,
          guard,
          layout,
          path,
          permissions,
          requireAllPermissions,
          feature
        }) => {
          const Guard = guard ?? Fragment;
          const Layout = layout ?? Fragment;
          const Component = component;
          const guardProps = {};
          if (guard) {
            if (permissions?.length) {
              guardProps.permissions = permissions;
            }

            if (!isNil(requireAllPermissions)) {
              guardProps.requireAllPermissions = requireAllPermissions;
            }

            if (feature) {
              guardProps.feature = feature;
            }
          }

          return (
            <Route
              element={(
                <Guard {...guardProps}>
                  <Layout>
                    <Component />
                  </Layout>
                </Guard>
              )}
              exact={exact}
              key={path + layout}
              path={path}
            />
          );
        }
      )}
    </Routes>
  </Suspense>
);
