import React from 'react';
import PropTypes from 'prop-types';
import { homePath } from '../../constants/paths';

const Logo = ({ selectedBranding, isCoBranding = false }) => (selectedBranding ? (
    <a href={homePath()}>
      <img
        alt={
          (isCoBranding
            ? `${selectedBranding.coBrandingDisplayName}`
            : `${selectedBranding.displayName}`) + 'Logo'
        }
        src={
          isCoBranding
            ? selectedBranding?.coBrandingLogo
            : selectedBranding.logo
        }
        width="220px"
      />
    </a>
) : (
    <></>
));

Logo.propTypes = {
  selectedBranding: PropTypes.object,
  isCoBranding: PropTypes.bool
};

export default Logo;
