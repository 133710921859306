import React from 'react';

const RxLightningLogo = (props) => (
  <img
    alt="RxLightningLogo"
    src="https://cdn.rxlightning.com/images/text_logo.svg"
    width="220px"
    {...props}
  />
);

export default RxLightningLogo;
