export const EMBEDDED_PORTAL = 'embedded:portal';
export const SIGN_BAA = 'sign:baa';

export const PHARMACIST = 'pharmacist';

export const READ_BOLT = 'read:bolt';
export const READ_RADIUS = 'read:radius';

export const BYPASS_PREREGISTERED_PATIENT = 'bypass:preregistered-patient';
export const BYPASS_PREREGISTERED_PRESCRIBER =
  'bypass:preregistered-prescriber';

export const CREATE_PATIENT = 'create:patient';
export const READ_PATIENT = 'read:patient';
export const READ_PATIENT_TRAINING = 'read:patient-training';
export const READ_PATIENT_SHIPMENTS = 'read:patient-shipments';
export const UPDATE_PATIENT = 'update:patient';
export const DELETE_PATIENT = 'delete:patient';

export const CREATE_PRESCRIBER = 'create:prescriber';
export const READ_PRESCRIBER = 'read:prescriber';
export const UPDATE_PRESCRIBER = 'update:prescriber';
export const DELETE_PRESCRIBER = 'delete:prescriber';
export const UPDATE_PRESCRIBER_QUANTITY = 'update:prescriber-quantity';
export const INITIATE_PRESCRIBER_VERIFICATION =
  'initiate:prescriber-verification';

export const CREATE_DESTINATION = 'create:destination';
export const READ_DESTINATION = 'read:destination';
export const UPDATE_DESTINATION = 'update:destination';
export const DELETE_DESTINATION = 'delete:destination';

export const CREATE_DRUG = 'create:drug';
export const READ_DRUG = 'read:drug';
export const UPDATE_DRUG = 'update:drug';
export const DELETE_DRUG = 'delete:drug';

export const CREATE_FOUNDATION = 'create:foundation';
export const READ_FOUNDATION = 'read:foundation';
export const CREATE_MAQ = 'create:maq';
export const CREATE_PA = 'create:pa';
export const CREATE_PRICING = 'create:pricing';
export const READ_PRICING = 'read:pricing';

export const CREATE_CASE = 'create:case';
export const READ_CASE = 'read:case';
export const UPDATE_CASE = 'update:case';
export const DELETE_CASE = 'delete:case';
export const CREATE_FAX_NOTIFICATION = 'create:fax-notification';
export const CLOSE_CASE = 'close:case';
export const REOPEN_CASE = 'reopen:case';
export const UPDATE_CASE_STATUS = 'update:case-status';

export const READ_CASE_DASHBOARD = 'read:case-dashboard';
export const READ_CASE_AUDIT = 'read:case-audit';
export const READ_CASE_OVERVIEW = 'read:case-overview';
export const READ_CASE_MISSING_INFORMATION = 'read:case-missing-information';
export const READ_CASE_FAX_INTAKE = 'read:case-intake';
export const READ_CASE_BENEFITS_INVESTIGATION =
  'read:case-benefits-investigation';
export const READ_CASE_PRIOR_AUTHORIZATION = 'read:case-prior-authorization';
export const READ_CASE_CLAIMS_AND_APPEALS = 'read:case-claims-and-appeals';
export const READ_CASE_PAP = 'read:case-pap';
export const READ_CASE_PAP_APPEALS = 'read:case-pap-appeals';
export const READ_CASE_COPAY_ASSISTANCE = 'read:case-copay-assistance';
export const READ_CASE_ALTERNATIVE_FUNDING = 'read:case-alternative-funding';
export const READ_CASE_FIRST_PRODUCT_SUPPORT =
  'read:case-first-product-support';
export const READ_CASE_PRODUCT_REPLACEMENT = 'read:case-product-replacement';
export const READ_CASE_SUMMARY_OF_BENEFITS = 'read:case-summary-of-benefits';

export const CREATE_CASE_NOTE = 'create:case-note';
export const READ_CASE_NOTE = 'read:case-note';
export const UPDATE_CASE_NOTE = 'update:case-note';
export const DELETE_CASE_NOTE = 'delete:case-note';

export const CREATE_ENROLLMENT = 'create:enrollment';
export const CREATE_ENROLLMENT_OFFICE_CONTACT =
  'create:enrollment-office-contact';
export const READ_ENROLLMENT = 'read:enrollment';

export const AUTOCOMPLETE_ENROLLMENT = 'autocomplete:testEnrollment';
export const READ_COMPLETE_ENROLLMENT = 'read:complete-enrollment';
export const UPDATE_ENROLLMENT = 'update:enrollment';
export const DELETE_ENROLLMENT = 'delete:enrollment';
export const DUPLICATE_ENROLLMENT = 'duplicate:enrollment';
export const UPDATE_ENROLLMENT_STATUS = 'update:enrollmentStatus';
export const UPDATE_ENROLLMENT_STATUS_ANY = 'update:enrollment-status-any';
export const CREATE_ADDITIONAL_FAX = 'create:additionalRecipFax';

export const COMMUNICATION_CONSENT = 'communication:consent';

export const READ_SWAGGER = 'read:swagger';
export const BYPASS_TIMEOUT = 'bypass:session-timeout';

export const READ_FILE_INTAKE = 'read:file-intake';

export const FORMIFIER_VIEW = 'formifier:viewPages';
export const FORMIFIER_MANAGE_FORM = 'formifier:manageForm';
export const FORMIFIER_MANAGE_QUESTIONS = 'formifier:manageQuestions';
export const FORMIFIER_MANAGE_MAPPINGS = 'formifier:manageMappings';
export const FORMIFIER_ADMIN_FORM = 'formifier:adminForm';
export const FORMIFIER_ADMIN_QUESTIONS = 'formifier:adminQuestions';
export const FORMIFIER_ADMIN_MAPPINGS = 'formifier:adminMappings';
export const FORMIFIER_GOD_MODE = 'formifier:godMode';

export const UPLOAD_SIGNATURE = 'upload:signature';

export const READ_LDD = 'read:ldd';
export const CREATE_LDD = 'create:ldd';
export const UPDATE_LDD = 'update:ldd';
export const DELETE_LDD = 'delete:ldd';

export const SELECT_ORGANIZATION_CONTEXT = 'select:organization-context';

export const CREATE_WET_SIGNATURE = 'create:wet-signature-baa';
export const READ_WET_SIGNATURE = 'read:wet-signature-baa';

export const CREATE_USER = 'create:user';
export const READ_USER = 'read:user';
export const UPDATE_USER = 'update:user';
export const DELETE_USER = 'delete:user';
export const VERIFY_USER = 'verify:user';
export const READ_NOTIFICATIONS = 'read:notifications';

export const CREATE_PRESCRIBERLESS_ENROLLMENT = 'prescriberless:enrollment';
export const CREATE_PATIENTLESS_ENROLLMENT = 'patientless:enrollment';

export const USERLESS_PRESCRIBER = 'userless:prescriber';

export const INSURANCE_SPENDOWN = 'insurance-spenddown';

export const CREATE_CALL = 'call:create';

export const READ_PHI = 'read:phi';

export const READ_COMPLIANCE = 'read:compliance';

export const READ_CALL_SCRIPT = 'read:call-script';

export const PRINT_PATIENT_CONSENT = 'print:patient-consent';

export const VIEW_ACCOUNT_MANAGEMENT = 'account-management:view';
export const MANAGE_USERS = 'account-management:manage-users';
export const MANAGE_PROVIDERS = 'account-management:manage-providers';
export const MANAGE_PROVIDER_SIGNATURES =
  'account-management:manage-provider-signatures';
export const MANAGE_GROUPS = 'account-management:manage-groups';
export const MANAGE_ROLES = 'account-management:manage-roles';
export const MANAGE_PERMISSIONS = 'account-management:manage-permissions';
export const MANAGE_PENDING_APPROVALS = 'account-management:pending-approvals';
export const MANAGE_PARENT_ORGANIZATIONS = 'account-management:parent-organizations';

export const MANAGE_ALL_ROLES = 'account-management:manage-all-roles';

export const CREATE_NEWRX = 'create:newrx';

export const READ_MEDACCESS = 'read:medaccess';
export const READ_MAQ_PREMIUM = 'read:maq-premium';

export const CREATE_UPICK = 'create:upickenrollment';

export const CREATE_PATIENT_NOTE = 'create:patientNote';
export const CREATE_ADVERSE_EVENT = 'create:adverse-event';
export const CREATE_GENERAL_INQUIRY = 'create:general-inquiry';

export const MANAGE_RPT = 'manage:rlt';
export const READ_ORDER_MANAGEMENT = 'read:supplyCloud';
export const CREATE_ORDER_MANAGEMENT = 'create:supplyCloud';
export const UPDATE_NUMBER_PRODUCED = 'update:numDosesProduced';
export const UPDATE_NUMBER_CONFIRMED = 'update:numDosesConfirmed';
export const READ_DOSE = 'read:dose';
export const READ_DOSE_ORDER = 'read:doseOrder';
export const CREATE_DOSE_ORDER = 'create:doseOrder';
export const READ_ORDER_SET = 'read:orderSet';
export const READ_ORDER_SET_DASHBOARD = 'read:orderSetDashboard';
export const CREATE_ORDER_SET = 'create:orderSet';
export const UPDATE_ORDER_SET = 'update:orderSet';
export const READ_SECURE_INBOX = 'read:secureInbox';
export const READ_RPT = 'read:rlt';
export const UPDATE_RPT = 'update:rlt';
export const READ_RAM_LICENSE_DETAILS = 'read:ramLicenseDetails';
export const CREATE_RAM_LICENSE_DETAILS = 'create:ramLicenseDetails';
export const UPDATE_RAM_LICENSE_DETAILS = 'update:ramLicenseDetails';
export const DELETE_RAM_LICENSE_DETAILS = 'delete:ramLicenseDetails';
export const READ_WAITLIST = 'read:waitlist';
export const UPDATE_WAITLIST = 'update:waitlist';
export const READ_BATCH_RELEASE = 'read:batchRelease';
export const CREATE_BATCH_RELEASE = 'create:batchRelease';
export const READ_URGENT_NOTIFICATIONS = 'read:urgentNotifications';
export const READ_ORG_RAM_APPROVAL = 'read:orgRamApproval';
export const UPDATE_ORG_RAM_APPROVAL = 'update:orgRamApproval';
export const READ_RPT_HIDDEN_FEATURES = 'read:rlt-hidden-features';

export const DISABLE_CSV_DOWNLOAD = 'disable:csvDownload';

export const AUTHORIZE_CONTACT = 'authorize:contact';
export const CREATE_CONTACT = 'create:contact';
export const READ_CONTACT = 'read:contact';
export const UPDATE_CONTACT = 'update:contact';
export const DELETE_CONTACT = 'delete:contact';

export const READ_MESSAGE = 'read:message';
export const CREATE_MESSAGE = 'create:message';
export const DELETE_MESSAGE = 'delete:message';

export const INVITE_USER = 'invite:user';
export const INVITE_PATIENT = 'invite:patient';
export const INVITE_PRESCRIBER = 'invite:prescriber';

export const DEVELOPER = 'read:developer';
export const READ_DATA_GRID = 'read:data-grid';

export const UPDATE_PREFERENCES = 'update:preferences';
export const UPDATE_NOTIFICATION_PREFERENCES = 'update:notificationPreferences';
export const UPDATE_UPICK_PREFERENCES = 'update:upickPreferences';
export const UPDATE_ORGANIZATION_PREFERENCES = 'update:organizationPreferences';

export const UPDATE_TABLE_FOOTER_DATES = 'update:table-footer-dates';

export const READ_HIDDEN_CASES = 'read:hiddenCases';
export const UPDATE_HIDDEN_CASES = 'update:hiddenCases';

export const UPDATE_ENROLLMENT_AFFORDABILITY_OUTCOME = 'update:enrollment-affordability-outcome';

export const PRESCRIBER_AGENT_ADMIN = 'prescriber-agent:admin';
export const SUBMIT_ENROLLMENT_UNVERIFIED_PRESCRIBER = 'UnverifiedPrescriberNoBAAFeature';
