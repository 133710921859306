import axios from 'axios';

const questionApi = (apiConfig) => {
  const listFormQuestions = (formId) => axios.get(`/v1/formQuestions/${formId}`, apiConfig);

  const insertGeneralFormQuestions = (formId) => axios.post(`/v1/formQuestions/${formId}`, {}, apiConfig);

  const updateFormQuestionDetails = (formId, updatedQuestions) => axios.put(
    `v1/formQuestions/${formId}`,
    updatedQuestions,
    apiConfig
  );

  const listBaseParentQuestionsForFormOrg = (formId) => axios.get(`/v1/allQuestions/${formId}`, apiConfig);

  const listBaseParentQuestionsForUserOrg = () => axios.get('/v1/allQuestions/userOrg', apiConfig);

  const listGeneralFormQuestionsForUserOrg = () => axios.get('/v1/allQuestions/generalForm', apiConfig);

  const updateGeneralFormQuestionDisplay = (questions) => axios.put('/v1/allQuestions/generalForm', questions, apiConfig);

  const changeIsGeneralFormStatus = (generalFormData) => axios.put(
    '/v1/allQuestions',
    generalFormData,
    apiConfig
  );

  const insertFormQuestion = (formQuestion) => axios.post('/v1/formQuestion', formQuestion, apiConfig);

  const editFormQuestion = (formQuestion) => axios.put('v1/formQuestion', formQuestion, apiConfig);

  const deleteFormQuestion = (formQuestionId) => axios.delete(`/v1/formQuestion/${formQuestionId}`, apiConfig);

  const getBaseQuestionChildren = (masterQuestionId) => axios.get(`/v1/allQuestion/${masterQuestionId}`, apiConfig);

  const saveNewBaseQuestion = (baseQuestion) => axios.post('/v1/allQuestion', baseQuestion, apiConfig);

  const saveEditedBaseQuestion = (baseQuestion) => axios.put('/v1/allQuestion', baseQuestion, apiConfig);

  const archiveBaseQuestion = (masterQuestionId) => axios.delete(`/v1/allQuestion/${masterQuestionId}`, apiConfig);

  const getQuestionCategories = () => axios.get('/v1/formCategories', apiConfig);

  const getDuplicateQuestionBranches = (masterQuestionId) => axios.get(`/v1/duplicateQuestion/${masterQuestionId}`, apiConfig);

  const mergeDuplicates = (duplicateData) => axios.post('/v1/duplicateQuestion', duplicateData, apiConfig);

  return {
    listFormQuestions,
    insertGeneralFormQuestions,
    updateFormQuestionDetails,
    listBaseParentQuestionsForFormOrg,
    listBaseParentQuestionsForUserOrg,
    listGeneralFormQuestionsForUserOrg,
    updateGeneralFormQuestionDisplay,
    changeIsGeneralFormStatus,
    insertFormQuestion,
    editFormQuestion,
    deleteFormQuestion,
    getBaseQuestionChildren,
    saveNewBaseQuestion,
    saveEditedBaseQuestion,
    archiveBaseQuestion,
    getQuestionCategories,
    getDuplicateQuestionBranches,
    mergeDuplicates
  };
};

export default questionApi;
