import React from 'react';
import {
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
  Autocomplete
} from '@mui/material';
import { InlineIcon } from '@iconify/react';
import useAuth from '../../../hooks/useAuth';
import { Info } from '../../../constants/icons';

const OrganizationSelector = () => {
  const {
    parentOrganizations,
    selectedParentOrganization,
    updateSelectedParentOrganization,
    user
  } = useAuth();
  const theme = useTheme();

  const parentOrganizationOptions = parentOrganizations.map((org) => ({
    id: org?.parentOrganizationId,
    label: org.organizationName
  }));

  return (
    <Autocomplete
      color="secondary"
      id="parentOrgSelection"
      disableClearable
      autoHighlight
      onChange={(event, newOrg) => {
        updateSelectedParentOrganization(
          parentOrganizations.find(
            (org) => newOrg.id === org?.parentOrganizationId
          )
        );
      }}
      size="small"
      sx={{ width: '250px', textOverflow: 'ellipses' }}
      value={{
        id: selectedParentOrganization?.parentOrganizationId ?? '',
        label: selectedParentOrganization?.organizationName ?? ''
      }}
      options={parentOrganizationOptions}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Organization"
          InputProps={{
            ...params.InputProps,
            startAdornment:
              selectedParentOrganization?.parentOrganizationId !==
              user?.parentOrganization?.parentOrganizationId ? (
                <InputAdornment
                  position="start"
                  onClick={() => updateSelectedParentOrganization(user?.parentOrganization)}
                  sx={{
                    cursor: 'pointer',
                    marginLeft: '6px',
                    marginRight: '0px'
                  }}
                >
                  <Tooltip title="Currently acting on behalf of another organization! Click to reset!">
                    <InlineIcon
                      icon={Info}
                      color={theme.palette.secondary.main}
                    />
                  </Tooltip>
                </InputAdornment>
                ) : (
                <></>
                )
          }}
        />
      )}
    />
  );
};

export default OrganizationSelector;
