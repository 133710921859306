import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useSnackbar } from 'notistack';
import useAuth from '../../hooks/useAuth';

const PreferredCommunicationDialog = () => {
  const { updatePreferredCommunicationType } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true);
  const [
    preferredCommunicationType,
    setPreferredCommunicationType
  ] = useState();

  const handleSubmit = async () => {
    updatePreferredCommunicationType(preferredCommunicationType, () => {
      setOpen(false);
      enqueueSnackbar('Preferences saved.', {
        autoHideDuration: 3000,
        variant: 'success'
      });
    });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid container item alignItems="center" spacing={1}>
            <Grid item>
              <Typography component="span" variant="h3">
                Communication
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <DialogContentText variant="h4">
            Please select your preferred method of communication.
          </DialogContentText>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              onChange={(e) => setPreferredCommunicationType(e.target.value)}
            >
              <FormControlLabel
                value="email"
                control={<Radio />}
                label="Email"
              />
              <FormControlLabel
                value="portal"
                control={<Radio />}
                label="In App"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleSubmit}
          variant="contained"
          disabled={!preferredCommunicationType}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreferredCommunicationDialog;
