import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import GlobalStyles from './components/page/GlobalStyles';
import ScrollReset from './components/page/ScrollReset';
import { AuthProvider } from './context/AuthContext';
import 'react-sortable-tree-patch-react-17/style.css';
import routes, { renderRoutes } from './Routes';
import { NotificationsProvider } from './context/NotificationsContext';
import { BrandingProvider } from './context/BrandingContext';
import PortalThemeProvider from './theme/PortalThemeProvider';
import { EnvironmentProvider } from './context/EnvironmentContext';
import { LoggingProvider } from './context/LoggingContext';
import { RxlApiProvider } from './context/RxlApiContext';
import { FeatureProvider } from './context/FeatureContext';
import { RPTProvider } from './context/RPTContext';

const App = () => (
  <div>
    <EnvironmentProvider>
      <CookiesProvider>
        <BrowserRouter>
          <BrandingProvider>
            <PortalThemeProvider>
              <LoggingProvider>
                <SnackbarProvider dense maxSnack={10}>
                  <RxlApiProvider>
                    <FeatureProvider>
                      <AuthProvider>
                        <NotificationsProvider>
                          <RPTProvider>
                            <GlobalStyles />
                            <ScrollReset />
                            {renderRoutes(routes)}
                          </RPTProvider>
                        </NotificationsProvider>
                      </AuthProvider>
                    </FeatureProvider>
                  </RxlApiProvider>
                </SnackbarProvider>
              </LoggingProvider>
            </PortalThemeProvider>
          </BrandingProvider>
        </BrowserRouter>
      </CookiesProvider>
    </EnvironmentProvider>
  </div>
);

export default App;
