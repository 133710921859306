import axios from 'axios';

const statusApi = (apiConfig) => {
  const updateStatus = (status) => axios.put('/v1/statuses', status, apiConfig);

  const listStatusHistory = (request) => axios.get('/v1/statushistory', {
    ...apiConfig,
    params: request
  });

  const listAvailableStatuses = (request) => axios.get('/v1/statuses', {
    ...apiConfig,
    params: request
  });

  return {
    updateStatus,
    listStatusHistory,
    listAvailableStatuses
  };
};

export default statusApi;
