import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {
  byDrugIncluded,
  byNameIncluded,
  byReferralCode
} from '../theme/user-branding';
import useEnvironment from '../hooks/useEnvironment';

const BrandingContext = createContext(null);

export const BrandingProvider = ({ children }) => {
  const { AUTH_COOKIE_NAME } = useEnvironment();
  const [cookies, setCookie] = useCookies([AUTH_COOKIE_NAME]);
  const { search } = useLocation();

  const [state, dispatch] = useReducer(
    (internalState, action) => {
      switch (action.type) {
        case 'INIT': {
          return action.payload;
        }
        default: {
          return internalState;
        }
      }
    },
    null,
    () => null
  );

  const parseBrandingKeyFromCookie = () => {
    const authCookie = decodeURI(
      cookies[AUTH_COOKIE_NAME]
    );
    return authCookie ? new URLSearchParams(authCookie).get('branding') : null;
  };

  const getByDrug = () => {
    const hostname = window.location.hostname;
    const urlSearchParams = new URLSearchParams(search);
    return (
      byDrugIncluded(urlSearchParams?.get('referralDrug')) ??
      byDrugIncluded(hostname) ??
      byDrugIncluded(urlSearchParams?.get('redirectUrl'))
    );
  };

  const getByName = () => {
    const hostname = window.location.hostname;
    const urlSearchParams = new URLSearchParams(search);
    return (
      byNameIncluded(parseBrandingKeyFromCookie()) ??
      byNameIncluded(hostname) ??
      byNameIncluded(urlSearchParams?.get('redirectUrl'))
    );
  };

  const clear = () => {
    dispatch({
      type: 'INIT',
      payload: {
        ...state,
        redirectUrl: null
      }
    });
  };

  useEffect(() => {
    const selectedBranding =
      byReferralCode(new URLSearchParams(search).get('referralCode')) ??
      getByDrug() ??
      getByName() ??
      byNameIncluded('RxLightning');

    const referralCode = new URLSearchParams(search).get('referralCode');
    const authCookie = decodeURI(
      cookies[AUTH_COOKIE_NAME]
    );

    if (referralCode && authCookie !== 'undefined') {
      const newCookie = authCookie.replace(
        `branding=${new URLSearchParams(authCookie).get('branding')}`,
        `branding=${selectedBranding.name}`
      );
      setCookie(AUTH_COOKIE_NAME, newCookie);
    }

    dispatch({
      type: 'INIT',
      payload: selectedBranding
    });
  }, []);

  useEffect(() => {
    if (state?.faviconSrc) {
      const favicon = document.getElementById('favicon');
      favicon.setAttribute('href', state?.faviconSrc);
    }
  }, [state?.faviconSrc]);

  return (
    <BrandingContext.Provider
      value={{
        ...state,
        clear
      }}
    >
      {state ? children : <></>}
    </BrandingContext.Provider>
  );
};

BrandingProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BrandingContext;
