import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';
import useRxlApis from '../hooks/useRxlApis';

const FeatureContext = createContext(null);

export const FeatureProvider = ({ children }) => {
  const { getFeatures } = useRxlApis();
  const [state, dispatch] = useReducer(
    (internalState, action) => {
      switch (action.type) {
        case 'INIT': {
          return action.payload;
        }
        default: {
          return internalState;
        }
      }
    },
    null,
    () => null
  );

  useEffect(() => {
    getFeatures()
      .then((response) => {
        if (response?.data) {
          dispatch({
            type: 'INIT',
            payload: {
              ...state,
              features: response.data
            }
          });
        }
      });
  }, []);

  const featureEnabled = (featureName) => state?.features && toLower(state.features[featureName]) === 'true';

  return (
    <FeatureContext.Provider
      value={{
        ...state,
        featureEnabled
      }}
    >
      {state ? children : <></>}
    </FeatureContext.Provider>
  );
};

FeatureProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FeatureContext;
