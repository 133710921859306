export const Onco360PharmacyId = 'a8c61cb6-4c6d-4974-bb91-62fbeae64b05';
export const HumanaPharmacyId = '40984f93-ed37-461c-810e-33b075265bdd';
export const CenterwellPharmacyId = '2731037b-500e-4e29-8f2e-3f436ebbad55';
export const WalgreensPharmacyId = '0ef4eada-3dca-4df5-a6e3-67990d62fecc';
export const BioPlusPharmacyId = '80759297-5cdb-49ce-bd7d-bd29755584a4';
export const OptimePharmacyId = '2bbc6541-c2a5-43e0-9af8-f44ae29f01b4';
export const FountainRxPharmacyId = 'f77f156b-045a-404d-adac-4a42d949af05';
export const ElectronicPriorAuthorizationPharmacyId =
  'c4dbd3a0-6c1e-4589-af07-c8a54c4a962e';
export const PaperPriorAuthorizationPharmacyId =
  'c5704e9e-aa6b-4bfc-88a8-b3165ea2ebc1';
export const RxLightningTestFormsPharmacyId =
  '64e6da46-9b1f-43eb-b44f-bc8360aaeb8a';
export const MedAccessQueuePharmacyId = '019366bd-784d-4543-92d6-7f39b8897169';
export const PreMedAccessQueuePharmacyId =
  'd9705a55-d153-4181-958b-469373f03cf6';
export const HubPharmacyId = 'c41da8ed-0e83-49f5-994b-5dfdb491a8a4';
export const PapPharmacyId = '15ce6ccc-9121-4740-adcc-02bb7c5337e1';
export const PatientServicesPharmacyId = 'cdd6a2d0-fd63-497e-9196-ce8f790eb333';
export const MerckPapPharmacyId = '4d248909-c828-4941-bada-7866219fff00';
export const HubAndPapPharmacyId = 'd90f52ff-c2d5-4dc9-b026-27ce78cef931';
export const A4MePapPharmacyId = 'c141fa07-ea58-4061-a222-97695dd36f9c';
export const A4MePapAppealPharmacyId = '8d24f126-e5fa-48e6-a1bd-d2fe17184ea4';
export const Access4MeHubPharmacyId = '5aeed139-d309-4a94-a33c-194baf27feb1';
export const CvsPharmacyId = '168658b9-d78a-4c35-9aab-346efb2a4826';
export const AdverseEventPharmacyId = '2df6b825-8d9b-461f-8aad-7ba1d6dded08';
export const GeneralInquiryPharmacyId = 'baee8bbd-a875-47f9-a619-d421a1ae9ff6';
export const RolvedonIntakePharmacyId = '36ca49a6-52bc-4ae0-b30e-d444b5eb453b';
export const Access4MePriorAuthPharmacyId =
  'd74f1d22-a987-4b79-b964-3c755525e705';
export const CheckPatientBenefitsPharmacyId =
  '6a66a4ec-9f53-46bc-b0a7-db94f55d251b';
export const PatientConsentPharmacyId = '1A1286B2-A945-48E4-B675-34A556AF3BAC';
