import axios from 'axios';

const foundationsApi = (apiConfig) => {
  const listFoundations = () => axios.get('/v1/foundations/programs', apiConfig);

  const listFoundationContactInfo = () => axios.get('/v1/foundations/contactInfo', apiConfig);

  const createFoundationOutcome = (payload) => axios.post('/v1/foundations/outcome', payload, apiConfig);

  const updateFoundationOutcome = (payload) => axios.put('/v1/foundations/outcome', payload, apiConfig);

  return {
    listFoundations,
    listFoundationContactInfo,
    createFoundationOutcome,
    updateFoundationOutcome
  };
};

export default foundationsApi;
