import axios from 'axios';
import { RxLightningTestFormsPharmacyId } from '../constants/pharmacies';

const caseApi = (apiConfig) => {
  const caseEmbeds = [
    'patient',
    'prescriber',
    'drug',
    'pharmacy',
    'user',
    'enrollment'
  ];

  const listCaseAudit = (caseId) => axios.get(`/v1/case/${caseId}/auditLogs`, apiConfig);

  const listCaseNotes = (caseId) => axios.get(`/v1/case/${caseId}/notes`, apiConfig);

  const addCaseNote = (caseId, note) => axios.post(`/v1/case/${caseId}/notes`, note, apiConfig);

  const addFaxNotification = (caseId, notificationType) => axios.post(`/v1/case/${caseId}/faxnotification`, notificationType, apiConfig);

  const createCase = (
    patientId,
    prescriberId,
    drugId,
    pharmacyId,
    caseOwnerUserId
  ) => axios.post('/v1/case', {
    patientId,
    prescriberId,
    drugId,
    pharmacyId: pharmacyId ?? RxLightningTestFormsPharmacyId,
    caseOwnerUserId
  }, apiConfig);

  const listCases = (organizationId, pageSize = 2000, index = 0) => axios.get('/v1/casedashboard', {
    ...apiConfig,
    params: {
      index,
      pageSize,
      organizationId,
      embeds: caseEmbeds.join(',')
    }
  });

  const createCaseEnrollment = (caseId, categoryWeightId, enrollment) => axios.post(
    `/v1/caseenrollment/${caseId}/enrollment/${categoryWeightId}`,
    enrollment,
    apiConfig
  );

  const closeCase = (caseId, reason) => axios.put(`/v1/case/${caseId}`, { close: true, message: reason }, apiConfig);

  const reopenCase = (caseId, reason) => axios.put(`/v1/case/${caseId}`, { reopen: true, message: reason }, apiConfig);

  const archiveCase = (caseId) => axios.put(`/v1/case/${caseId}`, { archive: true, message: 'Archive Case' }, apiConfig);

  return {
    listCaseAudit,
    listCaseNotes,
    addCaseNote,
    addFaxNotification,
    createCase,
    listCases,
    createCaseEnrollment,
    closeCase,
    reopenCase,
    archiveCase
  };
};

export default caseApi;
