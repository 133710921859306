import axios from 'axios';
import { createUploadData, formDataConfig } from '../constants/document-upload';

const attachmentApi = (apiConfig, apiConfigNoAuthToken) => {
  const getAttachment = (attachmentId) => axios.get(`/v1/attachment/${attachmentId}`, apiConfig);

  const getAttachmentTypes = () => axios.get('/v1/attachmentTypes', apiConfig);

  const getNoAuthAttachment = (attachmentId) => axios.get(`/v1/attachment/nologin/${attachmentId}`, apiConfigNoAuthToken);

  const createAttachment = (attachment) => axios.post('/v1/attachment', attachment, apiConfig);

  const createNoAuthAttachment = (attachment) => axios.post('/v1/attachment/verification', attachment, apiConfigNoAuthToken);

  const downloadAttachment = (attachmentId) => axios.get(`/v1/document/${attachmentId}`, {
    ...apiConfig,
    responseType: 'blob'
  });

  const downloadNoAuthAttachment = (attachmentId) => axios.get(`/v1/document/nologin/${attachmentId}`, {
    ...apiConfig,
    responseType: 'blob'
  });

  const downloadTestEnrollment = (testEnrollmentId) => axios.get(`/v1/testEnrollment/download/${testEnrollmentId}`, {
    ...apiConfig,
    responseType: 'blob'
  });

  const uploadAttachment = (file) => axios.post('/v1/document', createUploadData(file), {
    ...formDataConfig,
    ...apiConfig
  });

  const uploadNoAuthAttachment = (file) => axios.post('/v1/document/nologin', createUploadData(file), {
    ...formDataConfig,
    ...apiConfig
  });

  const updateAttachment = (attachmentId, attachment) => axios.put(`/v1/attachment/${attachmentId}`, attachment, apiConfig);

  return {
    getAttachment,
    getAttachmentTypes,
    getNoAuthAttachment,
    createAttachment,
    createNoAuthAttachment,
    downloadAttachment,
    downloadNoAuthAttachment,
    downloadTestEnrollment,
    uploadAttachment,
    uploadNoAuthAttachment,
    updateAttachment
  };
};

export default attachmentApi;
