import axios from 'axios';

const notificationApi = (apiConfig) => {
  const listNotifications = (organizationId) => axios.get('/v1/UserNotifications', {
    ...apiConfig,
    params: {
      organizationId
    }
  });

  const listDoseOrdersForNotification = (
    organizationId,
    notificationId,
    startDate,
    endDate
  ) => axios.get(`/v1/DoseOrders/${organizationId}/notification/${notificationId}`, {
    ...apiConfig,
    params: {
      start: startDate,
      finish: endDate
    }
  });

  const archiveNotification = (notificationId) => axios.delete(`/v1/Notifications/${notificationId}`, apiConfig);

  const setOrganizationUrgentNotificationsPreferences = (
    organizationId,
    preferences
  ) => axios.put(`/v1/preferences/organization/${organizationId}`, {
    data: preferences
  }, apiConfig);

  const listOrganizationUrgentNotificationsPreferences = (
    organizationId
  ) => axios.get(`/v1/preferences/organization/${organizationId}`, apiConfig);

  return {
    listNotifications,
    listDoseOrdersForNotification,
    archiveNotification,
    setOrganizationUrgentNotificationsPreferences,
    listOrganizationUrgentNotificationsPreferences
  };
};

export default notificationApi;
