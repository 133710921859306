import axios from 'axios';
import { createUploadData, formDataConfig } from '../constants/document-upload';

const formApi = (apiConfig) => {
  const getForm = (formId) => axios.get(`/v1/form/${formId}`, apiConfig);

  const getAncestries = (formId) => axios.get(`/v1/form/${formId}/questionancestries`, apiConfig);

  const getFormProperties = (formId) => axios.get(`/v1/form/${formId}/properties`, apiConfig);

  const listDestinationsForForm = (formId) => axios.get(`/v1/form/${formId}/pharmacies`, apiConfig);

  const listDrugsForForm = (formId) => axios.get(`/v1/form/${formId}/drugs`, apiConfig);

  const listTaxonomiesForForm = (formId) => axios.get(`v1/form/${formId}/taxonomies`, apiConfig);

  const duplicateFormData = (formId) => axios.post(`/v1/form/duplicate/${formId}`, {}, apiConfig);

  const uploadFormTemplate = (file) => axios.post('/v1/formTemplate', createUploadData(file), {
    ...formDataConfig,
    ...apiConfig
  });

  const createFormNoTemplate = (formName) => axios.post('/v1/form', { formName }, apiConfig);

  const updateFormTemplate = (formId, file) => axios.put(
    `/v1/formTemplate/${formId}`,
    createUploadData(file),
    {
      ...formDataConfig,
      ...apiConfig
    }
  );

  const removeFormTemplate = (formData) => axios.put('v1/formTemplate/removeTemplate', formData, apiConfig);

  const updatedTemplatePageCountCheck = (formId, file) => axios.post(
    `/v1/formTemplate/pageCountCheck/${formId}`,
    createUploadData(file),
    {
      ...formDataConfig,
      ...apiConfig
    }
  );

  const updateForm = (formId, form) => axios.put(`/v1/form/${formId}`, form, apiConfig);

  const listForms = () => axios.get('/v1/forms', {
    ...apiConfig,
    params: { pageSize: 3000 }
  });

  const listFormsForQuestionId = (masterQuestionId) => axios.get(`/v1/forms/${masterQuestionId}`, apiConfig);

  const viewForm = (formId) => axios.get(`v1/formTemplate/${formId}`, {
    ...apiConfig,
    responseType: 'blob'
  });

  const getFormDetails = (formId) => axios.get(`/v1/formDetails/${formId}`, apiConfig);

  const searchFormDetails = (enrollment) => axios.post('/v1/formDetails/search', enrollment, apiConfig);

  const updateFormDetails = (formDetails) => axios.put('/v1/formDetails', formDetails, apiConfig);

  const validateFormDetails = (formDetails) => axios.put('/v1/formDetails/validate', formDetails, apiConfig);

  const listActiveFormsWithSameAssociations = (formId) => axios.get(`/v1/formSearch/multiple/${formId}`, apiConfig);

  const initiateSmartFormIntake = (formId) => axios.post(`/v1/formintake/${formId}`, {}, apiConfig);

  const getFormOrganizationForUser = () => axios.get('/v1/formOrganization', apiConfig);

  const listFormOrganizations = () => axios.get('/v1/formOrganization/all', apiConfig);

  const getFormOrgReassignOptionForQuestion = (questionId) => axios.get(`/v1/questionOrganization/newOrgOption/${questionId}`, apiConfig);

  const reassignFormOrgForQuestion = (questionId, newOrg) => axios.put(`/v1/questionOrganization/reassign/${questionId}`, newOrg, apiConfig);

  const listFormNotes = (formId) => axios.get(`/v1/formDetails/${formId}/notes`, apiConfig);

  const createFormNote = (formId, formNote) => axios.post(`/v1/formDetails/${formId}/notes`, formNote, apiConfig);

  const archiveFormNote = (noteId) => axios.delete(`/v1/formDetails/notes/${noteId}`, apiConfig);

  return {
    getForm,
    getAncestries,
    getFormProperties,
    listDestinationsForForm,
    listDrugsForForm,
    listTaxonomiesForForm,
    duplicateFormData,
    uploadFormTemplate,
    createFormNoTemplate,
    updateFormTemplate,
    removeFormTemplate,
    updatedTemplatePageCountCheck,
    updateForm,
    listForms,
    listFormsForQuestionId,
    viewForm,
    getFormDetails,
    searchFormDetails,
    updateFormDetails,
    validateFormDetails,
    listActiveFormsWithSameAssociations,
    initiateSmartFormIntake,
    getFormOrganizationForUser,
    listFormOrganizations,
    getFormOrgReassignOptionForQuestion,
    reassignFormOrgForQuestion,
    listFormNotes,
    createFormNote,
    archiveFormNote
  };
};

export default formApi;
