import React from 'react';
import { AppBar, Grid, Toolbar } from '@mui/material';
import { useTheme } from '@mui/styles';
import useBranding from '../../../hooks/useBranding';
import Logo from '../../../components/logos/Logo';
import { YaleReferral } from '../../../theme/user-branding';

const TopBar = () => {
  const theme = useTheme();
  const branding = useBranding();

  const toolbarContent =
    branding?.name === YaleReferral ? (
      <Grid
        container
        width="100%"
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Grid item>
          <Logo selectedBranding={branding} />
        </Grid>
        <Grid item mt={1} mb={1}>
          <Logo selectedBranding={branding} isCoBranding />
        </Grid>
      </Grid>
    ) : (
      <Logo selectedBranding={branding} />
    );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        backgroundColor: theme.palette.header.default
      }}
    >
      <Toolbar>{toolbarContent}</Toolbar>
    </AppBar>
  );
};

export default TopBar;
