import axios from 'axios';

const serviceApi = (apiConfig) => {
  const listServices = () => axios.get('/v1/organization/services', apiConfig);

  const listOrgServices = (orgId) => axios.get(`/v1/organization/${orgId}/services`, apiConfig);

  const updateOrgServiceStatus = (orgId, serviceId, status, licenseId = null) => axios.put(
    `/v1/organization/${orgId}/service/${serviceId}?status=${status}`,
    {},
    {
      ...apiConfig,
      params: {
        licenseId
      }
    }
  );

  return {
    listServices,
    listOrgServices,
    updateOrgServiceStatus
  };
};

export default serviceApi;
