import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { InlineIcon } from '@iconify/react/dist/iconify';
import { Close, Help } from '../../constants/icons';
import useAuth from '../../hooks/useAuth';

const SessionTimeoutDialog = ({ onClose, open }) => {
  const theme = useTheme();
  const { logout } = useAuth();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item alignItems="center">
            <InlineIcon icon={Help} width="20px" />
            &nbsp;
            <Typography component="span" variant="h3">
              Session Timeout
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Close">
              <IconButton onClick={onClose}>
                <InlineIcon color={theme.palette.tertiary.main} icon={Close} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <DialogContentText variant="h4">
            You are being logged out due to inactivity. Do you want to stay
            logged in?
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          fullWidth
          onClick={logout}
          size="large"
          variant="outlined"
        >
          Logout
        </Button>
        <Button
          color="secondary"
          fullWidth
          onClick={onClose}
          size="large"
          variant="contained"
        >
          Stay Logged In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SessionTimeoutDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default SessionTimeoutDialog;
