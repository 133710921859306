import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const SplashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      padding: 'auto',
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    }}
  >
    <Box width={400}>
      <LinearProgress color="secondary" />
    </Box>
  </Box>
);

export default SplashScreen;
