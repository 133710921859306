import axios from 'axios';

const preferencesApi = (apiConfig) => {
  const getUserPreferences = (organizationId, userId) => axios.get(`/v1/preferences/organization/${organizationId}/user/${userId}`, apiConfig);

  const updateUserPreferences = (userPreferences) => axios.put('/v1/preferences/user', userPreferences, apiConfig);

  const getOrganizationPreferences = (organizationId) => axios.get(`/v1/preferences/organization/${organizationId}`, apiConfig);

  const updateOrganizationPreferences = (organizationPreferences) => axios.put('/v1/preferences/organization', organizationPreferences, apiConfig);

  const getUPickPreferences = (organizationId, drugId) => axios.get(`/v1/preferences/upick/${organizationId}/drug/${drugId}`, apiConfig);

  const updateUPickPreferences = (request) => axios.put('/v1/preferences/upick', request, apiConfig);

  const addHiddenEntities = (userPreferences) => axios.patch('/v1/preferences/add-hidden-entities', userPreferences, apiConfig);

  const removeHiddenEntities = (userPreferences) => axios.patch('/v1/preferences/remove-hidden-entities', userPreferences, apiConfig);

  return {
    getUserPreferences,
    updateUserPreferences,
    getOrganizationPreferences,
    updateOrganizationPreferences,
    getUPickPreferences,
    updateUPickPreferences,
    addHiddenEntities,
    removeHiddenEntities
  };
};

export default preferencesApi;
