import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import useEnvironment from '../hooks/useEnvironment';
import anonymousPatientApi from '../apis/anonymous-patient-api';
import attachmentApi from '../apis/attachment-api';
import caseApi from '../apis/case-api';
import categoryApi from '../apis/category-api';
import batchApi from '../apis/batch-api';
import codeListApi from '../apis/code-list-api';
import contactApi from '../apis/contact-api';
import copayApi from '../apis/copay-api';
import diagnosisCodesApi from '../apis/diagnosis-codes';
import doseOrdersApi from '../apis/dose-orders-api';
import drugApi from '../apis/drug-api';
import enrollmentApi from '../apis/enrollment-api';
import featuresApi from '../apis/features-api';
import formApi from '../apis/form-api';
import foundationsApi from '../apis/foundations-api';
import insuranceApi from '../apis/insurance-api';
import intakeApi from '../apis/intake-api';
import lddRelationshipApi from '../apis/ldd-relationship-api';
import licenseApi from '../apis/license-api';
import messagesApi from '../apis/messages-api';
import notificationsApi from '../apis/notification-api';
import organizationApi from '../apis/organization-api';
import patientApi from '../apis/patient-api';
import pharmacyApi from '../apis/pharmacy-api';
import preferencesApi from '../apis/preferences-api';
import prescriberAgentApi from '../apis/prescriber-agent-api';
import prescriberApi from '../apis/prescriber-api';
import pricingApi from '../apis/pricing-api';
import questionApi from '../apis/question-api';
import rptApi from '../apis/rpt-api';
import securityApi from '../apis/security-api';
import serviceApi from '../apis/service-api';
import signatureApi from '../apis/signature-api';
import statusApi from '../apis/status-api';
import supplyCloudApi from '../apis/supply-cloud-api';
import trainingApi from '../apis/training-api';
import treatmentPlanApi from '../apis/treatment-plan-api';
import userManagementApi from '../apis/user-management-api';
import vendorApi from '../apis/vendor-api';

const RxlApiContext = createContext({
  createTreatmentPlan: () => {},
  createTreatmentPlanDraft: () => {}
});

export const RxlApiProvider = ({
  children
}) => {
  const { API_BASE_URL, AUTH_API_BASE_URL, AUTH_COOKIE_NAME } = useEnvironment();
  const [cookies] = useCookies([AUTH_COOKIE_NAME]);
  const [axiosInstance] = useState(axios.create({
    baseURL: API_BASE_URL
  }));

  const apiConfig = {
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${new URLSearchParams(cookies[AUTH_COOKIE_NAME]).get('access_token')}`
    }
  };

  const securityApiConfig = {
    baseURL: AUTH_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${new URLSearchParams(cookies[AUTH_COOKIE_NAME]).get('access_token')}`
    }
  };

  const apiConfigNoAuthToken = {
    baseURL: API_BASE_URL
  };

  return (
    <RxlApiContext.Provider value={{
      ...anonymousPatientApi(apiConfig),
      ...attachmentApi(apiConfig, apiConfigNoAuthToken),
      ...caseApi(apiConfig),
      ...categoryApi(apiConfig),
      ...batchApi(apiConfig),
      ...codeListApi(apiConfig),
      ...contactApi(apiConfig),
      ...copayApi(apiConfig),
      ...diagnosisCodesApi(apiConfig),
      ...doseOrdersApi(apiConfig),
      ...drugApi(apiConfig),
      ...enrollmentApi(apiConfig),
      ...featuresApi(apiConfigNoAuthToken),
      ...formApi(apiConfig),
      ...foundationsApi(apiConfig),
      ...insuranceApi(apiConfig),
      ...intakeApi(apiConfig),
      ...lddRelationshipApi(apiConfig),
      ...licenseApi(apiConfig),
      ...messagesApi(apiConfig),
      ...notificationsApi(apiConfig),
      ...organizationApi(securityApiConfig),
      ...patientApi(apiConfig),
      ...pharmacyApi(apiConfig),
      ...preferencesApi(apiConfig),
      ...prescriberAgentApi(apiConfig),
      ...prescriberApi(apiConfig, apiConfigNoAuthToken),
      ...pricingApi(apiConfig),
      ...questionApi(apiConfig),
      ...rptApi(apiConfig),
      ...securityApi(securityApiConfig),
      ...serviceApi(apiConfig),
      ...signatureApi(apiConfig),
      ...statusApi(apiConfig),
      ...supplyCloudApi(apiConfig),
      ...trainingApi(apiConfig),
      ...treatmentPlanApi(apiConfig),
      ...userManagementApi(apiConfig),
      ...vendorApi(apiConfigNoAuthToken)
    }}
    >
      {axiosInstance ? children : <></>}
    </RxlApiContext.Provider>
  );
};

RxlApiProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default RxlApiContext;
