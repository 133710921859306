import axios from 'axios';

const categoryApi = (apiConfig) => {
  const searchCategories = (drugId, pharmacyId) => axios.get('/v1/categories', {
    ...apiConfig,
    params: {
      drugId,
      pharmacyId,
      pageSize: 3000
    }
  });

  const getCategory = (categoryId) => axios.get(`/v1/category/${categoryId}`, apiConfig);

  const listAllTaxonomies = () => axios.get('/v1/taxonomies/all', apiConfig);

  return {
    searchCategories,
    getCategory,
    listAllTaxonomies
  };
};

export default categoryApi;
