import axios from 'axios';

const trainingApi = (apiConfig) => {
  const getAllResourcesForArea = (trainingArea) => axios.get(`/v1/trainingResource/${trainingArea}`, apiConfig);

  const getResource = (trainingArea, resourceFileName) => axios.get(`/v1/trainingResource/${trainingArea}/${resourceFileName}`, {
    ...apiConfig,
    responseType: 'blob'
  });

  return {
    getAllResourcesForArea,
    getResource
  };
};

export default trainingApi;
