import axios from 'axios';

const intakeApi = (apiConfig) => {
  const listIntakeFiles = () => axios.get('/v1/inboundattachments', apiConfig);

  const archiveIntakeFile = (attachmentId) => axios.delete(`/v1/inboundattachments/${attachmentId}`, apiConfig);

  return {
    listIntakeFiles,
    archiveIntakeFile
  };
};

export default intakeApi;
