import dayJs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Holidays from 'date-holidays';
import { toLower } from 'lodash';

export const now = () => dayJs.extend(utc).utc();

export const nowUnix = () => dayJs().unix();

export const toUnix = (date) => (date ? dayJs(date).unix() : null);

export const parseUnix = (date) => (date ? dayJs.extend(utc).utc(date) : null);

export const toDisplay = (date, withTime) => dayJs
  .extend(utc)
  .utc(date)
  .local()
  .format(`MM/DD/YYYY${withTime ? ' hh:mm A' : ''}`);

export const toDisplayDateTimeNoConvert = (date) => (date ? dayJs.extend(utc).utc(date).format('MM/DD/YYYY hh:mm A') : null);

export const toDisplayDate = (date, showDayOfWeek) => (date
  ? dayJs
    .extend(utc)
    .utc(date)
    .format(showDayOfWeek ? 'ddd MM/DD/YYYY' : 'MM/DD/YYYY')
  : null);

export const toDisplayTime = (date) => (date ? dayJs.extend(utc).utc(date).local().format('hh:mm A') : null);

export const getAbsDifferenceInDays = (date1, date2, float = false) => Math.abs(dayJs(date1).diff(dayJs(date2), 'days', float));
export const differenceInDays = (date1, date2, float = true) => dayJs(date1).diff(dayJs(date2), 'days', float);

export const getDayOfWeek = (date, abbr) => {
  // date format: YYYY-MM-DD
  switch (new Date(date).getDay()) {
    case 0:
      return abbr ? 'Su' : 'Sunday';
    case 1:
      return abbr ? 'Mo' : 'Monday';
    case 2:
      return abbr ? 'Tu' : 'Tuesday';
    case 3:
      return abbr ? 'We' : 'Wednesday';
    case 4:
      return abbr ? 'Th' : 'Thursday';
    case 5:
      return abbr ? 'Fr' : 'Friday';
    case 6:
      return abbr ? 'Sa' : 'Saturday';
    default:
      return null;
  }
};

export const monthName = (date, abbr) => {
  // date format: YYY-MM-DD
  switch (new Date(date).getMonth()) {
    case 0:
      return abbr ? 'Jan' : 'January';
    case 1:
      return abbr ? 'Feb' : 'February';
    case 2:
      return abbr ? 'Mar' : 'March';
    case 3:
      return abbr ? 'Apr' : 'April';
    case 4:
      return abbr ? 'May' : 'May';
    case 5:
      return abbr ? 'Jun' : 'June';
    case 6:
      return abbr ? 'Jul' : 'July';
    case 7:
      return abbr ? 'Aug' : 'August';
    case 8:
      return abbr ? 'Sep' : 'September';
    case 9:
      return abbr ? 'Oct' : 'October';
    case 10:
      return abbr ? 'Nov' : 'November';
    case 11:
      return abbr ? 'Dec' : 'December';
    default:
      return null;
  }
};

export const weeksBetween = (dateString1, dateString2) => {
  const oneWeek = 1000 * 60 * 60 * 24 * 7;
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffMs = Math.abs(date2.getTime() - date1.getTime());
  const diffWeeks = Math.round(diffMs / oneWeek);
  return diffWeeks;
};

export const getDateOneWeekFromToday = () => {
  const today = new Date();
  const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  const year = nextWeek.getFullYear();
  const month = (nextWeek.getMonth() + 1).toString().padStart(2, '0');
  const day = nextWeek.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const addMilitaryTimeToUTCDate = (utcDate, militaryTime) => {
  const timeToAdd = militaryTime.replace(/:/g, '');
  const date = new Date(utcDate);
  const hours = parseInt(timeToAdd.slice(0, 2), 10);
  const minutes = parseInt(timeToAdd.slice(2, 4), 10);
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  return date.toISOString();
};

export const addMinutesToUTCDate = (utcDate, minutes) => {
  const date = new Date(utcDate);
  date.setUTCMinutes(date.getUTCMinutes() + minutes);
  return date.toISOString();
};

export const getClientTimezone = () => {
  const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
  const timezoneOffsetInHours = -(timezoneOffsetInMinutes / 60);
  const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    timezoneOffsetInHours,
    timezoneName
  };
};

export const convertUtcToLocal = (utcTimeString) => {
  const utcDate = new Date(utcTimeString);
  return new Date(utcDate.getTime());
};

export const convertLocalMilitaryTimeToUtcMilitaryTime = (localTime) => {
  const currentDate = new Date();
  const localHour = parseInt(localTime.substring(0, 2), 10);
  const localMinute = parseInt(localTime.substring(2, 4), 10);
  currentDate.setHours(localHour);
  currentDate.setMinutes(localMinute);
  const utcHour = currentDate.getUTCHours().toString().padStart(2, '0');
  const utcMinute = currentDate.getUTCMinutes().toString().padStart(2, '0');
  return `${utcHour}:${utcMinute}`;
};

export const getMilitaryTimeFromDate = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const militaryHours = hours < 10 ? '0' + hours : hours.toString();
  const militaryMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
  return `${militaryHours}${militaryMinutes}`;
};

export const addDaysToDate = (date, days) => dayJs(date).add(days, 'day');

export const subtractDaysFromDate = (date, days) => dayJs(date).subtract(days, 'day');
export const addMinutesToDate = (date, minutes) => dayJs(date).add(minutes, 'minute');

/**
 * @return {string}
 * @param {number} month
 */
export const getFirstDateOfMonth = (month) => {
  const date = new Date();
  date.setMonth(month);

  const year = date.getFullYear();

  const monthString = String(date.getMonth() + 1).padStart(2, '0');
  const dayString = '01';

  return `${year}-${monthString}-${dayString}`;
};

/**
 * @return {string}
 * @param {number} month
 */
export const getLastDateOfMonth = (month) => {
  const year = new Date().getFullYear();
  const date = new Date(year, month + 1, 0);
  return date.toISOString().split('T')[0];
};

/**
 * @return {Array<string>}
 * @param {string} startDate
 * @param {string} endDate
 */
export const getAllDatesInDateRangeExcludeWeekends = (startDate, endDate) => {
  const dates = [];
  const currentDate = new Date(startDate);
  const lastDate = new Date(endDate);

  while (currentDate <= lastDate) {
    if (currentDate.getDay() !== 5 && currentDate.getDay() !== 6) {
      dates.push(currentDate.toISOString().split('T')[0]);
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const firstMondayXDaysOut = (dayOffset) => {
  const today = new Date();
  const dayOfWeek = today.getUTCDay();
  const daysUntilNextMonday = (7 - dayOfWeek + 1) % 7;

  // next monday
  return new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate() + daysUntilNextMonday + dayOffset
  );
};

export const orderDateIsExcludedByManufacturer = (date, availability) => {
  const dateObj = new Date(date);
  const timezoneOffset = getClientTimezone().timezoneOffsetInHours;
  dateObj.setTime(dateObj.getTime() - timezoneOffset * 60 * 60 * 1000);

  const validOrderDays = Object.keys(availability);
  const dayOfWeekName = getDayOfWeek(dateObj);

  return !validOrderDays.includes(toLower(dayOfWeekName));
};

export const dateIsWeekendOrHoliday = (date) => {
  if (!date) return false;
  if (date) {
    const weekday = dayJs(date).isoWeekday();
    if (weekday > 5) {
      return true;
    }
    const dateObj = new Date(date);
    const timezoneOffset = getClientTimezone().timezoneOffsetInHours;
    dateObj.setTime(dateObj.getTime() - timezoneOffset * 60 * 60 * 1000);

    return !!new Holidays({
      country: 'us',
      types: ['bank']
    }).isHoliday(dateObj);
  }
  return false;
};

export const dateIsWeekend = (date) => {
  if (!date) return false;
  if (date) {
    const weekday = dayJs(date).isoWeekday();
    if (weekday > 5) {
      return true;
    }
  }
  return false;
};

/**
 * @return {string} utc
 * @param {string} date utc
 */
export const adjustUtcDateTimeForDaylightSavings = (date) => {
  const standardTimeOffset = dayJs('06-21').utcOffset();
  const dstTimeOffset = dayJs('12-21').utcOffset();
  const currentTimeOffset = dayJs().utcOffset();
  const dateOffset = dayJs(date).utcOffset();

  let retVal = date;
  if (
    currentTimeOffset === standardTimeOffset &&
    dateOffset === dstTimeOffset
  ) {
    retVal = addMinutesToUTCDate(date, 60);
  }
  if (
    currentTimeOffset === dstTimeOffset &&
    dateOffset === standardTimeOffset
  ) {
    retVal = addMinutesToUTCDate(date, -60);
  }
  return retVal;
};

// use when displaying dates in table filters
export const getDateFilter = (value, showTime = false) => {
  if (!value) {
    return 'Unknown';
  }

  const date = new Date(value);
  const dateHours = date.getHours();
  const timezoneOffset = getClientTimezone().timezoneOffsetInHours;
  date.setHours(dateHours - timezoneOffset);

  return showTime ? getDateTimeFilter(date) : date?.toLocaleDateString();
};

// use when displaying date-times in table filters
export const getDateTimeFilter = (value) => {
  const date = new Date(value);
  return `${date?.toLocaleDateString()} ${date?.toLocaleTimeString()}`;
};

export const getFancyDateFormat = (uglyDate) => {
  const tempDate = new Date(uglyDate);
  const day = getDayOfWeek(tempDate, true);
  const month = monthName(tempDate);
  const date = tempDate?.getDate();
  const year = tempDate?.getFullYear();
  return `${day}, ${month} ${date}, ${year}`;
};
