import axios from 'axios';

const doseOrdersApi = (apiConfig) => {
  const doseOrderEmbeds = ['user', 'organization', 'drug'];

  const getOrdersForAnOrganization = (organizationId) => axios.get(`/v1/doseOrders/${organizationId}/orders`, apiConfig);
  const getOrdersForAnOrganizationByDate = (
    organizationId,
    startDate,
    endDate
  ) => axios.get(
    `/v1/doseOrders/${organizationId}/orders?start=${startDate}&finish=${endDate}`,
    {
      params: {
        embeds: doseOrderEmbeds.join(', ')
      },
      ...apiConfig
    }
  );

  const getDoseOrdersForAnOrganization = (controller, organizationId) => axios.get(`/v1/DoseOrders/${organizationId}`, apiConfig);
  const getDoseOrdersForAnOrganizationByDate = (
    controller,
    organizationId,
    startDate,
    endDate
  ) => axios.get(
    `/v1/DoseOrders/${organizationId}?start=${startDate}&finish=${endDate}`,
    apiConfig
  );

  const moveDoseToPatient = (controller, doseOrder) => axios.put(
    `/v1/DoseOrder/${doseOrder?.doseOrderId}/moveToPatient/${doseOrder?.patientId}`,
    {},
    apiConfig
  );

  const updateDoseOrderAppointmentTime = (
    doseOrderId,
    utcStartTime,
    utcEndTime
  ) => axios.put(
    `/v1/doseOrder/${doseOrderId}/UpdateAppointmentTime`,
    {},
    {
      params: {
        utcStartTime,
        utcEndTime
      },
      ...apiConfig
    }
  );

  const updateDoseOrder = (controller, doseOrder) => axios.put('/v1/DoseOrder', doseOrder, apiConfig);

  const createNewDoseOrder = (doseOrder) => axios.post('/v1/DoseOrder', doseOrder, apiConfig);

  const confirmDoseOrders = (doseOrderIds) => axios.put('/v1/doseOrders/confirm', doseOrderIds, apiConfig);

  const cancelDoseOrders = (doseOrderIds, reason) => axios.put(`/v1/doseOrders/cancel?reason=${reason}`, doseOrderIds, apiConfig);

  const confirmDoseOrder = (doseOrderId) => axios.put(`/v1/doseOrder/${doseOrderId}/confirm`, {}, apiConfig);

  const cancelDoseOrder = (doseOrderId, reason) => axios.put(`/v1/doseOrder/${doseOrderId}/cancel?reason=${reason}`, {}, apiConfig);

  const markAdministered = (doseOrderIds) => axios.put('/v1/doseOrders/mark-administered', doseOrderIds, apiConfig);
  const markUnused = (doseOrderIds) => axios.put('/v1/doseOrders/mark-administered', doseOrderIds, apiConfig);

  const getDoseOrderStatusHistory = (doseOrderId) => axios.get(`/v1/doseOrder/${doseOrderId}/history`, apiConfig);

  const confirmDoseOrdersForDate = (confirmInventoryRequest) => axios.put(
    `/v1/doseOrders/${confirmInventoryRequest.organizationId}/confirm/${confirmInventoryRequest.injectionDate}/${confirmInventoryRequest.numberToConfirm}?drugId=${confirmInventoryRequest.drugId}`,
    {},
    apiConfig
  );

  const cancelDoseOrdersForDate = (cancelInventoryRequest) => axios.put(
    `/v1/doseOrders/${cancelInventoryRequest.organizationId}/cancel/${cancelInventoryRequest.injectionDate}/${cancelInventoryRequest.numberToCancel}?drugId=${cancelInventoryRequest.drugId}`,
    {},
    apiConfig
  );

  const listAvailableTimes = () => new Promise((fulfill) => {
    setTimeout(() => {
      const times = [];
      for (let hour = 7; hour <= 17; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const time = `${hour
            .toString()
            .padStart(2, '0')}${minute.toString()
            .padStart(2, '0')}`;
          times.push(time);
        }
      }
      const mockExistingOrdersResponse = {
        status: 200,
        data: times
      };
      fulfill(mockExistingOrdersResponse);
    }, 500);
  });

  const cancelInventory = (doseId) => new Promise((fulfill) => {
    setTimeout(() => {
      const mockCancelDose = {
        status: 200,
        data: {
          doseId
        }
      };
      fulfill(mockCancelDose);
    }, 400);
  });

  const updateDose = (dose) => new Promise((fulfill) => {
    setTimeout(() => {
      const mockUpdateDoseResponse = {
        status: 200,
        data: dose
      };
      fulfill(mockUpdateDoseResponse);
    }, 500);
  });

  const restockDose = (dose) => new Promise((fulfill) => {
    setTimeout(() => {
      const mockRestockDoseResponse = {
        status: 200,
        data: dose
      };
      fulfill(mockRestockDoseResponse);
    }, 500);
  });

  return {
    getOrdersForAnOrganization,
    getOrdersForAnOrganizationByDate,
    getDoseOrdersForAnOrganization,
    getDoseOrdersForAnOrganizationByDate,
    moveDoseToPatient,
    updateDoseOrder,
    updateDoseOrderAppointmentTime,
    createNewDoseOrder,
    confirmDoseOrders,
    cancelDoseOrders,
    confirmDoseOrder,
    cancelDoseOrder,
    markAdministered,
    markUnused,
    getDoseOrderStatusHistory,
    confirmDoseOrdersForDate,
    cancelDoseOrdersForDate,
    listAvailableTimes,
    cancelInventory,
    updateDose,
    restockDose
  };
};

export default doseOrdersApi;
