import axios from 'axios';

const vendorApi = (apiConfigNoAuthToken) => {
  const vendorEnrollmentEmbeds = (additionalEmbeds) => (additionalEmbeds
    ? [
      'prescriber',
      'drug',
      'pharmacy',
      'enrollmentOption',
      'user',
      ...additionalEmbeds
    ]
    : ['prescriber', 'drug', 'pharmacy', 'enrollmentOption', 'user']);

  const getPrescriberByNpi = (npi, brandingId) => axios.get(`/v1/vendor/${brandingId}/npiregistry?npi=${npi}`, apiConfigNoAuthToken);

  const createVendorEnrollment = (enrollment, brandingId) => axios.post(
    `/v1/vendor/${brandingId}/enrollment?formId=${enrollment?.formId}`,
    enrollment,
    apiConfigNoAuthToken
  );

  const updateVendorEnrollment = (enrollmentId, enrollment, brandingId) => axios.put(`/v1/vendor/${brandingId}/enrollment/${enrollmentId}`,
    enrollment,
    apiConfigNoAuthToken);

  const getVendorEnrollment = (enrollmentId, brandingId) => axios.get(`/v1/vendor/${brandingId}/enrollment/${enrollmentId}`, {
    params: {
      embeds: vendorEnrollmentEmbeds().join(',')
    },
    ...apiConfigNoAuthToken,
  });

  return {
    getPrescriberByNpi,
    createVendorEnrollment,
    updateVendorEnrollment,
    getVendorEnrollment
  };
};

export default vendorApi;
