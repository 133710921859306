import React, { useEffect } from 'react';
import { matchPath } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Grid,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InlineIcon } from '@iconify/react';
import RxLightningLogo from '../../../components/logos/RxLightningLogo';
import useAuth from '../../../hooks/useAuth';
import NavItem from './components/NavItem';
import {
  CREATE_CALL,
  CREATE_CASE,
  CREATE_ENROLLMENT,
  CREATE_LDD,
  CREATE_PATIENT,
  CREATE_PRESCRIBER,
  EMBEDDED_PORTAL,
  READ_CASE_DASHBOARD,
  READ_COMPLIANCE,
  READ_DOSE,
  READ_DOSE_ORDER,
  READ_ENROLLMENT,
  READ_FILE_INTAKE,
  READ_FOUNDATION,
  READ_LDD,
  READ_NOTIFICATIONS,
  READ_PATIENT,
  READ_PRESCRIBER,
  READ_RPT,
  READ_ORDER_MANAGEMENT,
  UPDATE_PRESCRIBER_QUANTITY,
  VIEW_ACCOUNT_MANAGEMENT,
  READ_RAM_LICENSE_DETAILS
} from '../../../constants/permissions';
import {
  AdverseEvent,
  Calendar,
  Cog,
  CreateCall,
  DownloadPdf,
  Drug,
  EditUser,
  Enrollment,
  FoundationSearch,
  GeneralInquiry,
  Help,
  Home,
  LDD,
  Logout,
  Notification,
  Organization,
  Patient,
  Prescriber
} from '../../../constants/icons';
import {
  organizationManagementPath,
  caseListPath,
  complianceViewPath,
  editUserPath,
  enrollmentCreationPath,
  enrollmentListPath,
  fileIntakeListPath,
  foundationsPath,
  homePath,
  lddRelationshipCreatePath,
  lddRelationshipListPath,
  logoutPath,
  notificationListPath,
  patientListPath,
  patientRegistrationPath,
  prescriberListPath,
  prescriberRegistrationPath,
  prescriberVialsPath,
  supportPath,
  rptViewPath,
  orderListPath,
  doseListPath,
  copayCardsPath,
  lisPath
} from '../../../constants/paths';
import GroupSelector from '../../../views/organizations/components/GroupSelector';
import { RolvedonDrugId } from '../../../constants/drugs';
import {
  AdverseEventPharmacyId,
  GeneralInquiryPharmacyId
} from '../../../constants/pharmacies';
import { MAQ } from '../../../constants/features';
import { hasApprovedOrderingService } from '../../../constants/services';

let sections = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Home',
        icon: <InlineIcon icon={Home} width={20} />,
        href: homePath()
      },
      {
        title: 'Notifications',
        icon: <InlineIcon icon={Notification} width={20} />,
        href: notificationListPath(),
        permission: READ_NOTIFICATIONS
      },
      {
        title: 'Intake',
        icon: <InlineIcon icon={DownloadPdf} width={20} />,
        href: fileIntakeListPath(),
        permission: READ_FILE_INTAKE
      },
      {
        title: 'Create Call',
        icon: <InlineIcon icon={CreateCall} width={20} />,
        href: 'https://rxlightning-prod.my.connect.aws/',
        permission: CREATE_CALL
      },
      {
        title: 'Compliance',
        icon: <InlineIcon icon={Drug} width={20} />,
        href: complianceViewPath(),
        permission: READ_COMPLIANCE
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Patients',
        icon: <InlineIcon icon={Patient} width={20} />,
        href: patientListPath(),
        permission: READ_PATIENT,
        items: [
          {
            title: 'List Patients',
            href: patientListPath(),
            permission: READ_PATIENT
          },
          {
            title: 'Register Patient',
            href: patientRegistrationPath(),
            permission: CREATE_PATIENT
          },
          {
            title: 'Case Dashboard',
            href: caseListPath(),
            permission: READ_CASE_DASHBOARD
          }
        ]
      },
      {
        title: 'Prescribers',
        icon: <InlineIcon icon={Prescriber} width={20} />,
        href: prescriberListPath(),
        permission: READ_PRESCRIBER,
        excludedPermission: READ_RAM_LICENSE_DETAILS,
        items: [
          {
            title: 'List Prescribers',
            href: prescriberListPath(),
            permission: READ_PRESCRIBER
          },
          {
            title: 'Add Prescriber',
            href: prescriberRegistrationPath(),
            permission: CREATE_PRESCRIBER
          },
          {
            title: 'Product Replacement',
            href: prescriberVialsPath(),
            permission: UPDATE_PRESCRIBER_QUANTITY
          }
        ]
      },
      {
        title: 'Enrollments',
        icon: <InlineIcon icon={Enrollment} width={20} />,
        href: enrollmentListPath(),
        permission: READ_ENROLLMENT,
        items: [
          {
            title: 'List Enrollments',
            href: enrollmentListPath(),
            permission: READ_ENROLLMENT
          },
          {
            title: 'Start New Enrollment',
            href: enrollmentCreationPath(),
            permission: CREATE_ENROLLMENT
          },
          {
            title: 'General Inquiry',
            icon: <InlineIcon icon={GeneralInquiry} width={20} />,
            href: enrollmentCreationPath({
              drugId: RolvedonDrugId,
              pharmacyId: GeneralInquiryPharmacyId,
              generalInquiry: true
            }),
            permission: CREATE_CASE
          },
          {
            title: 'Adverse Events',
            icon: <InlineIcon icon={AdverseEvent} width={20} />,
            href: enrollmentCreationPath({
              drugId: RolvedonDrugId,
              pharmacyId: AdverseEventPharmacyId,
              adverseEvent: true
            }),
            permission: CREATE_CASE
          }
        ]
      },
      {
        title: 'Financial Assistance',
        excludedPermission: READ_RAM_LICENSE_DETAILS,
        icon: <InlineIcon icon={FoundationSearch} width={20} />,
        href: foundationsPath(),
        items: [
          {
            title: 'Find Funding',
            href: foundationsPath(),
            permission: READ_FOUNDATION
          },
          {
            title: 'Copay Cards',
            href: copayCardsPath(),
            feature: MAQ
          },
          {
            title: 'LIS',
            href: lisPath(),
            feature: MAQ
          }
        ]
      },
      {
        title: 'Limited Distribution Drugs',
        icon: <InlineIcon icon={LDD} width={20} />,
        href: lddRelationshipListPath(),
        permission: READ_LDD,
        items: [
          {
            title: 'List Associations',
            href: lddRelationshipListPath(),
            permission: READ_LDD
          },
          {
            title: 'Create New Association',
            href: lddRelationshipCreatePath(),
            permission: CREATE_LDD
          }
        ]
      },
      // hcp
      {
        title: 'RPT',
        icon: <InlineIcon icon={Calendar} width={20} />,
        href: rptViewPath(),
        permission: READ_RPT,
        items: [
          {
            title: 'RPT Home',
            href: rptViewPath(),
            permission: READ_RPT
          },
          {
            title: 'Orders',
            href: orderListPath(),
            permission: READ_DOSE_ORDER
          },
          {
            title: 'Doses',
            href: doseListPath(),
            permission: READ_DOSE
          }
        ]
      },
      // manufacturer
      {
        title: 'RPT',
        icon: <InlineIcon icon={Calendar} width={20} />,
        href: rptViewPath(),
        permission: READ_ORDER_MANAGEMENT,
        items: [
          {
            title: 'Order Management',
            href: orderListPath(),
            permission: READ_ORDER_MANAGEMENT
          }
        ]
      },
      {
        title: 'Account',
        icon: <InlineIcon icon={Cog} width={20} />,
        href: editUserPath(),
        items: [
          {
            title: 'Edit My Account Info',
            icon: <InlineIcon icon={EditUser} width={20} />,
            href: editUserPath()
          },
          {
            title: 'Organization Management',
            icon: <InlineIcon icon={Organization} width={20} />,
            href: organizationManagementPath(),
            permission: VIEW_ACCOUNT_MANAGEMENT
          },
          {
            title: 'Logout',
            icon: <InlineIcon icon={Logout} width={20} />,
            href: logoutPath()
          }
        ]
      }
    ]
  }
];

const renderNavItems = ({ depth = 0, items, pathname }) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) => reduceChildRoutes({
        acc,
        depth,
        item,
        pathname
      }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc, pathname, item, depth
}) => {
  const key = item.title + depth;

  if (item.items?.length) {
    const open = matchPath(
      {
        path: item.href,
        exact: false
      },
      pathname
    );

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        permission={item.permission}
        excludedPermission={item.excludedPermission}
        feature={item.feature}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.items,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        permission={item.permission}
        excludedPermission={item.excludedPermission}
        feature={item.feature}
        title={item.title}
      />
    );
  }

  return acc;
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 512
  },
  desktopDrawer: {
    width: 512,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const { hasPermission, selectedOrganization } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  if (
    !hasApprovedOrderingService(selectedOrganization?.serviceStatuses ?? []) ||
    !hasPermission(READ_RPT)
  ) {
    sections = sections?.map((s) => {
      const { items, ...newSection } = s;
      const filteredItems = items?.filter((i) => i?.title !== 'RPT');

      return {
        ...newSection,
        items: filteredItems
      };
    });
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {!hasPermission(EMBEDDED_PORTAL) && (
          <Hidden lgUp>
            <Box p={2} display="flex" justifyContent="center">
              <RouterLink to={homePath()} id="navbar-logo-link">
                <RxLightningLogo />
              </RouterLink>
            </Box>
          </Hidden>
        )}
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              id={`navbar-${section.subheader}-link`}
              key={section.subheader}
              subheader={(
                <ListSubheader disableGutters disableSticky>
                  <Typography>{section.subheader}</Typography>
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <GroupSelector />
              </Grid>
            </Grid>
          </Box>
          <Box bgcolor="background.dark" borderRadius={1} p={2}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <InlineIcon
                  color={theme.palette.primary.main}
                  icon={Help}
                  width="50"
                />
              </Grid>
              <Grid item>
                <Typography color="textPrimary" variant="h3">
                  Need Help?
                </Typography>
                <RouterLink
                  to={supportPath()}
                  color="secondary"
                  style={{ textDecoration: 'none' }}
                >
                  <Link
                    id="navbar-contact-support-link"
                    color="secondary"
                    component="a"
                    target="_blank"
                    variant="subtitle1"
                    underline="hover"
                  >
                    Contact Support
                  </Link>
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          id="mobile-drawer"
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          id="desktop-drawer"
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open={openMobile}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
