import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useAuth from '../../hooks/useAuth';
import SessionTimeoutDialog from '../dialogs/SessionTimeoutDialog';
import { BYPASS_TIMEOUT } from '../../constants/permissions';

const SessionTimeout = () => {
  const { hasPermission, logout } = useAuth();
  const [timeoutWarn, setTimeoutWarn] = useState(false);

  const onPrompt = () => {
    setTimeoutWarn(true);
  };

  const onIdle = () => {
    setTimeoutWarn(false);
    logout();
  };

  const stayLoggedIn = () => {
    setTimeoutWarn(false);
    reset();
  };

  const { reset } = useIdleTimer({
    crossTab: true,
    debounce: 250,
    onIdle,
    onPrompt,
    promptTimeout: 1000 * 60,
    syncTimers: 250,
    timeout: 1000 * 60 * (hasPermission(BYPASS_TIMEOUT) ? 479 : 14)
  });

  return <SessionTimeoutDialog onClose={stayLoggedIn} open={timeoutWarn} />;
};

export default SessionTimeout;
