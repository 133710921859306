import axios from 'axios';

const treatmentPlanApi = (apiConfig) => {
  const listTreatmentPlansForOrganization = (controller, organizationId) => axios.get(`/v1/treatmentPlans/organization/${organizationId}`, {
    ...apiConfig,
    signal: controller?.signal || null
  });

  const listTreatmentPlansForPatient = (controller, patientId) => axios.get(`/v1/treatmentPlans/patient/${patientId}`, {
    ...apiConfig,
    signal: controller?.signal || null
  });

  // used for doseDateGenerator (no physician, organization, or RAM license required)
  const createTreatmentPlanExample = (scheduleConfiguration) => axios.post('/v1/calendar', scheduleConfiguration, apiConfig);

  const createTreatmentPlanDraft = (treatmentPlanDraft) => axios.post('/v1/treatmentPlan/draft', treatmentPlanDraft, apiConfig);

  const createTreatmentPlan = (treatmentPlan, organizationId) => axios.post(`/v1/treatmentPlan/organization/${organizationId}`, treatmentPlan, apiConfig);

  const updateTreatmentPlan = (treatmentPlan, organizationId = '16CB3F25-05E8-4401-BC24-0002A61FB417') => axios.put(`/v1/treatmentPlan/organization/${organizationId}`, treatmentPlan, apiConfig);

  const archiveTreatmentPlan = (treatmentPlanId) => axios.delete(`/v1/treatmentPlan/${treatmentPlanId}`, apiConfig);

  const getTreatmentPlan = (treatmentPlanId) => axios.get(`/v1/treatmentPlan/${treatmentPlanId}`, apiConfig);

  const completeTreatmentPlan = (treatmentPlanId) => axios.put(`/v1/treatmentPlan/${treatmentPlanId}/complete`, {}, apiConfig);

  const addDoseToTreatmentPlanScheduleItem = (treatmentPlanScheduleItemId) => axios.put('/v1/treatmentPlan/schedule-item-dose', {
    treatmentPlanScheduleItemId
  }, apiConfig);

  const getTreatmentPlansByOrgId = (organizationId) => axios.get(`/v1/treatmentPlans/organization/${organizationId}`, apiConfig);

  const getScheduleForOrg = (
    organizationId,
    start = null,
    finish = null
  ) => axios.get(`/v1/treatmentplans/organization/${organizationId}/schedule`, {
    ...apiConfig,
    params: {
      start,
      finish
    }
  });

  const getTreatmentPlanOrderTypes = () => new Promise((fulfill) => {
    const treatmentPlanOrderTypes = {
      data: {
        data: [
          {
            id: 'commercial',
            label: 'Commercial'
          },
          {
            id: 'pap',
            label: 'PAP'
          },
          {
            id: 'clinicalTrial',
            label: 'Clinical Trial',
            disabled: true
          }
        ]
      }
    };
    fulfill(treatmentPlanOrderTypes);
  });

  const getCompleteTreatmentPlanReasonOptions = () => new Promise((fulfill) => {
    const completeTreatmentPlanReasonOptions = {
      data: {
        data: [
          { id: 0, label: 'Discontinue Treatment' },
          { id: 1, label: 'Patient Deceased' },
          { id: 2, label: 'Covid-19 Positive' },
          { id: 3, label: 'Insurance Denied' },
          { id: 4, label: 'Medically Unfit' },
          { id: 5, label: 'OOP Costs' },
          { id: 6, label: 'Patient Chose Not to Continue Therapy' }
        ]
      }
    };
    fulfill(completeTreatmentPlanReasonOptions);
  });

  return {
    listTreatmentPlansForOrganization,
    listTreatmentPlansForPatient,
    createTreatmentPlanExample,
    createTreatmentPlanDraft,
    createTreatmentPlan,
    updateTreatmentPlan,
    archiveTreatmentPlan,
    getTreatmentPlan,
    completeTreatmentPlan,
    addDoseToTreatmentPlanScheduleItem,
    getTreatmentPlansByOrgId,
    getScheduleForOrg,
    getTreatmentPlanOrderTypes,
    getCompleteTreatmentPlanReasonOptions,
  };
};

export default treatmentPlanApi;
