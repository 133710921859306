import axios from 'axios';

const pricingApi = (apiConfig) => {
  const getPricing = (enrollmentId) => axios.get(`/v1/benefitcheck/pricecheck/${enrollmentId}`, apiConfig);

  const startPricing = (request) => axios.post('/v1/benefitcheck/pricecheck', request, apiConfig);

  return {
    getPricing,
    startPricing
  };
};

export default pricingApi;
